/**
 * @file App.js
 * @brief Inicio de la aplicacióm.
 * @author Efraín Gourcy
 * @modified Jul 26, 2023
 */

import { createContext, useEffect, useState } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import Protected from "./components/Protected";
import { validToken } from "./helpers/Session";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Usuarios from "./pages/usuarios/Usuarios";
import UserForm from "./pages/usuarios/Form";
import Perfiles from "./pages/perfiles/Perfiles";
import PerfilesForm from "./pages/perfiles/Form";
import IntencionNuevo from "./pages/intencion/Nuevo";
import { FiLogOut, FiUserCheck, FiTarget, FiBookOpen } from "react-icons/fi";
import {
  FaRegBell,
  FaRegPlusSquare,
  FaRegBuilding,
  FaUserAlt,
} from "react-icons/fa";
import { GoDashboard } from "react-icons/go";
import { BiUser, BiLock, BiSearchAlt2 } from "react-icons/bi";
import { ImTree, ImListNumbered } from "react-icons/im";
import { VscGraph } from "react-icons/vsc";
import { BsListCheck } from "react-icons/bs";
import { TbGps } from "react-icons/tb";
import {
  ApiUrl,
  settingsWithBody,
  settingsWithoutBody,
} from "./helpers/ApiUrl";
import Estructura from "./pages/estructuras/Estructura";
import EstructuraForm from "./pages/estructuras/Form";
import Escenarios from "./pages/escenarios/Escenarios";
import EscenariosForm from "./pages/escenarios/Form";
import Permisos from "./pages/permisos/Permisos";
import PermisosForm from "./pages/permisos/Form";
import Consultar from "./pages/intencion/Consultar";
//import logo from "./images/logo_mano.png";
import logo from "./images/permea.jpg";
import Ver from "./pages/intencion/Ver";
import AsignarEstructura from "./pages/intencion/AsignarEstructura";
import EditarEstructura from "./pages/intencion/EditarEstructura";
import ProgramasBeneficiarios from "./pages/programasBeneficiarios/ProgramasBeneficiarios";
import Programas from "./pages/programasBeneficiarios/Programas";
import FormProgramas from "./pages/programasBeneficiarios/FormProgramas";
import FormProgramasBeneficarios from "./pages/programasBeneficiarios/FormProgramasBeneficarios";
import Bingo from "./pages/bingo/Bingo";
import BingoPrep from "./pages/bingo/BingoPrep";
import BingoPrepCaptura from "./pages/bingo/BingoPrepCaptura";
import MiPerfil from "./pages/usuarios/MiPerfil";
import Partidos from "./pages/partidos/Partidos";
import Compromisos from "./pages/intencion/Compromisos";
import Loader from "./components/Loader";
import MonitoreoGps from "./pages/gps/Monitoreo";

export const contextUserPermissions = createContext(null);

function App() {
  /** Variables */
  const [isLogged, setIsLogged] = useState(false);
  const [userPermissions, setUserPermissions] = useState();
  const [userId, setUserId] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [escenario, setEscenario] = useState(null);
  const [year, setYear] = useState("1981");
  const [loading, setLoading] = useState(false);

  /** Estilo activo del menu */
  let activeStyle = {
    backgroundColor: "#81a3d1",
    borderColor: "#3b82f6",
  };

  useEffect(() => {

    setLoading(true);

    if (!isLogged) {
      /** Si no esta logeado valida que el token de sessionStorage sea valido */
      if (validToken()) setIsLogged(true);
    }
  }, []);

  /******************************************************** useEffect [isLogged] ********************************************************/
  useEffect(() => {
    /** Si el usuario esta logeado carga el perfil */
    if (isLogged) {
      loadProfile();
      setYear(new Date().getFullYear());
    }
  }, [isLogged]);

  /******************************************************** getPermissions ********************************************************/
  /** Obtiene los permisos del usuario */
  const getPermissions = async (userProfile) => {
    
    
      //setLoading(true);

      let valueToken = sessionStorage.getItem("token");
      let profile = userProfile.split(",");

      let profiles = {
        perfiles: profile,
      };

      const response = await fetch(
        ApiUrl().url + "permisos/getByUser",
        settingsWithBody(valueToken, profiles, "POST")
      );
      const respPermissions = await response.json();

      setUserPermissions(respPermissions.permisos);

      let etiquetaAsignacion = false;
      let etiquetaIntencion = false;
      let etiquetaBingo = false;

      respPermissions.permisos.forEach((el) => {
        if (el.modulo === "escenarios") {
          document.getElementById("menu-escenarios").classList.remove("hidden");
          etiquetaAsignacion = true;
        }

        if (el.modulo === "usuarios") {
          document.getElementById("menu-usuarios").classList.remove("hidden");
          etiquetaAsignacion = true;
        }

        if (el.modulo === "permisos" && sessionStorage.getItem("grupo") === "3881") {
          document.getElementById("menu-permisos").classList.remove("hidden");
          etiquetaAsignacion = true;
        }

        if (el.modulo === "perfiles") {
          document.getElementById("menu-perfiles").classList.remove("hidden");
          etiquetaAsignacion = true;
        }

        if (el.modulo === "estructura") {
          document.getElementById("menu-estructura").classList.remove("hidden");
          etiquetaAsignacion = true;
        }

        if (el.modulo === "programas") {
          document
            .getElementById("menu-programas-apoyos")
            .classList.remove("hidden");
          etiquetaAsignacion = true;
        }

        if (el.modulo === "partidos") {
          document.getElementById("menu-partidos").classList.remove("hidden");
          etiquetaAsignacion = true;
        }

        if (el.modulo === "intencion") {
          document
            .getElementById("menu-consultar-intencion")
            .classList.remove("hidden");
          etiquetaIntencion = true;
        }

        if (el.modulo === "intencion" && el.permiso === "captura") {
          document
            .getElementById("menu-captura-nuevo-registro")
            .classList.remove("hidden");
          etiquetaIntencion = true;
        }

        /*
        if (el.modulo === "asignar/programas") {
          document
            .getElementById("menu-compromisos")
            .classList.remove("hidden");
          etiquetaIntencion = true;
        }
        */

        if (el.modulo === "asignar/estructura") {
          document
            .getElementById("menu-asignar-estructura")
            .classList.remove("hidden");
          etiquetaIntencion = true;
        }

        if (el.modulo === "asignar/programas") {
          document
            .getElementById("menu-beneficiarios-programas")
            .classList.remove("hidden");
          etiquetaIntencion = true;
        }

        if (el.modulo === "bingo") {
          document.getElementById("menu-bingo").classList.remove("hidden");
          etiquetaBingo = true;
        }

        if (el.modulo === "bingo") {
          document.getElementById("menu-gps").classList.remove("hidden");
          etiquetaBingo = true;
        }

        if (el.modulo === "bingoPrep") {
          document.getElementById("menu-bingo-prep").classList.remove("hidden");
          etiquetaBingo = true;
        }

        if (etiquetaAsignacion)
          document.getElementById("et-asignacion").classList.add("md:block");

        if (etiquetaIntencion)
          document.getElementById("et-intencion").classList.add("md:block");

        if (etiquetaBingo)
          document.getElementById("et-bingo").classList.add("md:block");
      });

      setLoading(false);
   
  };

  /******************************************************** useEffect [userProfile] ********************************************************/
  useEffect(() => {
    if (userProfile !== null) {
      //setLoading(true);
      getPermissions(userProfile);
    }
  }, [userProfile]);

  /******************************************************** loadProfile ********************************************************/
  const loadProfile = async () => {
    /** Carga el perfil del usuario */
    let valueToken = sessionStorage.getItem("token");

    fetch(ApiUrl().url + "getUserId", settingsWithoutBody(valueToken, "GET"))
      .then((response) => response.json())
      .then((data) => {
        setUserId(data.user.id);
        setEscenario(data.user.escenario);
        setUserProfile(data.user.perfil);
      });
  };

  /******************************************************** if (!isLogged) ********************************************************/
  if (!isLogged) {
    /** Si no esta logeado valida que el token de sessionStorage sea valido */
    if (validToken()) setIsLogged(true);
  }

  /**************** logOut ******************************************************************************* */
  const logOut = () => {
    /** Salir de la aplicación */
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("token_time");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("escenario");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("profile");
    sessionStorage.removeItem("grupo");
    setIsLogged(false);
    setUserProfile(null);
  };

  /**************** return ******************************************************************************* */
  return (
    <>
      {isLogged ? (
        <>
          <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-gray-100 text-black">
            <div className="fixed w-full flex items-center justify-between h-20 text-white z-10 drop-shadow-lg">
              <div className="flex items-center justify-start pl-1 w-full h-20 bg-neutral-50">
                <img src={logo} alt="logo" className="w-24 ml-10" />
                {/*
                <span className="hidden md:block text-indigo-900 font-bold text-xl mt-1">
                  PERMEA
                </span>
                */}
              </div>

              <div className="flex justify-end items-center h-20 w-full bg-neutral-50 header-right">
                {/*
                  <div className="bg-white rounded flex items-center w-full max-w-xl mr-4 p-2 shadow-sm border border-gray-200">
                    <button className="outline-none focus:outline-none">
                      <svg className="w-5 text-gray-600 h-5 cursor-pointer" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </button>
                    <input type="search" name="" id="" placeholder="Buscar..." className="w-full pl-3 text-sm text-black outline-none focus:outline-none bg-transparent" />
                  </div>
                  */}

                <ul className="flex items-center">
                  <li>
                    <NavLink to="/perfil">
                      <div className="flex text-white mr-2 pr-2 pl-4 pt-1 pb-1 cursor-pointer bg-[#3c70b9] rounded-full shadow-md hover:bg-[#81a3d1]  focus:outline-none transition-all duration-300 transform hover:-translate-y-0.5">
                        <FaUserAlt className="mt-1" />
                        <span className="ml-2 uppercase pr-2">
                          {" "}
                          {sessionStorage.getItem("username")}
                        </span>
                      </div>
                    </NavLink>
                  </li>

                  {/*
                  <li>
                    <div className="cursor-pointer text-blue-400 hover:text-blue-500 group p-2 rounded-full shadow-md bg-blue-200 hover:bg-blue-300 focus:outline-none mr-2 transition-all duration-300 transform hover:-translate-y-0.5">
                      <button className="px-2 place-self-center">
                        <FaUserAlt />
                      </button>
                      <span>egourcy - administrador</span>
                    </div>
                  </li>
                  */}

                  {/*
                  <li>
                    <button
                      aria-hidden="true"
                      className="group p-2 rounded-full shadow-md bg-[#3c70b9]  hover:bg-[#81a3d1] focus:outline-none mr-2 transition-all duration-300 transform hover:-translate-y-0.5"
                    >
                      <FaRegBell className="text-white" />
                    </button>
                  </li>
                  */}

                  <li>
                    <div className="block w-px h-6 mx-3 bg-indigo-600"></div>
                  </li>

                  <li>
                    <span
                      onClick={logOut}
                      className="flex items-center mr-4 text-red-600 hover:text-red-500 cursor-pointer transition-all duration-300 transform hover:-translate-y-0.5"
                    >
                      Salir
                      <span className="inline-flex mr-1 ml-2">
                        <FiLogOut className="text-2xl" />
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="fixed flex flex-col top-20 left-0 w-14 hover:w-64 md:w-64 bg-[#3c70b9] h-full text-white transition-all duration-300 border-none z-10 sidebar">
              <div className="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">
                <ul className="flex flex-col py-4 space-y-1">
                  <li>
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        <GoDashboard className="text-lg" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>

                  {loading ? (
                    <>
                      <li>
                        <div className="h-16 mt-10 flex items-baseline justify-center">
                          <Loader />
                        </div>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  <li id="et-asignacion" className="px-5 hidden">
                    <div className="flex flex-row items-center h-8 border-b-2 border-gray-400">
                      <div className="text-sm font-light tracking-wide text-neutral-400 uppercase">
                        ADMINISTRACIÓN
                      </div>
                    </div>
                  </li>

                  <li id="menu-escenarios" className="hidden">
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/escenarios"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        <FaRegBuilding className="text-lg" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Escenarios
                      </span>
                    </NavLink>
                  </li>

                  <li id="menu-usuarios" className="hidden">
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/usuarios"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        <BiUser className="text-lg" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Usuarios
                      </span>
                    </NavLink>
                  </li>

                  <li id="menu-permisos" className="hidden">
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/permisos"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        <BiLock className="text-lg" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Permisos
                      </span>
                    </NavLink>
                  </li>

                  <li id="menu-perfiles" className="hidden">
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/perfiles"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-5">
                        <FiUserCheck className="text-lg" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Perfiles
                      </span>
                    </NavLink>
                  </li>

                  <li id="menu-estructura" className="hidden">
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/estructura"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-5">
                        <ImTree className="text-lg" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Estructura
                      </span>
                    </NavLink>
                  </li>

                  <li id="menu-programas-apoyos" className="hidden">
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/programas"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-5">
                        <ImListNumbered className="text-lg" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Programas/Apoyos
                      </span>
                    </NavLink>
                  </li>

                  <li id="menu-partidos" className="hidden">
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/partidos"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-5">
                        <BsListCheck className="text-2xl" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Partidos
                      </span>
                    </NavLink>
                  </li>

                  <li id="et-intencion" className="px-5 hidden">
                    <div className="flex flex-row items-center h-8 border-b-2 border-gray-400">
                      <div className="text-sm font-light tracking-wide text-gray-400 uppercase">
                        Intención del Voto
                      </div>
                    </div>
                  </li>

                  <li id="menu-consultar-intencion" className="hidden">
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/intencion/consultar"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        <BiSearchAlt2 className="text-lg" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Consultar Intención
                      </span>
                    </NavLink>
                  </li>

                  <li id="menu-captura-nuevo-registro" className="hidden">
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/intencion/nuevo/0"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        <FaRegPlusSquare className="text-lg" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Capturar Nuevo Registro
                      </span>
                    </NavLink>
                  </li>

                  <li id="menu-compromisos" className="hidden">
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/compromisos"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        <FiBookOpen className="text-lg" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Compromisos
                      </span>
                    </NavLink>
                  </li>

                  <li id="menu-asignar-estructura" className="hidden">
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/intencion/asignar/estructura"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        <ImTree className="text-lg" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Asignación de Estructuras
                      </span>
                    </NavLink>
                  </li>

                  <li id="menu-beneficiarios-programas" className="hidden">
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/programasBeneficiarios"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        <ImListNumbered className="text-lg" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Beneficiarios de Programas
                      </span>
                    </NavLink>
                  </li>

                  <li id="et-bingo" className="px-5 hidden">
                    <div className="flex flex-row items-center h-8 border-b-2 border-gray-400">
                      <div className="text-sm font-light tracking-wide text-gray-400 uppercase">
                        Bingo
                      </div>
                    </div>
                  </li>

                  <li id="menu-bingo" className="hidden">
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/bingo"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        <FiTarget className="text-lg" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Bingo
                      </span>
                    </NavLink>
                  </li>

                  <li id="menu-gps" className="hidden">
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/monitoreoGps"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        <TbGps className="text-lg" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Monitoreo GPS
                      </span>
                    </NavLink>
                  </li>

                  <li id="menu-bingo-prep" className="hidden">
                    <NavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/bingoPrep"
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#81a3d1] text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        <VscGraph className="text-lg" />
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        Bingo{" "}
                        <abbr title="Programa de Resultados Electorales Preliminares">
                          PREP
                        </abbr>
                      </span>
                    </NavLink>
                  </li>
                </ul>

                <div className="flex flex-row items-center h-8 border-b-2 border-gray-400"></div>

                <div className="mb-20 px-5 py-3 hidden md:block text-center text-xs">
                  {/* <p>{userId} - {sessionStorage.getItem("name")}</p> */}
                  <small>PERMEA V4.2.6</small>
                  <p>Copyright @{year}</p>
                  {/* <p>[{escenario}]</p> */}
                </div>
              </div>
            </div>

            <div className="h-full ml-14 mt-20 mb-10 md:ml-64">
              <contextUserPermissions.Provider
                value={{ userPermissions, escenario }}
              >
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <Home />
                      </Protected>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <Home />
                      </Protected>
                    }
                  />

                  <Route
                    path="/escenarios"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <Escenarios />
                      </Protected>
                    }
                  />
                  <Route
                    path="/escenarios/form/:id"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <EscenariosForm />
                      </Protected>
                    }
                  />

                  <Route
                    path="/usuarios"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <Usuarios />
                      </Protected>
                    }
                  />
                  <Route
                    path="/usuarios/form/:id"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <UserForm />
                      </Protected>
                    }
                  />

                  <Route
                    path="/perfil"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <MiPerfil />
                      </Protected>
                    }
                  />

                  <Route
                    path="/permisos"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <Permisos />
                      </Protected>
                    }
                  />
                  <Route
                    path="/permisos/form/:id"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <PermisosForm />
                      </Protected>
                    }
                  />

                  <Route
                    path="/perfiles"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <Perfiles />
                      </Protected>
                    }
                  />
                  <Route
                    path="/perfiles/form/:perfil"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <PerfilesForm />
                      </Protected>
                    }
                  />

                  <Route
                    path="/intencion/nuevo/:id"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <IntencionNuevo />
                      </Protected>
                    }
                  />
                  <Route
                    path="/intencion/consultar"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <Consultar />
                      </Protected>
                    }
                  />
                  <Route
                    path="/intencion/ver/:id"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <Ver />
                      </Protected>
                    }
                  />
                  <Route
                    path="/intencion/asignar/estructura"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <AsignarEstructura />
                      </Protected>
                    }
                  />
                  <Route
                    path="/intencion/asignar/estructura/:id"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <EditarEstructura />
                      </Protected>
                    }
                  />

                  <Route
                    path="/compromisos"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <Compromisos />
                      </Protected>
                    }
                  />

                  <Route
                    path="/estructura"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <Estructura />
                      </Protected>
                    }
                  />
                  <Route
                    path="/estructura/form/:id"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <EstructuraForm />
                      </Protected>
                    }
                  />

                  <Route
                    path="/programas"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <Programas />
                      </Protected>
                    }
                  />
                  <Route
                    path="/programas/form/:id"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <FormProgramas />
                      </Protected>
                    }
                  />

                  <Route
                    path="/programasBeneficiarios"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <ProgramasBeneficiarios />
                      </Protected>
                    }
                  />
                  <Route
                    path="/programasBeneficiarios/asignar/:id"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <FormProgramasBeneficarios />
                      </Protected>
                    }
                  />

                  <Route
                    path="/bingo"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <Bingo />
                      </Protected>
                    }
                  />


                  <Route
                    path="/MonitoreoGps"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <MonitoreoGps />
                      </Protected>
                    }
                  />

                  <Route
                    path="/bingoPrep"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <BingoPrep />
                      </Protected>
                    }
                  />
                  <Route
                    path="/bingoPrep/captura"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <BingoPrepCaptura />
                      </Protected>
                    }
                  />

                  <Route
                    path="/partidos"
                    element={
                      <Protected
                        setIsLogged={setIsLogged}
                        setUserProfile={setUserProfile}
                      >
                        <Partidos />
                      </Protected>
                    }
                  />
                </Routes>
              </contextUserPermissions.Provider>
            </div>
          </div>
        </>
      ) : (
        <>
          <Login isLogged={isLogged} setIsLogged={setIsLogged} />
        </>
      )}
    </>
  );
}

export default App;
