import React from 'react';
import ReactECharts from 'echarts-for-react';

function MovilizadorVotos({votos,total}) {

  let option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: ['Comprometidos', 'Avance'],
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: 'Votación',
        type: 'bar',
        barWidth: '60%',
        data: [
          {
            value: total,
            itemStyle: {
              color: '#f3c071'
            }
          },
          {
            value: votos,
            itemStyle: {
              color: '#81a3d1'
            }
          }
        ]
      }
    ]
  };

  return (
    <>
      <ReactECharts 
        option={option}
        style={{ width: '50%', height: '400px', minHeight: '300px', minWidth: '350px'}}
      />
    </>
  )
}

export default MovilizadorVotos;