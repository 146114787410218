import React from 'react';

const BtnBuscar = ({ text }) => {
  return (
    <>
      <button className="px-6 py-2 text-white font-bold bg-[#3c70b9] rounded-lg hover:bg-[#81a3d1] ring-2 ring-blue-400 focus:outline-none">
        { text }
      </button>
    </>
  );
}

export default BtnBuscar;