/**
 * @file Permisos.js
 * @brief Administración de Permisos.
 * @author Efraín Gourcy
 * @modified Feb 13, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import BtnAceptar from '../../components/BtnAceptar';
import { ApiUrl, settingsWithoutBody } from '../../helpers/ApiUrl';
import Grid from './Grid';
import { contextUserPermissions } from "../../App";

const Permisos = () => {

  /** Variables */
  const {userPermissions} = useContext(contextUserPermissions);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [permisos, setPermisos] = useState(null);
  const [gridPermisos, setGridPermisos] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [permissionEdit, setPermissionEdit] = useState(false);
  const [permissionDelete, setPermissionDelete] = useState(false);
  let url = ApiUrl().url+"permisos/get";

  
  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Valida los permisos del usuario */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "permisos") {

          if (el.permiso === "ver")
            permission = true;

          if (el.permiso === "nuevo") {
            let $btnNuevoUsuario = document.getElementById("btn-nuevo-usuario");
            $btnNuevoUsuario.classList.remove("invisible");
          }

          if (el.permiso === "eliminar")
            setPermissionDelete(true);

          if (el.permiso === "editar")
            setPermissionEdit(true);

        }
      });

      if (!permission || sessionStorage.getItem("grupo") !== '3881')
        navigate("/");
    }

  }, [userPermissions]);


  /**************** useEffect (url) ******************************************************************************* */
  useEffect(() => {
    /** Carga el grid de Permisos */
    setLoading(true);
      
      let valueToken = sessionStorage.getItem("token");

      fetch(url,settingsWithoutBody(valueToken,'GET'))
      .then(res => res.json())
      .then(data => {
        setPermisos(data.permisos);
        setGridPermisos(data.permisos.slice(0,5));
        setLoading(false);
      });
      

  }, [url]);


  /**************** updateGrid ******************************************************************************* */
  const updateGrid = () => {
    /** Actualiza el grid */
    setPermisos(null);
    setGridPermisos(null);
    setLoading(true);

    let inputSearch = document.getElementById("search");
    inputSearch.value= "";
    setIsSearch(false);

    let valueToken = sessionStorage.getItem("token");

    fetch(url,settingsWithoutBody(valueToken,'GET'))
      .then(res => res.json())
      .then(data => {
        setPermisos(data.permisos);
        setGridPermisos(data.permisos.slice(0,5));
        setLoading(false);
      });
  };


  /**************** handleActive ******************************************************************************* */
  const handleActive = (e) => {
    /** Activa / Inactiva los permisos */
    if (permissionDelete) {
      setPermisos(null);
      setGridPermisos(null);
      setLoading(true);

      let valueToken = sessionStorage.getItem("token");

      url = `${ApiUrl().url}permisos/active/${e.currentTarget.dataset.id}`;
      fetch(url,settingsWithoutBody(valueToken),'GET')
        .then(res => res.json())
        .then(data => {
          setLoading(false);
          url = ApiUrl().url+"permisos/get";
          updateGrid();
        });
    }
  };


  /**************** handleSearch ******************************************************************************* */
  const handleSearch = (e) => {
    /** Buscar un elemento dentro del grid de permisos */
    let search = e.target.value;
    let PermisosArray = [];

    if (search.length > 0) {

      let serachByModulo = false;
      if (isNaN(search))
        serachByModulo = true;

        PermisosArray = permisos.filter(el => {
        
        if (serachByModulo){
          let modulo = el.modulo.toLowerCase();
          return modulo.includes(search.toLowerCase());
        }
      });

      setGridPermisos(PermisosArray);
      setIsSearch(true);

    } else {
      setGridPermisos(permisos.slice(0,5));
      setIsSearch(false);
    }
  };


  /**************** return ******************************************************************************* */
  return (
    <>
      <div className="md:py-12 md:px-12 sm:py-3 sm:px-3 py-3 px-3">
        
        <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-left border-b-2 border-[#3c70b9]">Administrar Permisos</h1>
    
        <div className="p-4 flex bg-gray-50 rounded-lg shadow-xl">
          <div className="flex-none md:flex-grow-0">
            
            <NavLink id="btn-nuevo-usuario" to="/permisos/form/0" className="invisible">
              <BtnAceptar text={ "Nuevo Permiso" } />
            </NavLink>
            
          </div>

          <div className="pl-3 md:pl-96 flex-grow">
            <input id="search" type="text" onChange={ handleSearch } className="p-2 w-full rounded border-[#3c70b9] shadow-lg focus:ring-blue-500 focus:outline-none focus:ring" placeholder="Buscar..." />
          </div>
        </div>

        <Grid permisos={ permisos } loading={ loading } gridPermisos={ gridPermisos } setGridPermisos={ setGridPermisos } isSearch={ isSearch} permissionEdit={ permissionEdit } handleActive={ handleActive } />
          
      </div>
    </>
  );
}

export default Permisos;