import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { ApiUrl, settingsWithoutBody } from '../../helpers/ApiUrl';

const BarrasDVTR = () => {

  const [labels, setLabels] = useState();
  const [values, setValues] = useState();

  useEffect(() => {
    
    const getData = async () => {
      let valorEscenario = sessionStorage.getItem('escenario');
      let token = sessionStorage.getItem("token");
      const resp = await fetch(ApiUrl().url + "padron/getTotalPorSeccion/" + valorEscenario, settingsWithoutBody(token, 'GET'));
      const respTotalSeccion = await resp.json();

      if (respTotalSeccion) {
        let lab = [];
        let val = [];
        //console.log(respTotalSeccion.padron);

        respTotalSeccion.padron.forEach(element => {
          lab.push(element.seccion);
          val.push(element.total);
          //console.log(element.seccion);
        });

        setLabels(lab);
        setValues(val);

      }
    }

    getData();


  }, []);

  const labelRight = {
    position: 'right'
  };
  
  const option = {
    title: {
      text: 'Avance de Captura por Secciones'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      top: 80,
      bottom: 30
    },
    xAxis: {
      type: 'value',
      position: 'top',
      splitLine: {
        lineStyle: {
          type: 'dashed'
        }
      }
    },
    yAxis: {
      type: 'category',
      axisLine: { show: false },
      axisLabel: { show: true },
      axisTick: { show: false },
      splitLine: { show: false },
      data: labels,
    },
    series: [
      {
        name: 'Secciones',
        type: 'bar',
        stack: 'Total',
        label: {
          show: false,
          formatter: '{b}'
        },
        data: values,
        color: '#3c70b9'
      }
    ]
  }

  
  return (
    <>
      <div className='w-full mt-8 flex justify-center items-center'>


        <ReactECharts 
          option={option}
          style={{ width: '100%', minHeight: '200vh', minWidth: '300px'}}
        />
        
      
      </div>
    </>
  );
}

export default BarrasDVTR;