/**
 * @file Protected.js
 * @brief Componente que proteje las rutas de la aplicació. Valida que el token sea válido.
 * @author Efraín Gourcy
 * @modified Mar 3, 2023
 */

import { Navigate, useNavigate } from "react-router-dom";
import { ApiUrl, settingsWithBody } from "../helpers/ApiUrl";
import { AddHours } from "../helpers/Dates";

const Protected = ({ children,setIsLogged,setUserProfile }) => {

  /** Variables */
  let navigate = useNavigate();

  let tokenSessionStorage = sessionStorage.getItem("token");

  /**************** validateToken ******************************************************************************* */
  const validateToken = async () => {
    /** Valida el token y le grega una hora a la expiración */
    try {

      // token expires in 1 hour
      let tokenExpiration = sessionStorage.getItem("token_time");
      tokenExpiration = AddHours(1, new Date(tokenExpiration));
      let currentDate = new Date();

      if (currentDate > tokenExpiration) {

        const response = await fetch(ApiUrl().url+"validToken", settingsWithBody(tokenSessionStorage,{token:tokenSessionStorage}, 'POST'));
        const respToken = await response.json();
  
        if (respToken.message === "Unauthenticated." || respToken.message === 'Unauthorized') {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("token_time");
          sessionStorage.removeItem("name");
          sessionStorage.removeItem("escenario");
          sessionStorage.removeItem("username");
          sessionStorage.removeItem("profile");

          setIsLogged(false);
          setUserProfile(null);

          console.log("Not Valid Token");
          return navigate("/login");
          
        }

      }

    } catch (err) {
      console.log(err);
    }

  };


  /**************** Exist token ******************************************************************************* */
  /** Si no existe el token redirecciona a Login */
  if (tokenSessionStorage === null) {
    setIsLogged(false);
    return <Navigate to="/login" replace />
  }
  /** Si existe el token */
  else
    validateToken();

  return children;
};

export default Protected;