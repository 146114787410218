import React, { Component } from "react";
import { MapContainer, Marker, Popup, Rectangle, TileLayer, Tooltip, Polyline } from "react-leaflet";
import L, { Icon } from "leaflet";

import "leaflet/dist/leaflet.css";

const ags = [21.87897, -102.297631];
const ags1 = [21.88998, -102.327631];
const ags2 = [21.84699, -102.267631];

const newIcon = new Icon({
  iconUrl: 'https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  // popupAnchor: [-0, -76]
});

const yellowOptions = { color: '#dc2626' }




export default class MonitoreoGps extends Component {

  render() {

    return (
      <div className="dark:bg-gray-900">
        
        <MapContainer
          className="Map"
          center={{ lat: 21.882056, lng: -102.291175 }}
          zoom={13}
          scrollWheelZoom={true}
          style={{ height: "60vh", width: "100vw" }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <Marker position={ags} icon={newIcon}>
            <Popup>
              <div>
                <div style={{fontSize: "14px"}}>Aguascalientes</div>
                <div>
                  <div style={{fontSize: "10px"}}>Distrito II</div>
                  <div style={{fontSize: "10px"}}>Casilla: 1 - Sección:29</div>
                </div>
              </div>
            </Popup>
            <Tooltip>Casilla: 1 - Sección:29</Tooltip>
          </Marker>

          <Marker position={ags} icon={newIcon}>
            <Popup>
              <div>
                <div style={{fontSize: "14px"}}>Aguascalientes</div>
                <div>
                  <div style={{fontSize: "10px"}}>Distrito II</div>
                  <div style={{fontSize: "10px"}}>Casilla: 1 - Sección:29</div>
                </div>
              </div>
            </Popup>
            <Tooltip>Casilla: 1 - Sección:29</Tooltip>
          </Marker>

          <Marker position={ags1} icon={newIcon}>
            <Popup>
              <div>
                <div style={{fontSize: "14px"}}>Aguascalientes</div>
                <div>
                  <div style={{fontSize: "10px"}}>Distrito II</div>
                  <div style={{fontSize: "10px"}}>Casilla: 1 - Sección:29</div>
                </div>
              </div>
            </Popup>
            <Tooltip>Casilla: 1 - Sección:29</Tooltip>
          </Marker>

          <Marker position={ags2} icon={newIcon}>
            <Popup>
              <div>
                <div style={{fontSize: "14px"}}>Aguascalientes</div>
                <div>
                  <div style={{fontSize: "10px"}}>Distrito II</div>
                  <div style={{fontSize: "10px"}}>Casilla: 1 - Sección:29</div>
                </div>
              </div>
            </Popup>
            <Tooltip>Casilla: 1 - Sección:29</Tooltip>
          </Marker>

        </MapContainer>
      </div>
    );
  }
}