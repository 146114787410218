/**
 * @file Compromisos.js
 * @brief Compromisos de la intención del voto.
 * @author Efraín Gourcy
 * @modified May 27, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { contextUserPermissions } from '../../App';
import { ApiUrl, settingsWithoutBody } from '../../helpers/ApiUrl';
import GridCompromisos from './GridCompromisos';

const Compromisos = () => {

  /** Variables */
  const { userPermissions, escenario } = useContext(contextUserPermissions);
  const navigate = useNavigate();
  const [padron, setPadron] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gridPadron, setGridPadron] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [permissionEdit, setPermissionEdit] = useState(false);
  const [permissionNew, setPermissionNew] = useState(false);
  let url = ApiUrl().url + "padron/get/" + escenario;


  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Valida los permisos del usuario */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "compromisos") {

          if (el.permiso === "ver")
            permission = true;

          if (el.permiso === "nuevo")
            setPermissionNew(true);

          if (el.permiso === "editar")
            setPermissionEdit(true); 
        }

      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);


  /**************** useEffect (url) ******************************************************************************* */
  useEffect(() => {
    /** Carga el grid de padron */
    setLoading(true);

    let valueToken = sessionStorage.getItem("token");

    fetch(url, settingsWithoutBody(valueToken, 'GET'))
      .then(res => res.json())
      .then(data => {
        if (data.padron) {
          setPadron(data.padron);
          setGridPadron(data.padron.slice(0, 5));
          setLoading(false);
        }
      });


  }, [url]);


  /**************** handleSearch ******************************************************************************* */
  const handleSearch = (e) => {
    /** Buscar un elemento dentro del grid del padron */
    let search = e.target.value;
    let padronArray = [];

    if (search.length > 0) {

      let serachByName = false;
      if (isNaN(search))
        serachByName = true;

      padronArray = padron.filter(el => {

        if (serachByName) {
          let name = el.nombre.toLowerCase() + " " + el.paterno.toLowerCase() + " " + el.materno.toLowerCase();
          return name.includes(search.toLowerCase());
        }
      });

      setGridPadron(padronArray);
      setIsSearch(true);

    } else {
      setGridPadron(padron.slice(0, 5));
      setIsSearch(false);
    }
  };


  /**************** return ******************************************************************************* */
  return (
    <>

      <div className="md:py-12 md:px-12 sm:py-3 sm:px-3 py-3 px-3">

        <h1 className="md:my-3 md:text-3xl text-xl text-blue-600 text-left border-b-2 border-blue-600">Generar Compromisos</h1>

        <div className="p-4 flex bg-gray-50 rounded-lg shadow-xl">

          <div className="pl-3 md:pl-96 flex-grow">
            <input id="search" type="text" onChange={handleSearch} className="p-2 w-full rounded border-blue-600 shadow-lg focus:ring-blue-600 focus:outline-none focus:ring focus:ring-opacity-70" placeholder="Buscar..." />
          </div>
        </div>

        <GridCompromisos padron={padron} loading={loading} gridPadron={gridPadron} setGridPadron={setGridPadron} isSearch={isSearch} permissionEdit={permissionEdit} />

      </div>
    </>
  );

}

export default Compromisos;