/**
 * @file Dates.js
 * @brief Helper para el manejo de fechas.
 * @author Efraín Gourcy
 * @modified Dic 30, 2022
 */

/**************** AddHours ******************************************************************************* */
export const AddHours = (numOfHours, date = new Date()) => {
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
  return date;
};