/**
 * @file ApiUrl.js
 * @brief Helper para la conexión con la API y sus headers.
 * @author Efraín Gourcy
 * @modified Jul 26, 2023
 */

/**************** ApiUrl ******************************************************************************* */
export const ApiUrl = () => {
  //const url = "https://apipde.gourcy.com.mx/api/v1/";
  //const url = "http://localhost:8001/api-pde/public/api/v1/";
  //const url = "http://localhost/api-pde/public/api/v1/";
  //const url = "https://permea.mx/api_permea/api/v1/";
  const url = "https://permea-api.proit.mx/apipermea/api/v1/";
  return { url };
}

/**************** settingsWithBody ******************************************************************************* */
export const settingsWithBody = (token,params,method) => {
  const settings = {
    method: method,
    //mode: "no-cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(params),
  };

  return settings;
};

/**************** settingsWithoutBody ******************************************************************************* */
export const settingsWithoutBody = (token,method) => {
  const settings = {
    method: method,
    //mode: "no-cors",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };

  return settings;
};
