/**
 * @file Form.js
 * @brief Formulario que muestra la información del perfil del usuario.
 * @author Efraín Gourcy
 * @modified Abr 8, 2023
 */

import React, { useEffect, useState } from 'react';
import { ApiUrl, settingsWithBody, settingsWithoutBody } from '../../helpers/ApiUrl';
import Loader from '../../components/Loader';
import BtnAceptarLargo from '../../components/BtnAceptarLargo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let initialValues = {
  id: '',
  name: '',
  profile: '',
  username: '',
};

let formInitialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
};

const MiPerfil = () => {

  /** Variables */
  const [userData, setUserData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(formInitialValues);

  /**************** useEffect [] ******************************************************************************* */
  useEffect(() => {

    /** Obtiene el id del usuario */
    const getUserId = async () => {
      const token = sessionStorage.getItem('token');
      const response = await fetch(ApiUrl().url + "getUserId", settingsWithoutBody(token, 'GET'));
      const data = await response.json();
      if (data.user) {
        setUserData({
          ...userData,
          id: data.user.id,
          name: data.user.name,
          profile: data.user.perfil,
          username: data.user.username,
        });
      }
    };

    getUserId();

  }, []);


  /**************** handleChange ******************************************************************************* */
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    });
  };


  /**************** handleAccept ******************************************************************************* */
  const handleAccept = async (e) => {
    e.preventDefault();
    setLoading(true);

    /** Valida que los campos tengan información */
    if (formValues.currentPassword === '' || formValues.newPassword === '' || formValues.confirmPassword === '') {
      toast.error('No ha llenado todos los campos del formulario', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
      setLoading(false);
    } else {

      /** Valida que las contraseñas coincidan */
      if (formValues.newPassword !== formValues.confirmPassword) {
        toast.error('Las contraseñas no coinciden', {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored"
        });
        setLoading(false);

      } else {
        let token = sessionStorage.getItem('token');
        const response = await fetch(ApiUrl().url + "login", settingsWithBody(token, { username: userData.username, password: formValues.currentPassword }, 'POST'));
        const data = await response.json();

        /** Actualiza la contraseña */
        if (data.status === 'success') {

          const response = await fetch(ApiUrl().url + "usuarios/changePassword", settingsWithBody(token, { id: userData.id, password: formValues.newPassword }, 'POST'));
          const data = await response.json();
          if (data.status === 'success') {

            toast.success('Contraseña actualizada correctamente', {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored"
            });
            setLoading(false);
          }

          /** La contraseña actual no es la correcta */
        } else {
          toast.error('La contraseña actual no es correcta', {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored"
          });
          setLoading(false);
        }
      }
    }
  };


  /**************** return ******************************************************************************* */
  return (
    <>
      <ToastContainer />
      <div className="py-12 h-screen bg-gray-300">
        <div className="max-w-12xl mx-12 bg-white rounded-xl shadow-md overflow-hidden md:max-w-12xl">
          <div className="md:flex">
            <div className="w-full p-2 py-10">

              <div className="flex justify-center">
                <div className="relative">
                  <img src={`https://ui-avatars.com/api/?name=${userData.name}&background=cbd5e1&color=ffffff`} className="rounded-full" width="80" />
                </div>

              </div>

              <div className="flex flex-col text-center mt-3 mb-4">

                <span className="text-2xl font-medium">{userData.name}</span>
                <span className="text-md text-gray-400">Perfil: {userData.profile}</span>
                <span className="text-md text-gray-400">Usuario: {userData.username}</span>

              </div>

              <div className="grid grid-cols-1 md:grid-cols-1 px-20 text-center mt-10">

                <div>
                  <label htmlFor="estructura" className="text-gray-600">Contraseña Actual</label>
                </div>
                <div>
                  <input id="currentPassword" name="currentPassword" type="password" onChange={handleChange} className="w-full border-2 border-gray-300 p-2 rounded-lg focus:outline-none focus:border-indigo-500" placeholder="Contraseña Actual" />
                </div>

                <div className="mt-10">
                  <label htmlFor="estructura" className="text-gray-600">Nueva Contraseña</label>
                </div>
                <div>
                  <input id="newPassword" name="newPassword" type="password" onChange={handleChange} className="w-full border-2 border-gray-300 p-2 rounded-lg focus:outline-none focus:border-indigo-500" placeholder="Nueva Contraseña" />
                </div>

                <div className="mt-4">
                  <label htmlFor="estructura" className="text-gray-600">Confirma Contraseña</label>
                </div>
                <div>
                  <input id="cofirmPassword" name="confirmPassword" type="password" onChange={handleChange} className="w-full border-2 border-gray-300 p-2 rounded-lg focus:outline-none focus:border-indigo-500" placeholder="Confirma Contraseña" />
                </div>

                <div className="w-full mt-10">
                  {loading
                    ? <Loader />
                    : <>
                      <span onClick={handleAccept}><BtnAceptarLargo text={"Guardar"} /></span>
                    </>
                  }
                </div>



              </div>



            </div>

          </div>
        </div>

      </div>
    </>
  );

}

export default MiPerfil;