/**
 * @file FormProgramas.js
 * @brief Formulario para agregar/editar los programas de apoyo.
 * @author Efraín Gourcy
 * @modified Mar 24, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contextUserPermissions } from '../../App';
import BtnAceptar from '../../components/BtnAceptar';
import BtnCancelar from '../../components/BtnCancelar';
import Loader from '../../components/Loader';
import { ApiUrl, settingsWithBody, settingsWithoutBody } from '../../helpers/ApiUrl';

/** Valores iniciales form */
const initalValues = {
  escenario: "",
  programa: "",
  descripcion: ""
}

const FormProgramas = () => {

  /** Variables */
  let { id } = useParams();
  const [form, setForm] = useState(initalValues);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const {userPermissions} = useContext(contextUserPermissions);


  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Valida los permisos para el módulo de permisos */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "programas") {
          if (el.permiso === "nuevo" || el.permiso == "editar")
            permission = true;
        }
      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);


  /**************** useEffect [id] ******************************************************************************* */
  useEffect(() => {

    /** Modo Edición */
    if (parseInt(id) !== 0)
      getData();

  }, [id]);



  /**************** useEffect [] ******************************************************************************* */
  useEffect(() => {

    const getEscenarios = async () => {
      setLoading(true);
      let token = sessionStorage.getItem("token");

      let response = "";
      if (sessionStorage.getItem("grupo") === "3881")
        response = await fetch(ApiUrl().url + "escenarios/getActives", settingsWithoutBody(token, 'GET'));
      else
        response = await fetch(ApiUrl().url + "escenarios/getActives/"+sessionStorage.getItem("escenario"), settingsWithoutBody(token, 'GET'));

      const respEscenarios = await response.json();

      if (respEscenarios.status === "success") {

        let $selectEscenarios = document.getElementById("escenario");
        $selectEscenarios.innerHTML = "";

        let $option = document.createElement("option");
        $option.value = "-1";
        $option.innerText = "Selecciona una opción";
        $selectEscenarios.appendChild($option);

        respEscenarios.escenarios.forEach(el => {
          let $optionEscenario = document.createElement("option");
          $optionEscenario.value = el.id;
          $optionEscenario.innerText = el.nombre;
          $selectEscenarios.appendChild($optionEscenario);
        });

      }

      setLoading(false);

    };

    getEscenarios();

  }, []);


  /**************** getData ******************************************************************************* */
  const getData = async () => {
    let token = sessionStorage.getItem("token");
    const response = await fetch(ApiUrl().url + "programas/getPrograma/" + id, settingsWithoutBody(token, 'GET'));
    const respProgramas = await response.json();

    if (respProgramas.status === "success" && respProgramas.programas.length > 0) {

      setForm({
        ...form,
        escenario: respProgramas.programas[0].escenario,
        programa: respProgramas.programas[0].programa,
        descripcion: respProgramas.programas[0].descripcion
      });

      let $divPrograma = document.getElementById("div-programa");
      $divPrograma.classList.remove("hidden");

      let $divDescripcion = document.getElementById("div-descripcion");
      $divDescripcion.classList.remove("hidden");

    } else
      return navigate("/programas");
  };


  /**************** handleChange ******************************************************************************* */
  const handleChange = (e) => {

    setForm({
      ...form,
      [e.target.name]: e.target.value
    });

    /** Si se esta dando clic en el select */
    if (e.target.id === "escenario") {

      /** Muestra los campos del formulario */
      let $divPrograma = document.getElementById("div-programa");
      if (e.target.value !== "-1" && e.target.value !== "") {
        $divPrograma.classList.remove("hidden");
      } else {
        $divPrograma.classList.add("hidden");
      }
      /** Muestra los campos del formulario */
      let $divDescripcion = document.getElementById("div-descripcion");
      if (e.target.value !== "-1" && e.target.value !== "") {
        $divDescripcion.classList.remove("hidden");
      } else {
        $divDescripcion.classList.add("hidden");
      }

    }

  };


  /**************** handleAccept ******************************************************************************* */
  const handleAccept = (e) => {

    e.preventDefault();
    setLoading(true);

    let $selectEscenario = document.getElementById('escenario');
    $selectEscenario.classList.remove("ring-2");
    $selectEscenario.classList.remove("ring-red-400");

    let $inputPrograma = document.getElementById('programa');
    $inputPrograma.classList.remove("ring-2");
    $inputPrograma.classList.remove("ring-red-400");

    let $inputDescripcion = document.getElementById('descripcion');
    $inputDescripcion.classList.remove("ring-2");
    $inputDescripcion.classList.remove("ring-red-400");

    if (form.escenario !== "-1" && form.escenario !== "") {
      if (form.programa !== "" && form.descripcion !== "") {

        /** Nuevo registro */
        if (id === "0") {

          let token = sessionStorage.getItem("token");
          fetch(ApiUrl().url + "programas/store", settingsWithBody(token, form, 'POST'))
            .then(res => res.json())
            .then(data => {
              if (data.status === "success") {
                return navigate("/programas");
              } else {
                toast.error('Error de comunicación con el servidor', {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: "colored"
                });
              }
            });
        /** Actualiar registro (update) */
        } else {
          let token = sessionStorage.getItem("token");
          fetch(ApiUrl().url + "programas/edit/"+id, settingsWithBody(token, form, 'POST'))
            .then(res => res.json())
            .then(data => {
              if (data.status === "success") {
                return navigate("/programas");
              } else {
                toast.error('Error de comunicación con el servidor', {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: "colored"
                });
              }
            });
        }

      } else {
        toast.error('No ha introducido un programa/Descripción', {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored"
        });
        $inputPrograma.classList.add("ring-2");
        $inputPrograma.classList.add("ring-red-400");
      }
    } else {
      toast.error('No ha seleccionado un escenario', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
      $selectEscenario.classList.add("ring-2");
      $selectEscenario.classList.add("ring-red-400");
    }
    setLoading(false);
  };


  /**************** return ******************************************************************************* */
  return (
    <>
      <ToastContainer />
      <div className="py-6 px-2 bg-gray-100">

        <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-center">Crea un Nuevo Programa/Apoyo</h1>

        <form>
          <div className="px-2 md:py-6 py-2 grid md:grid-cols-3 gap-3">

            <div id="div-escenario" className="md:col-start-2">
              <label htmlFor="escenario" className="text-gray-600">Escenario</label>
              <select id="escenario" name="escenario" value={form.escenario} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
              </select>
            </div>

            <div id="div-programa" className="md:col-start-2 hidden">
              <label htmlFor="programa" className="text-gray-600">Programa</label>
              <input type="text" id="programa" name="programa" autoComplete="true" value={form.programa} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div id="div-descripcion" className="md:col-start-2 hidden">
              <label htmlFor="descripcion" className="text-gray-600">Descripción</label>
              <input type="text" id="descripcion" name="descripcion" autoComplete="true" value={form.descripcion} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="p-1 md:py-4 md:col-start-2 justify-self-end">
              {loading
                ? <Loader />
                : <>
                  <span onClick={handleAccept} className="mr-4"><BtnAceptar text={"Guardar"} /></span>

                  <NavLink to="/programas">
                    <BtnCancelar text={"Cancelar "} />
                  </NavLink>
                </>
              }
            </div>

          </div>
        </form>

      </div>
    </>
  );
}

export default FormProgramas;