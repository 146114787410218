/**
 * @file Estructura.js
 * @brief Administración de los Escenarios.
 * @author Efraín Gourcy
 * @modified Feb 13, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import BtnAceptar from '../../components/BtnAceptar';
import Grid from './Grid';
import { contextUserPermissions } from "../../App";
import { ApiUrl, settingsWithoutBody } from '../../helpers/ApiUrl';

const Escenarios = () => {

  /** Variables */
  const { userPermissions } = useContext(contextUserPermissions);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [escenarios, setEscenarios] = useState(null);
  const [gridEscenarios, setGridEscenarios] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [permissionDelete, setPermissionDelete] = useState(false);
  const [permissionEdit, setPermissionEdit] = useState(false);
  let url = ApiUrl().url + "escenarios/get";


  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Verifica los permisos del usuario */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "escenarios") {

          if (el.permiso === "ver")
            permission = true;

          if (el.permiso === "nuevo") {
            let $btnNuevoUsuario = document.getElementById("btn-nuevo-usuario");
            $btnNuevoUsuario.classList.remove("invisible");
          }

          if (el.permiso === "eliminar")
            setPermissionDelete(true);

          if (el.permiso === "editar")
            setPermissionEdit(true);

        }
      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);


  /**************** useEffect (url) ******************************************************************************* */

  useEffect(() => {
    /** Carga el grid de escenarios */
    setLoading(true);

    let valueToken = sessionStorage.getItem("token");

    let url2 = url;
    if (sessionStorage.getItem("grupo") !== '3881')
      url2 = ApiUrl().url + "escenarios/get/"+sessionStorage.getItem("escenario");

    fetch(url2, settingsWithoutBody(valueToken, 'GET'))
      .then(res => res.json())
      .then(data => {
        setEscenarios(data.escenarios);
        setGridEscenarios(data.escenarios.slice(0, 5));
        setLoading(false);
      });


  }, [url]);


  /**************** handleSearch ******************************************************************************* */
  const handleSearch = (e) => {
    /** Buscar un elemento dentro del grid de escenarios */
    let search = e.target.value;
    let EscenariosArray = [];

    if (search.length > 0) {

      let serachByEscenario = false;
      if (isNaN(search))
        serachByEscenario = true;

      EscenariosArray = escenarios.filter(el => {

        if (serachByEscenario) {
          let escenario = el.nombre.toLowerCase();
          return escenario.includes(search.toLowerCase());
        }
      });

      setGridEscenarios(EscenariosArray);
      setIsSearch(true);

    } else {
      setGridEscenarios(escenarios.slice(0, 5));
      setIsSearch(false);
    }
  };


  /**************** updateGrid ******************************************************************************* */
  const updateGrid = () => {
    /** Carga el grid de escenarios */
    setEscenarios(null);
    setGridEscenarios(null);
    setLoading(true);

    let inputSearch = document.getElementById("search");
    inputSearch.value = "";
    setIsSearch(false);

    let valueToken = sessionStorage.getItem("token");

    fetch(url, settingsWithoutBody(valueToken, 'GET'))
      .then(res => res.json())
      .then(data => {
        setEscenarios(data.escenarios);
        setGridEscenarios(data.escenarios.slice(0, 5));
        setLoading(false);
      });
  };


  /**************** handleActive ******************************************************************************* */
  const handleActive = (e) => {
    /** Activa / Inactiva los escenarios */
    /*
    if (permissionDelete) {
      setEscenarios(null);
      setGridEscenarios(null);
      setLoading(true);

      let valueToken = sessionStorage.getItem("token");

      url = `${ApiUrl().url}escenarios/active/${e.currentTarget.dataset.id}`;
      fetch(url, settingsWithoutBody(valueToken), 'GET')
        .then(res => res.json())
        .then(data => {
          setLoading(false);
          url = ApiUrl().url + "escenarios/get";
          updateGrid();
        });
    }
    */
  };


  /**************** return ******************************************************************************* */
  return (
    <>

      <div className="md:py-12 md:px-12 sm:py-3 sm:px-3 py-3 px-3">

        <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-left border-b-2 border-[#3c70b9]">Administrar Escenarios</h1>

        <div className="p-4 flex bg-gray-50 rounded-lg shadow-xl">
          <div className="flex-none md:flex-grow-0">

            <NavLink id="btn-nuevo-usuario" to="/escenarios/form/0" className="invisible">
              <BtnAceptar text={"Nuevo Escenario"} />
            </NavLink>

          </div>

          <div className="pl-3 md:pl-96 flex-grow">
            <input id="search" type="text" onChange={handleSearch} className="p-2 w-full rounded border-[#3c70b9] shadow-lg focus:ring-blue-500 focus:outline-none focus:ring" placeholder="Buscar..." />
          </div>
        </div>

        <Grid escenarios={escenarios} loading={loading} gridEscenarios={gridEscenarios} setGridEscenarios={setGridEscenarios} isSearch={isSearch} permissionEdit={permissionEdit} handleActive={handleActive} />

      </div>
    </>
  );
}

export default Escenarios;