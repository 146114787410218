import * as React from 'react';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { useEffect } from 'react';
import { ApiUrl, settingsWithoutBody } from '../helpers/ApiUrl';
import { contextUserPermissions } from '../App';
import { useState } from 'react';

export default function FirstComponent({idPadron}) {

  const { userPermissions, escenario } = React.useContext(contextUserPermissions);
  const [data, setData] = useState();

  const myStyles = {
    voto: {
      color: 'red',
      fontWeight: 'bold',
    },
    noVoto: {
      color: '#000'
    }
  };

  useEffect(() => {
    
    const getData = () => {

      let valueToken = sessionStorage.getItem("token");
      let url = ApiUrl().url + "padron/get/" + escenario;
    

      fetch(url, settingsWithoutBody(valueToken, 'GET'))
        .then(res => res.json())
        .then(data => {
          if (data.padron) {

            setData(data.padron);
            //console.log(data.padron);

          }
      });

    };

    getData();

  }, []);

  const buildTree = () => {

    let output = [];
    
    if (data) {

      let dependeEstructura = 0;
      let nombre = "";
      let voto = 0;

      data.map(el => {
        if (el.id == idPadron) {
          dependeEstructura = el.usuario_depende_estructura;
          voto = el.voto;

          if (voto == 1)
            nombre = "Ya voto - " + el.puesto + " - " + el.nombre + " " + el.paterno + " " + el.materno;
          else
            nombre = el.puesto + " - " + el.nombre + " " + el.paterno + " " + el.materno;

        }
      });

      /* El usuario es Lider */
      if (dependeEstructura == 0) {

        if (voto == 1)
          output.push(<TreeItem sx={myStyles.voto} itemId={idPadron} label={nombre} key={idPadron}> {getChild(idPadron)} </TreeItem>);
        else
          output.push(<TreeItem sx={myStyles.noVoto} itemId={idPadron} label={nombre} key={idPadron}> {getChild(idPadron)} </TreeItem>);

      } else { /* El usuario es sublider o movilizado */
        output.push(getParent(idPadron,nombre,dependeEstructura,voto));
      }

      return output;

    } else {
      return "";
    }


  };

  const getChild = (id) => {
    let childs = [];
    data.map(el => {


      if (el.usuario_depende_estructura == id) {

        let nombre = "";

        if (el.voto == 1)
          nombre = "Ya voto - " + el.puesto + " - " + el.nombre + " " + el.paterno + " " + el.materno;
        else
          nombre = el.puesto + " - " + el.nombre + " " + el.paterno + " " + el.materno;

        if (el.voto == 1)
          childs.push(<TreeItem sx={myStyles.voto} itemId={el.id} label={nombre} key={el.id}> {getchild2(el.id)} </TreeItem>);
        else
          childs.push(<TreeItem sx={myStyles.noVoto} itemId={el.id} label={nombre} key={el.id}> {getchild2(el.id)} </TreeItem>);
      }
    });
    return childs;
  };

  const getchild2 = (id) => {
    let childs = [];
    data.map(el => {
      if (el.usuario_depende_estructura == id) {

        let nombre = "";

        if (el.voto == 1)
          nombre = "Ya voto - " + el.puesto + " - " + el.nombre + " " + el.paterno + " " + el.materno;
        else
          nombre = el.puesto + " - " + el.nombre + " " + el.paterno + " " + el.materno;

        if (el.voto == 1)
          childs.push(<TreeItem sx={myStyles.voto} itemId={el.id} label={nombre} key={el.id} />);
        else
          childs.push(<TreeItem sx={myStyles.noVoto} itemId={el.id} label={nombre} key={el.id} />);

      }
    });
    return childs;
  };

  const getParent = (id,nombre,dependeEstructura,voto) => {

    let parent = [];

    data.map(el => {

      if (el.id == dependeEstructura) {

        let nombreLider = "";

        if (el.voto == 1)
          nombreLider = " Ya voto - " + el.puesto + " - " + el.nombre + " " + el.paterno + " " + el.materno;
        else
          nombreLider = el.puesto + " - " + el.nombre + " " + el.paterno + " " + el.materno;

        let depende = el.usuario_depende_estructura;
      
        /* El usuario es sublider */
        if (el.usuario_depende_estructura == 0) {

          if (el.voto == 1) {

            if (voto == 1)
              parent.push(<TreeItem sx={myStyles.voto} itemId={el.id} label={nombreLider} key={el.id}><TreeItem sx={myStyles.voto} itemId={id} label={nombre} key={id}> {getchild2(id)} </TreeItem></TreeItem>);
            else 
              parent.push(<TreeItem sx={myStyles.voto} itemId={el.id} label={nombreLider} key={el.id}><TreeItem sx={myStyles.noVoto} itemId={id} label={nombre} key={id}> {getchild2(id)} </TreeItem></TreeItem>);

          } else {

            if (voto == 1)
              parent.push(<TreeItem sx={myStyles.noVoto} itemId={el.id} label={nombreLider} key={el.id}><TreeItem sx={myStyles.voto} itemId={id} label={nombre} key={id}> {getchild2(id)} </TreeItem></TreeItem>);
            else
              parent.push(<TreeItem sx={myStyles.noVoto} itemId={el.id} label={nombreLider} key={el.id}><TreeItem sx={myStyles.noVoto} itemId={id} label={nombre} key={id}> {getchild2(id)} </TreeItem></TreeItem>);

          }


        } else { /* El usuario es movilizado */
          parent.push(getParent2(depende,nombreLider,el.id,id,nombre,el.voto,voto));
        }

      }
    });

    return parent;
  };

  const getParent2 = (idLider,nombreSublider,idSublider,idUsuario,nombreUsuario,votoSublider,votoUsuario) => {
    let parent = [];

    data.map(el => {

      if (el.id == idLider) {

        let nombreLider = "";

        if (el.voto == 1)
          nombreLider = "Ya voto - " + el.puesto + " - " + el.nombre + " " + el.paterno + " " + el.materno;
        else
          nombreLider = el.puesto + " - " + el.nombre + " " + el.paterno + " " + el.materno;

        if (el.voto == 1) {

          if (votoSublider == 1) {

            if (votoUsuario == 1)
              parent.push(<TreeItem sx={myStyles.voto} itemId={el.id} label={nombreLider} key={el.id}><TreeItem sx={myStyles.voto} itemId={idSublider} label={nombreSublider} key={idSublider}><TreeItem sx={myStyles.voto} itemId={idUsuario} label={nombreUsuario} key={idUsuario} /></TreeItem></TreeItem>);
            else
              parent.push(<TreeItem sx={myStyles.voto} itemId={el.id} label={nombreLider} key={el.id}><TreeItem sx={myStyles.voto} itemId={idSublider} label={nombreSublider} key={idSublider}><TreeItem sx={myStyles.noVoto} itemId={idUsuario} label={nombreUsuario} key={idUsuario} /></TreeItem></TreeItem>);

          } else {

            if (votoUsuario == 1)
              parent.push(<TreeItem sx={myStyles.voto} itemId={el.id} label={nombreLider} key={el.id}><TreeItem sx={myStyles.noVoto} itemId={idSublider} label={nombreSublider} key={idSublider}><TreeItem sx={myStyles.voto} itemId={idUsuario} label={nombreUsuario} key={idUsuario} /></TreeItem></TreeItem>);
            else
              parent.push(<TreeItem sx={myStyles.voto} itemId={el.id} label={nombreLider} key={el.id}><TreeItem sx={myStyles.noVoto} itemId={idSublider} label={nombreSublider} key={idSublider}><TreeItem sx={myStyles.noVoto} itemId={idUsuario} label={nombreUsuario} key={idUsuario} /></TreeItem></TreeItem>);

          }
            


        } else {

          if (votoSublider == 1) {

            if (votoUsuario == 1)
              parent.push(<TreeItem sx={myStyles.noVoto} itemId={el.id} label={nombreLider} key={el.id}><TreeItem sx={myStyles.voto} itemId={idSublider} label={nombreSublider} key={idSublider}><TreeItem sx={myStyles.voto} itemId={idUsuario} label={nombreUsuario} key={idUsuario} /></TreeItem></TreeItem>);
            else
              parent.push(<TreeItem sx={myStyles.noVoto} itemId={el.id} label={nombreLider} key={el.id}><TreeItem sx={myStyles.voto} itemId={idSublider} label={nombreSublider} key={idSublider}><TreeItem sx={myStyles.noVoto} itemId={idUsuario} label={nombreUsuario} key={idUsuario} /></TreeItem></TreeItem>);

          } else {

            if (votoUsuario == 1)
              parent.push(<TreeItem sx={myStyles.noVoto} itemId={el.id} label={nombreLider} key={el.id}><TreeItem sx={myStyles.noVoto} itemId={idSublider} label={nombreSublider} key={idSublider}><TreeItem sx={myStyles.voto} itemId={idUsuario} label={nombreUsuario} key={idUsuario} /></TreeItem></TreeItem>);
            else
              parent.push(<TreeItem sx={myStyles.noVoto} itemId={el.id} label={nombreLider} key={el.id}><TreeItem sx={myStyles.noVoto} itemId={idSublider} label={nombreSublider} key={idSublider}><TreeItem sx={myStyles.noVoto} itemId={idUsuario} label={nombreUsuario} key={idUsuario} /></TreeItem></TreeItem>);

          }

          

        }
        


      }

    });

    return parent;
  };
  

  return (
    <SimpleTreeView
      aria-label="file system navigator"
      sx={{ flexGrow: 1 }}
    >

      {buildTree()}
      
    </SimpleTreeView>
  );
}