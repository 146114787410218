/**
 * @file Programas.js
 * @brief Administración de los Programas/Apoyos.
 * @author Efraín Gourcy
 * @modified Feb 13, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { contextUserPermissions } from '../../App';
import BtnAceptar from '../../components/BtnAceptar';
import { ApiUrl, settingsWithoutBody } from '../../helpers/ApiUrl';
import GridProgramas from './GridProgramas';

const Programas = () => {

  /** Variables */
  const { userPermissions } = useContext(contextUserPermissions);
  const [programas, setProgramas] = useState(null);
  const [gridProgramas, setGridProgramas] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [permissionEdit, setPermissionEdit] = useState(false);
  const [permissionDelete, setPermissionDelete] = useState(false);
  let url = ApiUrl().url + "programas/get";
  const navigate = useNavigate();

  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Verifica los permisos del usuario */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "programas") {

          if (el.permiso === "ver")
            permission = true;

          if (el.permiso === "nuevo") {
            let $btnNuevoPrograma = document.getElementById("btn-nuevo-programa");
            $btnNuevoPrograma.classList.remove("invisible");
          }

          if (el.permiso === "editar")
            setPermissionEdit(true);

          if (el.permiso === "eliminar")
            setPermissionDelete(true);

        }
      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);


  /**************** useEffect (url) ******************************************************************************* */
  useEffect(() => {
    /** Carga el grid de programas */
    setLoading(true);

    let valueToken = sessionStorage.getItem("token");
  
    let url2 = url;
      if (sessionStorage.getItem("grupo") !== '3881')
        url2 = ApiUrl().url + "programas/get/"+sessionStorage.getItem("escenario");

    fetch(url2, settingsWithoutBody(valueToken, 'GET'))
      .then(res => res.json())
      .then(data => {
        setProgramas(data.programas);
        setGridProgramas(data.programas.slice(0, 5));
        setLoading(false);
      });

  }, [url]);


  /**************** handleSearch ******************************************************************************* */
  const handleSearch = (e) => {
    /** Buscar un elemento dentro del grid de programas */
    let search = e.target.value;
    let usersArray = [];

    if (search.length > 0) {

      let serachByPrograma = false;
      if (isNaN(search))
      serachByPrograma = true;

      usersArray = programas.filter(el => {

        if (serachByPrograma) {
          let programa = el.programa.toLowerCase();
          return programa.includes(search.toLowerCase());
        }
      });

      setGridProgramas(usersArray);
      setIsSearch(true);

    } else {
      setGridProgramas(programas.slice(0, 5));
      setIsSearch(false);
    }
  };


  /**************** updateGrid ******************************************************************************* */
  const updateGrid = () => {
    /** Actualiza el grid */
    let inputSearch = document.getElementById("search");
    inputSearch.value= "";
    setIsSearch(false);

    let valueToken = sessionStorage.getItem("token");

    fetch(ApiUrl().url+"programas/get",settingsWithoutBody(valueToken,'GET'))
      .then(res => res.json())
      .then(data => {
        setProgramas(data.programas);
        setGridProgramas(data.programas.slice(0,5));
        setLoading(false);
      });
  };


  /**************** handleActive ******************************************************************************* */
  const handleActive = (e) => {
    /** Activa / Inactiva los programas */
    if (permissionDelete) {
      setProgramas(null);
      setGridProgramas(null);
      setLoading(true);

      let valueToken = sessionStorage.getItem("token");
      url = `${ApiUrl().url}programas/active/${e.currentTarget.dataset.id}`;
      fetch(url,settingsWithoutBody(valueToken),'GET')
        .then(res => res.json())
        .then(data => {
          updateGrid();
        });
    }
  };


  /**************** return ******************************************************************************* */
  return (
    <>

      <div className="md:py-12 md:px-12 sm:py-3 sm:px-3 py-3 px-3">

        <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-left border-b-2 border-[#3c70b9]">Administrar Programas/Apoyos</h1>

        <div className="p-4 flex bg-gray-50 rounded-lg shadow-xl">
          <div className="flex-none md:flex-grow-0">

            <NavLink id="btn-nuevo-programa" to="/programas/form/0" className="invisible">
              <BtnAceptar text={"Agregar Programa"} />
            </NavLink>

          </div>

          <div className="pl-3 md:pl-96 flex-grow">
            <input id="search" type="text" onChange={handleSearch} className="p-2 w-full rounded border-[#3c70b9] shadow-lg focus:ring-blue-500 focus:outline-none focus:ring" placeholder="Buscar..." />
          </div>
        </div>

        <GridProgramas programas={programas} loading={loading} gridProgramas={gridProgramas} setGridProgramas={setGridProgramas} isSearch={isSearch} permissionEdit={permissionEdit} handleActive={handleActive} />

      </div>
    </>
  );
}

export default Programas;