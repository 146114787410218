/**
 * @file BingoPrep.js
 * @brief Bingo Prep (Programa de resultados preliminares).
 * @author Efraín Gourcy
 * @modified Jul 26, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { contextUserPermissions } from '../../App';
import BtnAceptar from '../../components/BtnAceptar';
//import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
//import { Bar } from 'react-chartjs-2';
import { ApiUrl, settingsWithoutBody } from '../../helpers/ApiUrl';
import ReactECharts from 'echarts-for-react';

//ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend );

const BingoPrep = () => {

  /** Variables */
  const { userPermissions, escenario } = useContext(contextUserPermissions);
  const navigate = useNavigate();
  const [graficaValores, setGraficaValores] = useState([]);
  const [graficaLables, setGraficaLables] = useState([]);
  const [totalVotos, setTotalVotos] = useState(0);

  /**************** loadGraph ******************************************************************************* */
  const loadGraph = async() => {
    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "bingoPrep/get/" + escenario, settingsWithoutBody(token, 'GET'));
    const respPrep = await resp.json();

    /* Obtiene la lista de partidos visibles */
    const response = await fetch(ApiUrl().url + "partidos/get/" + escenario, settingsWithoutBody(token, 'GET'));
    const data = await response.json();

    let valoresGrafica = [];
    let lablesGrafica = [];
    if (respPrep.status === "success") {

      if (data.status === "success") {

        /* Verifica cual partido esta visible para mostrarlo en la gráfica */
        data.partidos.forEach(el => {

          if (el.partido === "pan" && (el.visible === "1" || el.visible === 1)) {
            valoresGrafica.push(respPrep.prep[0].pan);
            lablesGrafica.push("PAN");
          }

          if (el.partido === "pri" && (el.visible === "1" || el.visible === 1)) {
            valoresGrafica.push(respPrep.prep[0].pri);
            lablesGrafica.push("PRI");
          }

          if (el.partido === "prd" && (el.visible === "1" || el.visible === 1)) {
            valoresGrafica.push(respPrep.prep[0].prd);
            lablesGrafica.push("PRD");
          }

          if (el.partido === "pt" && (el.visible === "1" || el.visible === 1)) {
            valoresGrafica.push(respPrep.prep[0].pt);
            lablesGrafica.push("PT");
          }

          if (el.partido === "verde" && (el.visible === "1" || el.visible === 1)) {
            valoresGrafica.push(respPrep.prep[0].verde);
            lablesGrafica.push("VERDE");
          }

          if (el.partido === "mc" && (el.visible === "1" || el.visible === 1)) {
            valoresGrafica.push(respPrep.prep[0].mc);
            lablesGrafica.push("MC");
          }

          if (el.partido === "morena" && (el.visible === "1" || el.visible === 1)) {
            valoresGrafica.push(respPrep.prep[0].morena);
            lablesGrafica.push("MORENA");
          }

          if (el.partido === "pes" && (el.visible === "1" || el.visible === 1)) {
            valoresGrafica.push(respPrep.prep[0].pes);
            lablesGrafica.push("PES");
          }

          if (el.partido === "fsm" && (el.visible === "1" || el.visible === 1)) {
            valoresGrafica.push(respPrep.prep[0].fsm);
            lablesGrafica.push("FSM");
          }

          if (el.partido === "rsp" && (el.visible === "1" || el.visible === 1)) {
            valoresGrafica.push(respPrep.prep[0].rsp);
            lablesGrafica.push("RSP");
          }

          if (el.partido === "pla" && (el.visible === "1" || el.visible === 1)) {
            valoresGrafica.push(respPrep.prep[0].pla);
            lablesGrafica.push("PLA");
          }
        });

      }
      
      valoresGrafica.push(respPrep.prep[0].nulos);
      lablesGrafica.push("NULOS");
      setTotalVotos(respPrep.prep[0].total);

    }

    console.log(valoresGrafica);
    console.log(lablesGrafica);

    setGraficaValores(valoresGrafica);
    setGraficaLables(lablesGrafica);
  };

  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Verifica los permisos del usuario */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "bingoPrep") {

          if (el.permiso === "ver")
            permission = true;

          if (el.permiso === "capturar") {
            let $btnCaptura = document.getElementById("btn-captura");
            $btnCaptura.classList.remove("invisible");
          }

        }
      });

      if (!permission)
        navigate("/");

      loadGraph();
    }

  }, [userPermissions]);


  /**************** Grafica  ******************************************************************************* */
  /*
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Total de Votos: ' + totalVotos,
      },
    },
  };

  const data = {
    labels: graficaLables,
    datasets: [
      {
        label: 'Total de Votos',
        data: graficaValores,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  */

  let option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: graficaLables,
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: 'Votación',
        type: 'bar',
        barWidth: '60%',
        data: graficaValores
      }
    ]
  };


  /**************** return ******************************************************************************* */
  return (
    <>
      <div className="md:py-12 md:px-12 sm:py-3 sm:px-3 py-3 px-3">

        <div className='flex items-center border-b-2 border-[#3c70b9]'>
          <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-left">Bingo PREP</h1>
          <span className='text-xl text-[#3c70b9] ml-2'>(Programa de resultados electorales preliminares)</span>
        </div>

        <div className="p-4 flex bg-gray-50 rounded-lg shadow-xl">
          <div className="flex-none md:flex-grow-0">
            <NavLink id="btn-captura" to="/bingoPrep/captura" className="invisible">
              <BtnAceptar text={"Captura"} />
            </NavLink>
          </div>
        </div>

        {/*
        <div className="w-full md:mt-12 mt-6">
          <Bar options={options} data={data} />
        </div>
        */}

        <div className="w-full md:mt-12 mt-6">
          <ReactECharts 
            option={option}
            style={{ width: '100%', height: '400px', minHeight: '300px', minWidth: '350px'}}
          />
        </div>

        




      </div>
    </>
  );
}

export default BingoPrep;