/**
 * @file Form.js
 * @brief Formulario de estructuras (Nuevo/Editar).
 * @author Efraín Gourcy
 * @modified May 26, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import BtnAceptar from '../../components/BtnAceptar';
import BtnCancelar from '../../components/BtnCancelar';
import Loader from '../../components/Loader';
import { ApiUrl, settingsWithBody, settingsWithoutBody } from '../../helpers/ApiUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contextUserPermissions } from '../../App';

/** Valores iniciales form */
const initalValues = {
  estructura: "",
  puesto: "",
  depende: "",
  nuevaEstructura: "",
  escenario: ""
}

const EstructuraForm = () => {

  /** Variables */
  const [form, setForm] = useState(initalValues);
  const [loading, setLoading] = useState(false);
  const [selectLoaded, setSelectLoaded] = useState(false);
  let { id } = useParams();
  let navigate = useNavigate();
  const [escenarios, setEscenarios] = useState([]);
  const {userPermissions} = useContext(contextUserPermissions);


  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Valida los permisos para el módulo de permisos */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "estructura") {
          if (el.permiso === "nuevo" || el.permiso == "editar")
            permission = true;
        }
      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);


  /**************** useEffect [selectLoaded] ******************************************************************************* */
  useEffect(() => {

    /** Carga los datos de la estructura (modo edición) */
    const loadData = async () => {

      try {
        let valueToken = sessionStorage.getItem("token");
        const response = await fetch(ApiUrl().url + "estructura/getElemento/" + id, settingsWithoutBody(valueToken, 'GET'));
        const respEstructura = await response.json();

        if (respEstructura.estructura) {

          loadSelectDepende(respEstructura.estructura[0].id).then(data => {

            /** Calcula el option correcto del select de estructura */
            let valorEstrucuturaEditar = respEstructura.estructura[0].estructura + " / " + respEstructura.estructura[0].nombre_escenario;
            let valuesSelectEscenario = Array.from(document.getElementById("estructura").options).map(e => [e.innerText, e.value]);
            let idElemento = "-1";

            valuesSelectEscenario.forEach(el => {
              if (valorEstrucuturaEditar === el[0])
                idElemento = el[1];
            });

            setForm({
              ...form,
              estructura: idElemento,
              puesto: respEstructura.estructura[0].puesto,
              depende: respEstructura.estructura[0].depende,
              escenario: respEstructura.estructura[0].escenario
            });

            let $estructura = document.getElementById("estructura");
            $estructura.setAttribute("disabled", "disabled");

            let $divPuesto = document.getElementById("div-puesto");
            $divPuesto.classList.remove("hidden");

            let $divDepende = document.getElementById("div-depende");
            $divDepende.classList.remove("hidden");

          });

        }

        setLoading(false);
      } catch (err) {
        return navigate("/estructura");
      }

    };

    /** Si es modo edición y ya se cargo el select de estructuras */
    if (parseInt(id) !== 0 && selectLoaded) {
      setLoading(true);
      loadData();
    }

  }, [selectLoaded])


  /**************** useEffect [] ******************************************************************************* */
  useEffect(() => {

    /** Carga el select de estructuras */
    const loadSelectEstructura = async () => {

      setLoading(true);

      let token = sessionStorage.getItem("token");


      let response = "";

      if (sessionStorage.getItem("grupo") === "3881")
        response = await fetch(ApiUrl().url + "estructura/getEstructuras", settingsWithoutBody(token, 'GET'));
      else
        response = await fetch(ApiUrl().url + "estructura/getEstructuras/"+sessionStorage.getItem("escenario"), settingsWithoutBody(token, 'GET'));



      const respEstructura = await response.json();

      if (respEstructura.status === "success") {

        let $option;
        let $select = document.getElementById("estructura");
        $select.innerHTML = "";

        $option = document.createElement("option");
        $option.innerText = "Selecciona un opción";
        $option.value = "-1";
        $select.appendChild($option);

        $option = document.createElement("option");
        $option.innerText = "Nueva Estructura";
        $option.value = "0";
        $select.appendChild($option);

        if (respEstructura.estructura) {
          respEstructura.estructura.forEach(el => {
            $option = document.createElement("option");
            $option.innerText = el.estructura + " / " + el.escenario;
            $option.value = el.id;
            $select.appendChild($option);
          });
        }

      }

      setSelectLoaded(true);
      setLoading(false);

    };

    /** Carga los escenarios activos */
    const loadEscenarios = async () => {
      let token = sessionStorage.getItem("token");
      const response = await fetch(ApiUrl().url + "escenarios/getActives/"+sessionStorage.getItem("escenario"), settingsWithoutBody(token, 'GET'));
      const respEscenarios = await response.json();

      if (respEscenarios.escenarios) {
        setEscenarios(respEscenarios.escenarios);
      }

    };

    loadEscenarios();
    loadSelectEstructura();

  }, []);


  /**************** agregaElementoAEstructura ******************************************************************************* */
  const agregaElementoAEstructura = async () => {

    /** Agrega / Edita un elemento a una estructura existente */
    let valueToken = sessionStorage.getItem("token");
    let response;

    if (parseInt(id) === 0)
      response = await fetch(ApiUrl().url + "estructura/addElemento", settingsWithBody(valueToken, form, 'POST'));
    else
      response = await fetch(ApiUrl().url + "estructura/editElemento/" + id, settingsWithBody(valueToken, form, 'POST'));


    const respEstructura = await response.json();

    if (respEstructura.status === "success")
      return navigate("/estructura");


    setLoading(false);
  };


  /**************** agregaNuevaEstructura ******************************************************************************* */
  const agregaNuevaEstructura = async () => {

    /** Agrega una nueva estructura  */
    let valueToken = sessionStorage.getItem("token");
    let response = await fetch(ApiUrl().url + "estructura/agregaEstructura", settingsWithBody(valueToken, form, 'POST'));
    let respStructure = await response.json();

    if (respStructure.status === "success")
      return navigate("/estructura");

    if (respStructure.message === "El nombre de la estructura ya existe") {
      toast.error('El nombre de la estructura ya existe', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
    }

    setLoading(false);
  };


  /**************** handleAccept ******************************************************************************* */
  const handleAccept = (e) => {
    /** Boton Aceptar */
    e.preventDefault();

    /** Resetea los mensajes de error */

    let $selectEstructura = document.getElementById('estructura');
    $selectEstructura.classList.remove("ring-2");
    $selectEstructura.classList.remove("ring-red-400");

    let $inputNuevaEstructura = document.getElementById('nuevaEstructura');
    $inputNuevaEstructura.classList.remove("ring-2");
    $inputNuevaEstructura.classList.remove("ring-red-400");

    let $inputPuesto = document.getElementById('puesto');
    $inputPuesto.classList.remove("ring-2");
    $inputPuesto.classList.remove("ring-red-400");

    let $selectDepende = document.getElementById('depende');
    $selectDepende.classList.remove("ring-2");
    $selectDepende.classList.remove("ring-red-400");

    let $selectEscenario = document.getElementById('escenario');
    $selectEscenario.classList.remove("ring-2");
    $selectEscenario.classList.remove("ring-red-400");


    let error = false;

    /** Valida la información del formulario */
    if (form.estructura === "" || form.estructura === "-1") {
      toast.error('Campo estructura requerido', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
      $selectEstructura.classList.add("ring-2");
      $selectEstructura.classList.add("ring-red-400");
      error = true;
    } else {

      /** Valida campos si es una estructura existente */
      if (form.estructura !== "Nueva Estructura") {

        if (form.puesto === "") {
          toast.error('Campo puesto requerido', {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored"
          });
          $inputPuesto.classList.add("ring-2");
          $inputPuesto.classList.add("ring-red-400");
          error = true;
        }

        if (form.depende === "-1" || form.depende === "") {
          toast.error('Campo depende requerido', {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored"
          });
          $selectDepende.classList.add("ring-2");
          $selectDepende.classList.add("ring-red-400");
          error = true;
        }

        /** Valida campos si es una nueva estructura */
      } else {

        if (form.nuevaEstructura === "") {
          toast.error('Campo estructura requerido', {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored"
          });
          $inputNuevaEstructura.classList.add("ring-2");
          $inputNuevaEstructura.classList.add("ring-red-400");
          error = true;
        }

        if (form.puesto === "") {
          toast.error('Campo puesto requerido', {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored"
          });
          $inputPuesto.classList.add("ring-2");
          $inputPuesto.classList.add("ring-red-400");
          error = true;
        }

        if (form.escenario === "") {
          toast.error('Campo escenario requerido', {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored"
          });
          $selectEscenario.classList.add("ring-2");
          $selectEscenario.classList.add("ring-red-400");
          error = true;
        }
      }
    }

    if (!error) {
      setLoading(true);

      if (form.estructura === "Nueva Estructura") {
        agregaNuevaEstructura();
      } else {
        agregaElementoAEstructura();
      }
    }

  }


  /**************** loadSelectDepende ******************************************************************************* */
  const loadSelectDepende = async (value) => {
    /** Carga el select Depende */
    let token = sessionStorage.getItem("token");
    const response = await fetch(ApiUrl().url + "estructura/getPuestos", settingsWithBody(token, { id_estructura: value }, 'POST'));
    const respEstructura = await response.json();

    let $option;
    let $select = document.getElementById("depende");

    $option = document.createElement("option");
    $option.innerText = "Selecciona una opción";
    $option.value = "-1";
    $select.appendChild($option);

    $option = document.createElement("option");
    $option.innerText = "No Depende de Nadie";
    $option.value = "0";
    $select.appendChild($option);

    let escenarioEstructura = 0;

    if (respEstructura.estructura) {
      respEstructura.estructura.forEach(el => {

        /** Un nodo no dependa de si mismo */
        if (value !== el.id) {
          $option = document.createElement("option");
          $option.innerText = el.puesto;
          $option.value = el.id;
          $select.appendChild($option);
          escenarioEstructura = el.escenario;
        }

      });
    }

    setForm({
      ...form,
      estructura: value,
      escenario: escenarioEstructura
    });


    setLoading(false);

  };


  /**************** loadSelectEscenarios ******************************************************************************* */
  const loadSelectEscenarios = () => {
    /** Carga el select escenarios */
    let $optionEscenarios;
    let $selectEscenarios = document.getElementById("escenario");
    $selectEscenarios.innerHTML = "";

    $optionEscenarios = document.createElement("option");
    $optionEscenarios.innerText = "Selecciona un opción";
    $optionEscenarios.value = "-1";
    $selectEscenarios.appendChild($optionEscenarios);

    if (escenarios) {
      escenarios.forEach(el => {
        $optionEscenarios = document.createElement("option");
        $optionEscenarios.innerText = el.nombre;
        $optionEscenarios.value = el.id;
        $selectEscenarios.appendChild($optionEscenarios);
      });

    }

  };


  /**************** handleChange ******************************************************************************* */
  const handleChange = (e) => {
    /** Cambio en cualquier elemento del formulario */

    /** Si el target es el select de estructura */
    if (e.target.id === "estructura") {
      setLoading(true);

      /** Resetea el formulario */
      let $select = document.getElementById("depende");
      $select.innerHTML = "";

      let $inputNuevaEstructura = document.getElementById("nuevaEstructura");
      let $selectEstructura = document.getElementById("estructura");

      let $divPuesto = document.getElementById("div-puesto");
      $divPuesto.classList.add("hidden");

      let $divDepende = document.getElementById("div-depende");
      $divDepende.classList.add("hidden");

      let $selectEscenario = document.getElementById("escenario");
      $selectEscenario.innerHTML = "";

      let $divEscenario = document.getElementById("div-escenario");
      $divEscenario.classList.add("hidden");

      /** Nueva estructura */
      if (e.target.value === "0") {

        setForm({
          ...form,
          estructura: "Nueva Estructura"
        });

        $selectEstructura.classList.add("hidden");
        $inputNuevaEstructura.classList.remove("hidden");
        $divPuesto.classList.remove("hidden");
        $divEscenario.classList.remove("hidden");
        loadSelectEscenarios();
        setLoading(false);

        /** Agregar elemento a una estructura existente */
      } else {
        /** Si la opcion del select estructura es diferente a "Selecciona una opción" */
        if (e.target.value !== "-1") {
          $divPuesto.classList.remove("hidden");
          $divDepende.classList.remove("hidden");
          loadSelectDepende(e.target.value);
        } else {
          setLoading(false);
        }

        setForm({
          ...form,
          [e.target.name]: e.target.value
        });
      }

    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value
      });
    }

  };


  /**************** return ******************************************************************************* */
  return (
    <>
      <ToastContainer />
      <div className="py-6 px-2 bg-gray-100">

        <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-center">Crea una Nueva Estrucrura</h1>

        <form>
          <div className="px-2 md:py-6 py-2 grid md:grid-cols-3 gap-3">

            <div id="div-estructura" className="md:col-start-2">
              <label htmlFor="estructura" className="text-gray-600">Estructura</label>
              <select id="estructura" name="estructura" value={form.estructura} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
              </select>
              <input type="text" id="nuevaEstructura" name="nuevaEstructura" autoComplete="true" value={form.nuevaEstructura} onChange={handleChange} className="hidden w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div id="div-puesto" className="md:col-start-2 hidden">
              <label htmlFor="puesto" className="text-gray-600">Puesto</label>
              <input type="text" id="puesto" name="puesto" autoComplete="true" value={form.puesto} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div id="div-depende" className="md:col-start-2 hidden">
              <label htmlFor="depende" className="text-gray-600">Depende</label>
              <select id="depende" name="depende" value={form.depende} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
              </select>
            </div>

            <div id="div-escenario" className="md:col-start-2 hidden">
              <label htmlFor="escenario" className="text-gray-600">Escenario</label>
              <select id="escenario" name="escenario" value={form.escenario} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
              </select>
            </div>

            <div className="p-1 md:py-4 md:col-start-2 justify-self-end">
              {loading
                ? <Loader />
                : <>
                  <span onClick={handleAccept} className="mr-4"><BtnAceptar text={"Guardar"} /></span>

                  <NavLink to="/estructura">
                    <BtnCancelar text={"Cancelar "} />
                  </NavLink>
                </>
              }
            </div>

          </div>
        </form>

      </div>
    </>
  );
}

export default EstructuraForm;