/**
 * @file Nuevo.js
 * @brief Formulario para insertar un nuevo registro en el padrón.
 * @author Efraín Gourcy
 * @modified Abr 7, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import BtnAceptar from '../../components/BtnAceptar';
import BtnCancelar from '../../components/BtnCancelar';
import Loader from '../../components/Loader';
import { ApiUrl, settingsWithBody, settingsWithoutBody } from '../../helpers/ApiUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contextUserPermissions } from "../../App";

/** Inicializacion del los datos del formulario */
const initalValues = {
  folio: "",           //
  numeroElectoral: "", //
  casilla: "",         //
  claveElectoral: "",
  paterno: "",
  materno: "",
  nombre: "",
  calle: "",
  interior: "",        //
  exterior: "",
  colonia: "",
  seccion: "",
  localidad: "",
  distritoLocal: "",
  municipio: "",
  distritoFederal: "",
  telefonoMovil: "",
  estructura: "",
  puesto: "",
  depende: "",
  escenario: ""
};

const valuesEditarPadron = {
  estructura: "",
  puesto: "",
  depende: ""
};

const IntencionNuevo = () => {

  /** Variables */
  let { id } = useParams();
  const [form, setForm] = useState(initalValues);
  const [loading, setLoading] = useState(false);
  const { userPermissions, escenario } = useContext(contextUserPermissions);
  const navigate = useNavigate();
  const [puestoValido, setPuestoValido] = useState(false);
  const [valoresEditarPadron, setValoresEditarPadron] = useState(valuesEditarPadron);
  const [programas, setProgramas] = useState({});


  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Validación de los permisos del usuario */
    if (userPermissions) {

      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "intencion") {

          if (el.permiso === "captura" || el.permiso == "editar")
            permission = true;

        }
      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);


  /**************** useEffect [] ******************************************************************************* */
  useEffect(() => {

    /** Carga la información del padrón de acuerdo al id */
    const cargaDatosPadron = async () => {
      let token = sessionStorage.getItem("token");
      const response = await fetch(ApiUrl().url + "padron/getPadron/" + id, settingsWithoutBody(token, 'GET'));
      const respPadron = await response.json();

      if (respPadron.status === 'success' && respPadron.padron.length > 0) {

        setForm({
          ...form,
          folio: respPadron.padron[0].folio,
          numeroElectoral: respPadron.padron[0].numero_electoral,
          casilla: respPadron.padron[0].casilla,
          claveElectoral: respPadron.padron[0].clave_electoral,
          paterno: respPadron.padron[0].paterno,
          materno: respPadron.padron[0].materno,
          nombre: respPadron.padron[0].nombre,
          calle: respPadron.padron[0].calle,
          interior: respPadron.padron[0].interior,
          exterior: respPadron.padron[0].exterior,
          colonia: respPadron.padron[0].colonia,
          seccion: respPadron.padron[0].seccion,
          localidad: respPadron.padron[0].localidad,
          distritoLocal: respPadron.padron[0].distrito_local,
          municipio: respPadron.padron[0].municipio,
          distritoFederal: respPadron.padron[0].distrito_federal,
          telefonoMovil: respPadron.padron[0].telefono_movil
        });

        setValoresEditarPadron({
          ...valoresEditarPadron,
          estructura: respPadron.padron[0].estructura,
          puesto: respPadron.padron[0].puesto,
          depende: respPadron.padron[0].depende
        });

        setPuestoValido(true);

      } else
        navigate('/intencion/consultar');
    };

    //if (escenario === null)
      //navigate("/");

    /** != 0 Edición del Padrón */
    if (parseInt(id) !== 0)
      cargaDatosPadron();

  }, []);


  /**************** useEffect [id] ******************************************************************************* */
  useEffect(() => {
    /** Carga estructuras de acuerdo al escenario */
    const cargaEstructuras = async () => {

      setLoading(true);

      let valorEscenario = escenario;
      if (valorEscenario === null) {
        valorEscenario = sessionStorage.getItem('escenario');
      }

      setForm({
        ...form,
        escenario: valorEscenario
      });

      let token = sessionStorage.getItem("token");
      const response = await fetch(ApiUrl().url + "estructura/getEstructurasConEscenario/" + valorEscenario, settingsWithoutBody(token, 'GET'));
      const respEstructura = await response.json();

      let $option;
      let $select = document.getElementById("estructura");
      $select.innerHTML = "";

      $option = document.createElement("option");
      $option.innerText = "Selecciona una opción";
      $option.value = "-1";
      $select.appendChild($option);

      if (respEstructura.estructura) {
        respEstructura.estructura.forEach(el => {
          $option = document.createElement("option");
          $option.innerText = el.estructura;
          $option.value = el.id;
          $select.appendChild($option);
        });
      }

      setLoading(false);

    };

    if (parseInt(id) === 0) {
      cargaEstructuras();
    }
  }, [id]);


  /**************** useEffect [programas] ******************************************************************************* */
  useEffect(() => {
    /** Cargar la lista de programas y busca los programas que tiene el usuario del padron */
    if (Object.keys(programas).length > 0) {

      setLoading(true);

      let token = sessionStorage.getItem("token");
      fetch(ApiUrl().url + "programas/padron/get/" + id, settingsWithoutBody(token, 'GET'))
        .then(data => data.json())
        .then(data => {

          let $divEl;
          let $element;
          let $label;
          let $permisos = document.getElementById("div-programas");
          $permisos.innerHTML = "";

          let $div = document.createElement("div");
          $div.classList.add("grid", "grid-cols-2", "md:grid-cols-3", "gap-4", "items-center", "mb-2");

          programas.forEach(el => {

            $divEl = document.createElement("div");
            $element = document.createElement("input");
            $element.type = "checkbox";
            $element.name = el.id;
            $element.value = el.id;
            $element.id = el.id;
            $element.classList.add("cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

            $divEl.setAttribute("data-te-toggle", "tooltip");
            $divEl.setAttribute("title", el.descripcion);

            if (data.programas) {
              data.programas.forEach(elProgramas => {

                if (parseInt(elProgramas.programa) === parseInt(el.id))
                  $element.checked = true;

              });
            }

            $label = document.createElement("label");
            $label.htmlFor = el.id;
            $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
            $label.appendChild(document.createTextNode(el.programa));

            $divEl.appendChild($element);
            $divEl.appendChild($label);
            $div.appendChild($divEl);

            $permisos.appendChild($div);

          });

        });

      setLoading(false);
    }

  }, [programas]);


  /**************** useEffect [escenario] ******************************************************************************* */
  useEffect(() => {
    /** Obtienen los programas de acuerdo al escenario */
    if (escenario !== null) {
      let token = sessionStorage.getItem("token");
      fetch(ApiUrl().url + "programas/getConEscenario/" + escenario, settingsWithoutBody(token, 'GET'))
        .then(data => data.json())
        .then(data => {
          setProgramas(data.programas);
        });
    }

  }, [escenario]);


  /**************** loadPuestos ******************************************************************************* */
  /** Carga los puestos de acuerdo a la estructura seleccionada */
  const loadPuestos = async (id_estructura) => {

    let token = sessionStorage.getItem("token");
    const response = await fetch(ApiUrl().url + "estructura/getPuestos", settingsWithBody(token, { id_estructura }, 'POST'));
    const respEstructura = await response.json();

    if (respEstructura.status === "success") {

      if (respEstructura.estructura.length > 0) {
        let $option;
        let $select = document.getElementById("puesto");
        $select.innerHTML = "";

        $option = document.createElement("option");
        $option.innerText = "Selecciona un opción";
        $option.value = "-1";
        $select.appendChild($option);

        respEstructura.estructura.forEach(el => {
          $option = document.createElement("option");
          $option.innerText = el.puesto;
          $option.value = el.id;
          $select.appendChild($option);
        });

        let $divPuesto = document.getElementById("div-puesto");
        $divPuesto.classList.remove("hidden");
      }

    }

    setLoading(false);
  };


  /**************** getDependientesPuesto ******************************************************************************* */
  const getDependientesPuesto = async (id_puesto) => {
    let token = sessionStorage.getItem("token");
    let response = await fetch(ApiUrl().url + "estructura/getDependientesPuesto", settingsWithBody(token, { depende: id_puesto }, 'POST'));
    const respEstructura = await response.json();

    if (respEstructura.status === "success") {
      let $puesto = document.getElementById("puesto");
      /** Si el puesto es un liderazo (nodo raiz de la estructura) */
      if (respEstructura.estructura[0].depende === "0" || respEstructura.estructura[0].depende === 0) {
        setForm({
          ...form,
          puesto: $puesto.value,
          depende: "0"
        });

        setPuestoValido(true);
        /** Si el puesto necesita depender de alguien */
      } else {
        setForm({
          ...form,
          puesto: $puesto.value,
          depende: ""
        });

        setPuestoValido(false);

        response = await fetch(ApiUrl().url + "padron/getPuesto", settingsWithBody(token, { puesto_estructura: respEstructura.estructura[0].depende }, 'POST'));
        const respPadron = await response.json();

        let $option;
        let $select = document.getElementById("depende");
        $select.innerHTML = "";

        $option = document.createElement("option");
        $option.innerText = "Selecciona un opción";
        $option.value = "-1";
        $select.appendChild($option);

        respPadron.padron.forEach(el => {
          $option = document.createElement("option");
          $option.innerText = el.nombre + " " + el.paterno + " " + el.materno;
          $option.value = el.id;
          $select.appendChild($option);
        });

        let $divDepende = document.getElementById("div-depende");
        $divDepende.classList.remove("hidden");

      }

    }
  };


  /**************** handleChange ******************************************************************************* */
  const handleChange = (e) => {
    /** Actualización de los campos del formulario */

    let $divDepende = document.getElementById("div-depende");

    /**  Si es el select de Estructura */
    if (e.target.id === "estructura") {

      $divDepende.classList.add("hidden");

      /** Válida que haya seleccionado una opción */
      if (e.target.value !== "-1") {
        setLoading(true);
        loadPuestos(e.target.value);
        /** Oculta el div de puestos */
      } else {
        let $divPuesto = document.getElementById("div-puesto");
        $divPuesto.classList.add("hidden");
      }
      setForm({
        ...form,
        estructura: e.target.value,
        puesto: "-1"
      });

      /** Si es el select de puesto */
    } else if (e.target.id === "puesto") {

      $divDepende.classList.add("hidden");
      /** Si selecciona una opción */
      if (e.target.value !== "-1") {
        getDependientesPuesto(e.target.value);
      }

      setForm({
        ...form,
        puesto: e.target.value
      });

      /** Si es el select de depende */
    } else if (e.target.id === "depende") {
      /** Si selecciona una opción */
      if (e.target.value !== "-1") {
        setForm({
          ...form,
          depende: e.target.value
        });
        setPuestoValido(true);
      }

      /** Demas campos del formulario */
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value.toUpperCase()
      });
    }
  };


  /**************** handleNumericChange ******************************************************************************* */
  const handleNumericChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value.replace(/[^0-9]/g, '')
    });
  };
  

  /**************** store ******************************************************************************* */
  const store = async () => {
    /** Guarda el registro */
    let token = sessionStorage.getItem("token");
    let response;

    if (parseInt(id) === 0)
      response = await fetch(ApiUrl().url + "padron/store", settingsWithBody(token, form, 'POST'));
    else
      response = await fetch(ApiUrl().url + "padron/edit/" + id, settingsWithBody(token, form, 'POST'));

    const respPadron = await response.json();

    if (respPadron.status === "success") {

      let idUsuario = respPadron.padron.id;

      let $elements = document.getElementById('div-programas').getElementsByTagName('input');
      let programasSeleccionados = [];

      for (var i = 0; i < $elements.length; i++) {
        if ($elements[i].checked === true)
          programasSeleccionados.push($elements[i].id);
      }
      let listaProgramas = {
        programas: programasSeleccionados,
        idUsuario: idUsuario
      };

      response = await fetch(ApiUrl().url + "programas/guardaProgramas", settingsWithBody(token, listaProgramas, 'POST'));
      const respProgramas = await response.json();

      if (respProgramas.status !== "success") {
        toast.error('No se pudo guardar los Programas de Beneficiarios', {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored"
        });
      }


      /** Nuevo Registro */
      if (parseInt(id) === 0) {
        setForm(initalValues);
        let $divPuesto = document.getElementById("div-puesto");
        $divPuesto.classList.add("hidden");
        let $divDepende = document.getElementById("div-depende");
        $divDepende.classList.add("hidden");

        /*
        toast.success('Registro guardado', {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored"
        });
        */

        navigate("/intencion/consultar");
        /** Actualización del registri (edición) */
      } else
        navigate("/intencion/consultar");


    } else {
      if (respPadron.status === "Not Valid") {
        toast.error('El registro ya existe', {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored"
        });
      } else {
        toast.error('Ocurrió un error al guardar el registro', {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored"
        });
      }
    }

  };


  /**************** handleAccept ******************************************************************************* */
  const handleAccept = (e) => {
    /** Clic al botón de Aceptar */
    e.preventDefault();

    setLoading(true);

    /*
    let numeroElectoral = form.numeroElectoral;
    let casilla = form.casilla;
    let interior = form.interior;
    */

    /** Resetea los mensajes de error */
    /*
    let $inputFolio = document.getElementById('folio');
    $inputFolio.classList.remove("ring-2");
    $inputFolio.classList.remove("ring-red-400");
    */

    /*
    let $inputNumeroElectoral = document.getElementById('numeroElectoral');
    $inputNumeroElectoral.classList.remove("ring-2");
    $inputNumeroElectoral.classList.remove("ring-red-400");
    */

    /*
    let $inputCasilla = document.getElementById('casilla');
    $inputCasilla.classList.remove("ring-2");
    $inputCasilla.classList.remove("ring-red-400");
    */

    let $inputClaveElectoral = document.getElementById('claveElectoral');
    $inputClaveElectoral.classList.remove("ring-2");
    $inputClaveElectoral.classList.remove("ring-red-400");

    let $inputPaterno = document.getElementById('paterno');
    $inputPaterno.classList.remove("ring-2");
    $inputPaterno.classList.remove("ring-red-400");

    let $inputMaterno = document.getElementById('materno');
    $inputMaterno.classList.remove("ring-2");
    $inputMaterno.classList.remove("ring-red-400");

    let $inputNombre = document.getElementById('nombre');
    $inputNombre.classList.remove("ring-2");
    $inputNombre.classList.remove("ring-red-400");

    let $inputCalle = document.getElementById('calle');
    $inputCalle.classList.remove("ring-2");
    $inputCalle.classList.remove("ring-red-400");

    /*
    let $inputInterior = document.getElementById('interior');
    $inputInterior.classList.remove("ring-2");
    $inputInterior.classList.remove("ring-red-400");
    */

    let $inputExterior = document.getElementById('exterior');
    $inputExterior.classList.remove("ring-2");
    $inputExterior.classList.remove("ring-red-400");

    let $inputColonia = document.getElementById('colonia');
    $inputColonia.classList.remove("ring-2");
    $inputColonia.classList.remove("ring-red-400");

    let $inputSeccion = document.getElementById('seccion');
    $inputSeccion.classList.remove("ring-2");
    $inputSeccion.classList.remove("ring-red-400");

    let $inputLocalidad = document.getElementById('localidad');
    $inputLocalidad.classList.remove("ring-2");
    $inputLocalidad.classList.remove("ring-red-400");

    let $inputDistritoLocal = document.getElementById('distritoLocal');
    $inputDistritoLocal.classList.remove("ring-2");
    $inputDistritoLocal.classList.remove("ring-red-400");

    let $inputMunicipio = document.getElementById('municipio');
    $inputMunicipio.classList.remove("ring-2");
    $inputMunicipio.classList.remove("ring-red-400");

    let $inputDistritoFederal = document.getElementById('distritoFederal');
    $inputDistritoFederal.classList.remove("ring-2");
    $inputDistritoFederal.classList.remove("ring-red-400");

    let $inputTelefonoMovil = document.getElementById('telefonoMovil');
    $inputTelefonoMovil.classList.remove("ring-2");
    $inputTelefonoMovil.classList.remove("ring-red-400");


    /** Si es nuevo registro (Valida estos campos) */
    let $inputEstructura = document.getElementById('estructura');
    let $inputPuesto = document.getElementById('puesto');
    let $inputDepende = document.getElementById('depende');

    /*
    $inputEstructura.classList.remove("ring-2");
    $inputEstructura.classList.remove("ring-red-400");

    $inputPuesto.classList.remove("ring-2");
    $inputPuesto.classList.remove("ring-red-400");

    $inputDepende.classList.remove("ring-2");
    $inputDepende.classList.remove("ring-red-400");
    */

    if (parseInt(id) === 0) {

      $inputEstructura.classList.remove("ring-2");
      $inputEstructura.classList.remove("ring-red-400");

      $inputPuesto.classList.remove("ring-2");
      $inputPuesto.classList.remove("ring-red-400");

      $inputDepende.classList.remove("ring-2");
      $inputDepende.classList.remove("ring-red-400");

    }

    /** Valida los datos del formulario */
    let error = false;
    let errorMsg = "";
    let valueInt = 0;

    /*
    let valueInt = parseInt(form.folio);
    if (form.folio === "" || !Number.isInteger(valueInt)) {
      $inputFolio.classList.add("ring-2");
      $inputFolio.classList.add("ring-red-400");
      errorMsg = errorMsg + "Folio" + " ";
      error = true;
    }
    */
   
    /*
    valueInt = parseInt(form.numeroElectoral);
    if (form.numeroElectoral === "" || !Number.isInteger(valueInt)) {
      numeroElectoral = "0";
    }
    if (form.casilla === "") {
      casilla = "0";
    }
    */
    if (form.claveElectoral === "") {
      $inputClaveElectoral.classList.add("ring-2");
      $inputClaveElectoral.classList.add("ring-red-400");
      errorMsg = errorMsg + "Clave Electoral" + " ";
      error = true;
    }
    if (form.paterno === "") {
      $inputPaterno.classList.add("ring-2");
      $inputPaterno.classList.add("ring-red-400");
      errorMsg = errorMsg + "Apellido Paterno" + " ";
      error = true;
    }
    if (form.materno === "") {
      $inputMaterno.classList.add("ring-2");
      $inputMaterno.classList.add("ring-red-400");
      errorMsg = errorMsg + "Apellido Materno" + " ";
      error = true;
    }
    if (form.nombre === "") {
      $inputNombre.classList.add("ring-2");
      $inputNombre.classList.add("ring-red-400");
      errorMsg = errorMsg + "Nombre(s)" + " ";
      error = true;
    }
    if (form.calle === "") {
      $inputCalle.classList.add("ring-2");
      $inputCalle.classList.add("ring-red-400");
      errorMsg = errorMsg + "Calle" + " ";
      error = true;
    }
    /*
    if (form.interior === "") {
      interior = "0";
    }
    */
    if (form.exterior === "") {
      $inputExterior.classList.add("ring-2");
      $inputExterior.classList.add("ring-red-400");
      errorMsg = errorMsg + "Núm. Exterior" + " ";
      error = true;
    }
    if (form.colonia === "") {
      $inputColonia.classList.add("ring-2");
      $inputColonia.classList.add("ring-red-400");
      errorMsg = errorMsg + "Colonia" + " ";
      error = true;
    }
    valueInt = parseInt(form.seccion);
    if (form.seccion === "" || !Number.isInteger(valueInt)) {
      $inputSeccion.classList.add("ring-2");
      $inputSeccion.classList.add("ring-red-400");
      errorMsg = errorMsg + "Sección" + " ";
      error = true;
    }
    if (form.localidad === "") {
      $inputLocalidad.classList.add("ring-2");
      $inputLocalidad.classList.add("ring-red-400");
      errorMsg = errorMsg + "Localidad" + " ";
      error = true;
    }
    valueInt = parseInt(form.distritoLocal);
    if (form.distritoLocal === "" || !Number.isInteger(valueInt)) {
      $inputDistritoLocal.classList.add("ring-2");
      $inputDistritoLocal.classList.add("ring-red-400");
      errorMsg = errorMsg + "Distrito Local" + " ";
      error = true;
    }
    if (form.municipio === "") {
      $inputMunicipio.classList.add("ring-2");
      $inputMunicipio.classList.add("ring-red-400");
      errorMsg = errorMsg + "Municipio" + " ";
      error = true;
    }
    valueInt = parseInt(form.distritoFederal);
    if (form.distritoFederal === "" || !Number.isInteger(valueInt)) {
      $inputDistritoFederal.classList.add("ring-2");
      $inputDistritoFederal.classList.add("ring-red-400");
      errorMsg = errorMsg + "Distrito Federal" + " ";
      error = true;
    }
    if (form.telefonoMovil === "") {
      $inputTelefonoMovil.classList.add("ring-2");
      $inputTelefonoMovil.classList.add("ring-red-400");
      errorMsg = errorMsg + "Telefóno Móvil" + " ";
      error = true;
    }
    /** Si es nuevo registro (valida estos campos) */
    if (parseInt(id) === 0) {
      if (form.estructura === "" || form.estructura === "-1") {
        $inputEstructura.classList.add("ring-2");
        $inputEstructura.classList.add("ring-red-400");
        error = true;
      }
      if (form.puesto === "" || form.puesto === "-1") {
        $inputPuesto.classList.add("ring-2");
        $inputPuesto.classList.add("ring-red-400");
        error = true;
      }
      if (!puestoValido) {
        if (form.depende === "" || form.depende === "-1") {
          $inputDepende.classList.add("ring-2");
          $inputDepende.classList.add("ring-red-400");
          error = true;
        }
      }
    }
    /*
    setForm({
      ...form,
      numeroElectoral: numeroElectoral,
      casilla: casilla,
      interior: interior
    });
    */
    if (!error && puestoValido)
      store();
    else {
      toast.error('Error en los campos: ' + errorMsg, {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
    }


    setLoading(false);
  };


  /**************** return ******************************************************************************* */
  return (
    <>
      <div className="py-6 px-2 bg-gray-100">

        <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-center">Crea un Nuevo registro</h1>

        <ToastContainer />

        <form>
          <div className="md:px-24 px-2 md:py-6 py-2 grid md:grid-cols-12 gap-3">

            <div className="md:col-span-4">
              <label htmlFor="folio" className="text-gray-600">Folio</label>
              <input type="text" id="folio" name="folio" autoComplete="true" value={form.folio} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" autoFocus />
            </div>

            <div className="md:col-span-4">
              <label htmlFor="numeroElectoral" className="text-gray-600">Número Electoral</label>
              <input type="text" id="numeroElectoral" name="numeroElectoral" autoComplete="true" value={form.numeroElectoral} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="md:col-span-4">
              <label htmlFor="casilla" className="text-gray-600">Casilla</label>
              <input type="text" id="casilla" name="casilla" autoComplete="true" value={form.casilla} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="md:col-span-12">
              <label htmlFor="claveElectoral" className="text-gray-600">Clave Electoral</label>
              <input type="text" id="claveElectoral" name="claveElectoral" autoComplete="true" value={form.claveElectoral} maxLength="18" onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="md:col-span-4">
              <label htmlFor="paterno" className="text-gray-600">Apellido Paterno</label>
              <input type="text" id="paterno" name="paterno" autoComplete="true" value={form.paterno} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="md:col-span-4">
              <label htmlFor="materno" className="text-gray-600">Apellido Materno</label>
              <input type="text" id="materno" name="materno" autoComplete="true" value={form.materno} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="md:col-span-4">
              <label htmlFor="nombre" className="text-gray-600">Nombre(s)</label>
              <input type="text" id="nombre" name="nombre" autoComplete="true" value={form.nombre} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="md:col-span-8">
              <label htmlFor="calle" className="text-gray-600">Calle</label>
              <input type="text" id="calle" name="calle" autoComplete="true" value={form.calle} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="md:col-span-2">
              <label htmlFor="interior" className="text-gray-600">Núm. Interior</label>
              <input type="text" id="interior" name="interior" autoComplete="true" value={form.interior} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="md:col-span-2">
              <label htmlFor="exterior" className="text-gray-600">Núm. Exterior</label>
              <input type="text" id="exterior" name="exterior" autoComplete="true" value={form.exterior} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="md:col-span-10">
              <label htmlFor="colonia" className="text-gray-600">Colonia</label>
              <input type="text" id="colonia" name="colonia" autoComplete="true" value={form.colonia} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="md:col-span-2">
              <label htmlFor="seccion" className="text-gray-600">Sección</label>
              <input type="text" id="seccion" name="seccion" autoComplete="true" value={form.seccion} onChange={handleNumericChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="md:col-span-12">
              <label htmlFor="localidad" className="text-gray-600">Localidad</label>
              <input type="text" id="localidad" name="localidad" autoComplete="true" value={form.localidad} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="md:col-span-4">
              <label htmlFor="distritoLocal" className="text-gray-600">Distrito Local</label>
              <input type="text" id="distritoLocal" name="distritoLocal" autoComplete="true" value={form.distritoLocal} onChange={handleNumericChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="md:col-span-4">
              <label htmlFor="municipio" className="text-gray-600">Municipio</label>
              <input type="text" id="municipio" name="municipio" autoComplete="true" value={form.municipio} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="md:col-span-4">
              <label htmlFor="distritoFederal" className="text-gray-600">Distrito Federal</label>
              <input type="text" id="distritoFederal" name="distritoFederal" autoComplete="true" value={form.distritoFederal} onChange={handleNumericChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            <div className="md:col-span-4">
              <label htmlFor="telefonoMovil" className="text-gray-600">Teléfono Móvil</label>
              <input type="text" id="telefonoMovil" name="telefonoMovil" autoComplete="true" maxLength="10" value={form.telefonoMovil} onChange={handleNumericChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
            </div>

            {parseInt(id) === 0
              ? <>
                <div className="md:col-span-4 md:col-start-1">
                  <label htmlFor="estructura" className="text-gray-600">Estructura</label>
                  <select id="estructura" name="estructura" value={form.estructura} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
                  </select>
                </div>

                <div id="div-puesto" className="md:col-span-4 hidden">
                  <label htmlFor="puesto" className="text-gray-600">Puesto</label>
                  <select id="puesto" name="puesto" value={form.puesto} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
                  </select>
                </div>

                <div id="div-depende" className="md:col-span-4 hidden">
                  <label htmlFor="depende" className="text-gray-600">Depende</label>
                  <select id="depende" name="depende" value={form.depende} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
                  </select>
                </div>
              </>
              : <>
                <div className="md:col-span-4 md:col-start-1">
                  <label htmlFor="estructura" className="text-gray-600">Estructura</label>
                  <input type="text" value={valoresEditarPadron.estructura} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none border bg-gray-200 border-gray-300 text-gray-500" readOnly={true} />
                </div>
                <div className="md:col-span-4">
                  <label htmlFor="estructura" className="text-gray-600">Puesto</label>
                  <input type="text" value={valoresEditarPadron.puesto} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none border bg-gray-200 border-gray-300 text-gray-500" readOnly={true} />
                </div>
                {valoresEditarPadron.depende
                  ? <>
                    <div className="md:col-span-4">
                      <label htmlFor="estructura" className="text-gray-600">Depende</label>
                      <input type="text" value={valoresEditarPadron.depende} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none border bg-gray-200 border-gray-300 text-gray-500" readOnly={true} />
                    </div>
                  </>
                  : <></>
                }

              </>
            }

            <div id="div-programas" className="mt-16 px-16 mt-3 text-letf md:col-span-12">
            </div>

            <div className="p-1 md:py-14 md:col-span-12 justify-self-end">
              {loading
                ? <Loader />
                : <>
                  <span onClick={handleAccept} className="mr-4"><BtnAceptar text={"Guardar"} /></span>

                  {parseInt(id) === 0
                    ? <>
                      <NavLink to="/">
                        <BtnCancelar text={"Cancelar "} />
                      </NavLink>
                    </>
                    : <>
                      <NavLink to="/intencion/consultar">
                        <BtnCancelar text={"Cancelar "} />
                      </NavLink>
                    </>
                  }
                </>
              }
            </div>

          </div>
        </form>

      </div>
    </>
  );
}

export default IntencionNuevo;