import React, { useEffect } from 'react';
import { ApiUrl, settingsWithoutBody } from '../../helpers/ApiUrl';

const Secciones = () => {

  useEffect(() => {

    const getSecciones = async () => {
      let valorEscenario = sessionStorage.getItem('escenario');
      let token = sessionStorage.getItem("token");
      const resp = await fetch(ApiUrl().url + "padron/getSecciones/" + valorEscenario, settingsWithoutBody(token, 'GET'));
      const respSecciones = await resp.json();

      if (respSecciones.padron) {
        
        let $selectSecciones = document.getElementById('secciones');
        $selectSecciones.innerHTML = '';

        let $option = document.createElement('option');
        $option.value = '-1';
        $option.innerText = 'Selecciona una opción';
        $selectSecciones.appendChild($option);

        
        respSecciones.padron.forEach(el => {
          let $option = document.createElement('option');
          $option.value = el.seccion;
          $option.innerText = el.seccion;
          $selectSecciones.appendChild($option);
        });
      }
    }

    getSecciones();
    
  }, [])


  const getData = async(seccion) => {

    let $tablaSeccionesMovilizadores = document.getElementById('tabla-secciones-movilizadores');
    $tablaSeccionesMovilizadores.innerHTML = "";

    if (seccion != '-1') {
      let $table = document.createElement('table');
      let $thead = document.createElement('thead');
      let $trThead = document.createElement('tr');
      let $tdNombreThead = document.createElement('td');
      
      let $tbody = document.createElement('tbody');
      
      

      $table.classList.add("min-w-full", "divide-y", "divide-gray-200", "select-none");
      $thead.classList.add("bg-blue-50");
      $tdNombreThead.classList.add("px-6", "py-3", "text-left", "text-xs", "font-medium", "text-gray-500", "uppercase", "tracking-wider");
      $tbody.classList.add("bg-white", "divide-y", "divide-gray-200");
      


      let valorEscenario = sessionStorage.getItem('escenario');
      let token = sessionStorage.getItem("token");
      const resp = await fetch(ApiUrl().url + "padron/dependeEstructura/" + valorEscenario + "/" + seccion, settingsWithoutBody(token, 'GET'));
      const respData = await resp.json();
      
      //console.log(respData.movilizadores);

      //$tdNombre.innerHTML = "Nombre";
      
      
      if (respData.movilizadores) {
        respData.movilizadores.forEach(el => {

          let $tr = document.createElement('tr');
          let $tdNombre = document.createElement('td');
          $tdNombre.classList.add("px-6", "py-4", "whitespace-nowrap");

          let nombre = el;
          $tdNombre.innerHTML = nombre;


          $tr.appendChild($tdNombre);
          $tbody.appendChild($tr);

        });
      }
      

      $tdNombreThead.innerHTML = "Nombre";
      

      $trThead.appendChild($tdNombreThead);
      $thead.appendChild($trThead);

      
      
      $table.appendChild($thead);
      $table.appendChild($tbody);

      $tablaSeccionesMovilizadores.appendChild($table);

      

    }
  }
  

  const hanldeChangeSeccion = (e) => {
    //console.log(e.target.value);
    
    getData(e.target.value);
  }

  return (
    <>
      <div className='w-full'>
        <div className='mt-8 ml-4 text-[#3c70b9] font-bold'>Selecciona una Sección para ver sus Movilizadores</div>
          <div className='' id="select-Secciones">
          <select id="secciones" name="secciones" className="ml-4 mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" onChange={hanldeChangeSeccion}>
          </select>
        </div>

        <div className='mt-6 ml-4 gap-6 flex flex-row'>
          <div id="tabla-secciones-movilizadores">
          </div>
        </div>
      </div>
    </>
  );
}

export default Secciones;