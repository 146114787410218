/**
 * @file BtnCancelar.js
 * @brief Estructura del botón Cancelar.
 * @author Efraín Gourcy
 * @modified Dic 30, 2022
 */

import React from 'react';

const BtnCancelar = ({ text }) => {
  return (
    <>
      <button className="px-6 py-2 text-white font-bold bg-red-600 rounded-lg hover:bg-red-700 ring-2 ring-red-400 focus:outline-none">
        { text }
      </button>
    </>
  );
}

export default BtnCancelar;