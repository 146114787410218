/**
 * @file Home.js
 * @brief Dashboard de la Aplicación.
 * @author Efraín Gourcy
 * @modified Jul 24, 2023
 */

import React, { useEffect, useState } from 'react';
//import { Chart as ChartJS, BarElement, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, Filler } from 'chart.js';
//import { Pie, Line, Bar, Doughnut } from 'react-chartjs-2';
import { ApiUrl, settingsWithoutBody } from '../helpers/ApiUrl';
import { HiOutlineUsers } from "react-icons/hi";
import { FiUserCheck } from "react-icons/fi";
import { CgSearchFound } from "react-icons/cg";
import { ImTree } from "react-icons/im";
import { FaSortAmountUpAlt, FaSortAmountDown } from "react-icons/fa";
//import MapaDashboard from '../components/MapaDashboard';
import DVTR from '../components/DVTR';
//import DVTR2 from '../components/DVTR2';
import { Chart as ChartG } from "react-google-charts";
//import GPIV from '../components/graficas/GPIV';
//import GPV from '../components/graficas/GPV';
import BarrasDVTR from '../components/graficas/BarrasDVTR';
import IPTD from '../components/graficas/IPTD';
//import MovilizadorMasVotos from '../components/graficas/MovilizadorMasVotos';
//import MovilizadorMenosVotos from '../components/graficas/MovilizadorMenosVotos';
import Loader from '../components/Loader';
import MovilizadorVotos from '../components/graficas/MovilizadorVotos';
import TablaMovilizadores from '../components/graficas/TablaMovilizadores';
import Secciones from '../components/graficas/Secciones';

//ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, Filler, BarElement);

const Home = () => {

  const [padronOficial, setPadronOficial] = useState(0);
  const [padronIntencion, setPadronIntencion] = useState(0);
  const [encontrados, setEncontrados] = useState(0);
  const [pertenenceEstructura, setPertenenceEstructura] = useState(0);
  const [movilizadorMasVotos, setMovilizadorMasVotos] = useState(0);
  const [movilizadorIdMasVotos, setMovilizadorIdMasVotos] = useState(0);
  const [movilizadorIdMenosVotos, setMovilizadorIdMenosVotos] = useState(0);
  const [movilizadorMasVotosNumero, setMovilizadorMasVotosNumero] = useState(0);
  //const [totalVotosComprometidosMovilizador, setTotalVotosComprometidosMovilizador] = useState(0);
  //const [totalVotosComprometidosMovilizadorMenos, setTotalVotosComprometidosMovilizadorMenos] = useState(0);
  const [movilizadorMenosVotos, setMovilizadorMenosVotos] = useState(0);
  const [movilizadorMenosVotosNumero, setMovilizadorMenosVotosNumero] = useState(0);
  const [seccionMasVotos, setSeccionMasVotos] = useState(0);
  const [seccionMasVotosNumero, setSeccionMasVotosNumero] = useState(0);
  const [seccionMenosVotos, setSeccionMenosVotos] = useState(0);
  const [seccionMenosVotosNumero, setSeccionMenosVotosNumero] = useState(0);
  const [escenario, setEscenario] = useState(0);
  const [pestanas, setPestanas] = useState(2);
  const [dataGauge1, setDataGauge1] = useState(getData);
  const [dataGauge2, setDataGauge2] = useState(getData2);
  const [movilizadores, setMovilizadores] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [totalGraficaMovilizadores, setTotalGraficaMovilizadores] = useState(0);
  const [votosGraficaMovilizadores, setVotosGraficaMovilizadores] = useState(0);


  
  function getData(value) {
    return [
      ["Label", "Value"],
      ["Intención", value],
    ];
  }

  function getData2(value) {
    return [
      ["Label", "Value"],
      ["Padrón", value],
    ];
  }

  /**************** useEffect [gauges] ******************************************************************************* */
  useEffect(() => {

    
    const id = setInterval(() => {

      let token = sessionStorage.getItem("token");
      fetch(ApiUrl().url + "padron/getVotos/" + escenario, settingsWithoutBody(token, 'GET'))
      .then(data => data.json())
      .then(data => {
        if (data.padron) {
          setDataGauge1(getData(data.padron[1]['total']));
          setDataGauge2(getData2(data.padron[1]['total']));
        }
      });

    }, 15000);

    return () => {
      clearInterval(id);
    };
  });
  


  /**************** useEffect [] ******************************************************************************* */
  useEffect(() => {
    setEscenario(sessionStorage.getItem("escenario"));
    setDataGauge1(getData(0));
    setDataGauge2(getData2(0));
  }, []);


  /**************** useEffect [escenario] ******************************************************************************* */
  useEffect(() => {

    setisLoading(true);

    let token = sessionStorage.getItem("token");

    fetch(ApiUrl().url + "padron/total/" + escenario, settingsWithoutBody(token, 'GET'))
      .then(data => data.json())
      .then(data => {
        if (data.total) {
          setPadronIntencion(data.total);
        }
      });

    fetch(ApiUrl().url + "padronOficial/total/" + escenario, settingsWithoutBody(token, 'GET'))
      .then(data => data.json())
      .then(data => {
        if (data.total) {
          setPadronOficial(data.total);
        }
      });

    fetch(ApiUrl().url + "padron/econtrados/" + escenario, settingsWithoutBody(token, 'GET'))
      .then(data => data.json())
      .then(data => {
        if (data.total) {
          setEncontrados(data.total);
        }
      });

    fetch(ApiUrl().url + "padron/perteneceEstuctura/" + escenario, settingsWithoutBody(token, 'GET'))
      .then(data => data.json())
      .then(data => {
        if (data.total) {
          setPertenenceEstructura(data.total);
        }
      });

    fetch(ApiUrl().url + "padron/movilizadorMasVotos/" + escenario, settingsWithoutBody(token, 'GET'))
      .then(data => data.json())
      .then(data => {
        if (data.nombre) {
          setMovilizadorMasVotos(data.nombre);
          setMovilizadorMasVotosNumero(data.total);
          setMovilizadorIdMasVotos(data.id);
        }
      });

    fetch(ApiUrl().url + "padron/movilizadorMenosVotos/" + escenario, settingsWithoutBody(token, 'GET'))
      .then(data => data.json())
      .then(data => {
        if (data.nombre) {
          setMovilizadorMenosVotos(data.nombre);
          setMovilizadorMenosVotosNumero(data.total);
          setMovilizadorIdMenosVotos(data.id);
        }
      });

    fetch(ApiUrl().url + "padron/seccionMasVotos/" + escenario, settingsWithoutBody(token, 'GET'))
      .then(data => data.json())
      .then(data => {
        if (data.status === 'success') {
          if (data.seccion != '' && data.total > 0) {
            setSeccionMasVotos(data.seccion);
            setSeccionMasVotosNumero(data.total);
          }
        }
      });

    fetch(ApiUrl().url + "padron/seccionMenosVotos/" + escenario, settingsWithoutBody(token, 'GET'))
      .then(data => data.json())
      .then(data => {
        if (data.status === 'success') {
          if (data.seccion != '' && data.total > 0) {
            setSeccionMenosVotos(data.seccion);
            setSeccionMenosVotosNumero(data.total);
          }
        }
      });

    /* Movilizadores */
    fetch(ApiUrl().url + "padron/getMovilizadores/" + escenario, settingsWithoutBody(token, 'GET'))
      .then(data => data.json())
      .then(data => {
        if (data.status === 'success') {
          if (data.movilizadores.length > 0) {
            //console.log(data.movilizadores);
            setMovilizadores(data.movilizadores);
          }
            //console.log(data.movilizadores);
        } else
          setisLoading(false);
      });

  }, [escenario]);


  /**************** useEffect [pestanas] ******************************************************************************* */
  useEffect(() => {
    let $pestana1 = document.getElementById('pestana-1');
    $pestana1.classList.add('hidden');
    let $pestana2 = document.getElementById('pestana-2');
    $pestana2.classList.add('hidden');
    let $pestana3 = document.getElementById('pestana-3');
    $pestana3.classList.add('hidden');
    let $pestana4 = document.getElementById('pestana-4');
    $pestana4.classList.add('hidden');

    let $menuPestana1 = document.getElementById('menu-pestana-1');
    let $menuPestana2 = document.getElementById('menu-pestana-2');
    let $menuPestana3 = document.getElementById('menu-pestana-3');
    let $menuPestana4 = document.getElementById('menu-pestana-4');

    $menuPestana1.classList.remove('bg-[#3c70b9]');
    $menuPestana1.classList.remove('text-white');
    $menuPestana2.classList.remove('bg-[#3c70b9]');
    $menuPestana2.classList.remove('text-white');
    $menuPestana3.classList.remove('bg-[#3c70b9]');
    $menuPestana3.classList.remove('text-white');
    $menuPestana4.classList.remove('bg-[#3c70b9]');
    $menuPestana4.classList.remove('text-white');

    $menuPestana1.classList.add('hover:text-white');
    $menuPestana1.classList.add('hover:bg-[#3c70b9]');
    $menuPestana2.classList.add('hover:text-white');
    $menuPestana2.classList.add('hover:bg-[#3c70b9]');
    $menuPestana3.classList.add('hover:text-white');
    $menuPestana3.classList.add('hover:bg-[#3c70b9]');
    $menuPestana4.classList.add('hover:text-white');
    $menuPestana4.classList.add('hover:bg-[#3c70b9]');


    if (pestanas === 1) {
      $pestana1.classList.remove('hidden');
      $menuPestana1.classList.add('bg-[#3c70b9]');
      $menuPestana1.classList.add('text-white');
      $menuPestana1.classList.remove('hover:text-white');
      $menuPestana1.classList.remove('hover:bg-[#3c70b9]');
    }

    if (pestanas === 2) {
      $pestana2.classList.remove('hidden');
      $menuPestana2.classList.add('bg-[#3c70b9]');
      $menuPestana2.classList.add('text-white');
      $menuPestana2.classList.remove('hover:text-white');
      $menuPestana2.classList.remove('hover:bg-[#3c70b9]');
    }

    if (pestanas === 3) {
      $pestana3.classList.remove('hidden');
      $menuPestana3.classList.add('bg-[#3c70b9]');
      $menuPestana3.classList.add('text-white');
      $menuPestana3.classList.remove('hover:text-white');
      $menuPestana3.classList.remove('hover:bg-[#3c70b9]');
    }

    if (pestanas === 4) {
      $pestana4.classList.remove('hidden');
      $menuPestana4.classList.add('bg-[#3c70b9]');
      $menuPestana4.classList.add('text-white');
      $menuPestana4.classList.remove('hover:text-white');
      $menuPestana4.classList.remove('hover:bg-[#3c70b9]');
    }


  }, [pestanas]);


  /**************** useEffect [movilizadorIdMasVotos] ******************************************************************************* */
  /*
  useEffect(() => {

    let token = sessionStorage.getItem("token");

    fetch(ApiUrl().url + "padron/totalVotosComprometidosMovilizador/" + escenario + "/" + movilizadorIdMasVotos, settingsWithoutBody(token, 'GET'))
      .then(data => data.json())
      .then(data => {
        if (data.total) {
          setTotalVotosComprometidosMovilizador(data.total);
        }
      });
    
  }, [movilizadorIdMasVotos]);
  */

  /**************** useEffect [movilizadorIdMenosVotos] ******************************************************************************* */
  /*
  useEffect(() => {

    let token = sessionStorage.getItem("token");

    fetch(ApiUrl().url + "padron/totalVotosComprometidosMovilizador/" + escenario + "/" + movilizadorIdMenosVotos, settingsWithoutBody(token, 'GET'))
      .then(data => data.json())
      .then(data => {
        if (data.total) {
          setTotalVotosComprometidosMovilizadorMenos(data.total);
        }
      });
    
  }, [movilizadorIdMenosVotos]);
  */


  /**************** useEffect [movilizadores] ******************************************************************************* */
  useEffect(() => {

    if (movilizadores) {

      //console.log(movilizadores);

      let $selectMovilizadores = document.getElementById('movilizadores');
      $selectMovilizadores.innerHTML = '';

      let $option = document.createElement('option');
      $option.value = '-1';
      $option.innerText = 'Selecciona una opción';
      $selectMovilizadores.appendChild($option);

      
      movilizadores.forEach(el => {
        let nombre = el[0];
        //console.log(nombre);
        let $option = document.createElement('option');
        $option.value = nombre;
        $option.innerText = nombre;
        $selectMovilizadores.appendChild($option);
      });
      
    }

    setisLoading(false);
    
  }, [movilizadores]);


  /**************** hanldeChangeMovilizador ******************************************************************************* */
  const hanldeChangeMovilizador = (e) => {
    //console.log(e.target.value);

    let $divTablaMovilizadores = document.getElementById('tabla-avance-movilizadores');
    $divTablaMovilizadores.innerHTML = '';

    let $divGraficaMovilizadores = document.getElementById('grafica-avance-movilizadores');
    $divGraficaMovilizadores.classList.add('hidden');

    if (e.target.value != '-1') {
      let $table = document.createElement('table');
      let $thead = document.createElement('thead');
      let $trThead = document.createElement('tr');
      let $tdNombreThead = document.createElement('td');
      let $tdTotalThead = document.createElement('td');
      let $tdVotosThead = document.createElement('td');
      let $tbody = document.createElement('tbody');
      let $tr = document.createElement('tr');
      let $tdNombre = document.createElement('td');
      let $tdTotal = document.createElement('td');
      let $tdVotos = document.createElement('td');

      $table.classList.add("min-w-full", "divide-y", "divide-gray-200", "select-none");
      $thead.classList.add("bg-blue-50");
      $tdNombreThead.classList.add("px-6", "py-3", "text-left", "text-xs", "font-medium", "text-gray-500", "uppercase", "tracking-wider");
      $tdTotalThead.classList.add("px-6", "py-3", "text-left", "text-xs", "font-medium", "text-gray-500", "uppercase", "tracking-wider");
      $tdVotosThead.classList.add("px-6", "py-3", "text-left", "text-xs", "font-medium", "text-gray-500", "uppercase", "tracking-wider");
      $tbody.classList.add("bg-white", "divide-y", "divide-gray-200");
      $tdNombre.classList.add("px-6", "py-4", "whitespace-nowrap");
      $tdTotal.classList.add("px-6", "py-4", "whitespace-nowrap");
      $tdVotos.classList.add("px-6", "py-4", "whitespace-nowrap");

      $tdNombre.innerHTML = "Nombre";
      $tdTotal.innerHTML = "0";
      $tdVotos.innerHTML = "0";

      if (movilizadores) {
        movilizadores.forEach(el => {
          let nombre = el[0];
          let total = el[1];
          let votos = el[2];
          if (nombre == e.target.value) {
            setTotalGraficaMovilizadores(total);
            setVotosGraficaMovilizadores(votos);
            $tdNombre.innerHTML = nombre;
            $tdTotal.innerHTML = total;
            $tdVotos.innerHTML = votos;
          }
        });
      }

      $tdNombreThead.innerHTML = "Nombre";
      $tdTotalThead.innerHTML = "Total Votos Comprometidos";
      $tdVotosThead.innerHTML = "No. de Votos";

      $trThead.appendChild($tdNombreThead);
      $trThead.appendChild($tdTotalThead);
      $trThead.appendChild($tdVotosThead);
      $thead.appendChild($trThead);

      $tr.appendChild($tdNombre);
      $tr.appendChild($tdTotal);
      $tr.appendChild($tdVotos);

      $tbody.appendChild($tr);
      $table.appendChild($thead);
      $table.appendChild($tbody);

      $divTablaMovilizadores.appendChild($table);

      $divGraficaMovilizadores.classList.remove('hidden');

    }

  }
  


  /**************** handlePestana ******************************************************************************* */
  const handlePestana = (e) => {
    if (e.target.id === 'menu-pestana-1')
      setPestanas(1);

    if (e.target.id === 'menu-pestana-2')
      setPestanas(2);

    if (e.target.id === 'menu-pestana-3')
      setPestanas(3);

    if (e.target.id === 'menu-pestana-4')
      setPestanas(4);
  };




  /** Valores gráfica de barras */
  /*
  const dataBarras = {
    labels: ['Padron Oficial', 'intención', 'Estructura', 'Programas'],
    datasets: [
      {
        label: 'Padrones',
        data: [padronOficial, padronIntencion, pertenenceEstructura, 5],
        backgroundColor: [
          'rgba(153, 102, 255, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(153, 102, 255, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      }
    ],
  };
  */

  /** Valores gráfica de dona */
  /*
  const dataDona = {
    labels: ['Padron Oficial', 'intención'],
    datasets: [
      {
        label: '',
        data: [padronOficial, padronIntencion],
        backgroundColor: [
          'rgba(153, 102, 255, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
          'rgba(153, 102, 255, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      }
    ],
  };
  */

  /** Valores gráfica de barras */
  /*
  const optionsBarras = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Estructura / Beneficiarios de Programas',
      },
    },
  };
  */

  /** Valores gráfica de dona */
  /*
  const optionsDona = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Intención del Voto',
      },
    },
  };
  */


  /** Valores gráfica circular */
  /*
  const dataPie = {
    labels: ['Padron', 'Intención', 'Estructura', 'Programas'],
    datasets: [
      {
        label: 'Padron/Intención/Estructura/Programas',
        data: [padronOficial, padronIntencion, pertenenceEstructura, 5],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  */

  /** Valores gráfica de lineas */
  /*
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Avance de Captura',
      },
    },
  };
  */

  /*
  const labels = ['Eenero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'];

  const data2 = {
    labels: labels,
    datasets: [
      {
        label: 'Avance por fecha',
        data: [100, 33, 22, 19, 11, 49, 30],
        borderColor: 'rgba(54, 162, 235, 0.2)',
        backgroundColor: 'rgba(54, 162, 235, 1)',
      }
    ]
  };
  */

  /*
  const handleMovilizadorMasVotos = () => {
    //alert(movilizadorIdMasVotos);
    let $divGraficaMovilizador = document.getElementById('grafica-movilizador');
    let $divGraficaMovilizadorMenos = document.getElementById('grafica-movilizador-menos');
    let $divGraficaSeccion = document.getElementById('grafica-seccion');

    $divGraficaMovilizadorMenos.classList.add('hidden');
    $divGraficaSeccion.classList.add('hidden');

    if ($divGraficaMovilizador.className.indexOf('hidden') !== -1) {
      $divGraficaMovilizador.classList.remove('hidden');
    } else {
      $divGraficaMovilizador.classList.add('hidden');
    }
  }

  const handleMovilizadorMenosVotos = () => {
    
    let $divGraficaMovilizador = document.getElementById('grafica-movilizador-menos');
    let $divGraficaMovilizadorMas = document.getElementById('grafica-movilizador');
    let $divGraficaSeccion = document.getElementById('grafica-seccion');

    $divGraficaMovilizadorMas.classList.add('hidden');
    $divGraficaSeccion.classList.add('hidden');

    if ($divGraficaMovilizador.className.indexOf('hidden') !== -1) {
      $divGraficaMovilizador.classList.remove('hidden');
    } else {
      $divGraficaMovilizador.classList.add('hidden');
    }
  }
  */

  /*
  const handleSeccionMasVotos = () => {
    
    let $divGraficaMovilizador = document.getElementById('grafica-movilizador');
    let $divGraficaMovilizadorMenos = document.getElementById('grafica-movilizador-menos');
    let $divGraficaSeccion = document.getElementById('grafica-seccion');

    $divGraficaMovilizador.classList.add('hidden');
    $divGraficaMovilizadorMenos.classList.add('hidden');

    if ($divGraficaSeccion.className.indexOf('hidden') !== -1) {
      $divGraficaSeccion.classList.remove('hidden');
    } else {
      $divGraficaSeccion.classList.add('hidden');
    }
  }
  */


  const handleChangeMap = (e) => {
    e.preventDefault();
    let $mapa = document.getElementById('select-mapa').value;
    if ($mapa == 1) {
      let $mapa1 = document.getElementById('mapa-1');
      let $mapa2 = document.getElementById('mapa-2');
      $mapa1.classList.remove('hidden');
      $mapa2.classList.add('hidden');
    }
    if ($mapa == 2) {
      let $mapa1 = document.getElementById('mapa-1');
      let $mapa2 = document.getElementById('mapa-2');
      $mapa1.classList.add('hidden');
      $mapa2.classList.remove('hidden');
    }
  }


  /**************** return ******************************************************************************* */
  return (
    <>
      <ul className="mt-10 ml-8 flex flex-wrap text-sm font-medium text-center text-gray-500 border-b-2 border-[#3c70b9]">
        <li
          id="menu-pestana-1"
          onClick={handlePestana}
          className="mr-2 cursor-pointer w-1/5 hover:text-white hover:bg-[#3c70b9] rounded-t-lg"
        >
          <a
            id="menu-pestana-1"
            onClick={handlePestana}
            className="inline-block p-4 text-xl"
          >
            Análisis, Metas y Estadísticas
          </a>
        </li>
        <li
          id="menu-pestana-2"
          onClick={handlePestana}
          className="mr-2 cursor-pointer w-1/5 hover:text-white hover:bg-[#3c70b9] rounded-t-lg"
        >
          <a
            id="menu-pestana-2"
            onClick={handlePestana}
            className="inline-block p-4 text-xl"
          >
            Big Data Interpretación Inmediata
          </a>
        </li>
        <li
          id="menu-pestana-3"
          onClick={handlePestana}
          className="mr-2 cursor-pointer w-1/5 hover:text-white hover:bg-[#3c70b9] rounded-t-lg"
        >
          <a
            id="menu-pestana-3"
            onClick={handlePestana}
            className="inline-block p-4 text-xl"
          >
            Detección del Voto en Tiempo Real
          </a>
        </li>
        <li
          id="menu-pestana-4"
          onClick={handlePestana}
          className="mr-2 cursor-pointer w-1/5 hover:text-white hover:bg-[#3c70b9] rounded-t-lg"
        >
          <a
            id="menu-pestana-4"
            onClick={handlePestana}
            className="inline-block p-4 text-xl"
          >
            Información para la Toma de Desición
          </a>
        </li>
      </ul>

      <div id="pestana-1">
        {/*
        <MapaDashboard />s
        */}

        <div>
          <select id="select-mapa" name="select-mapa" onChange={handleChangeMap} className="ml-4 mt-6 mb-3 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
            <option value="1">Ubicaciones electorales</option>
            <option value="2">Históricos electorales</option>
          </select>
        </div>

        <div id='mapa-1' style={{width: "100%"}}>
          {/*
          <iframe src="https://www.google.com/maps/d/embed?mid=11kegpwRbcY0v_HIDUECzuhCy63REf5Y&ehbc=2E312F" width="100%" height="800"></iframe>
          */}
          <iframe src="https://app.powerbi.com/view?r=eyJrIjoiMTU2YzUxOWUtYzVjYy00YmZkLTg2MzctYmQwNWFiYWYyYTQ4IiwidCI6ImY1NjVjNmU3LTk4MzYtNDlkZC05OWEyLTliOWY1YjdmYWFlNiJ9" width="100%" height="800"></iframe>
        </div>

        <div id='mapa-2' className='hidden' style={{width: "100%"}}>
          <iframe src="https://app.powerbi.com/view?r=eyJrIjoiNmExNjRiMTgtMWRlZi00Y2NjLThlYmEtZWNmYjAxZGYxMDg4IiwidCI6ImY1NjVjNmU3LTk4MzYtNDlkZC05OWEyLTliOWY1YjdmYWFlNiJ9" width="100%" height="800"></iframe>
        </div>

      </div>

      <div id="pestana-2" className="hidden">
        <div className="mt-5 md:mt10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 p-4 gap-4">
          <div className="bg-[#3c70b9] shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-[#81a3d1] text-white font-medium group">
            <div className="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
              <HiOutlineUsers className="text-[#3c70b9] text-3xl" />
            </div>
            <div className="text-right">
              <p className="text-2xl">{padronOficial}</p>
              <p>Padron Oficial</p>
            </div>
          </div>

          <div className="bg-[#3c70b9] shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-[#81a3d1] text-white font-medium group">
            <div className="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
              <FiUserCheck className="text-[#3c70b9] text-3xl" />
            </div>
            <div className="text-right">
              <p className="text-2xl">{padronIntencion}</p>
              <p>Intención del Voto</p>
            </div>
          </div>
          <div className="bg-[#3c70b9] shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-[#81a3d1] text-white font-medium group">
            <div className="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
              <CgSearchFound className="text-[#3c70b9] text-3xl" />
            </div>
            <div className="text-right">
              <p className="text-2xl">{encontrados}</p>
              <p>Econtrados</p>
            </div>
          </div>
          <div className="bg-[#3c70b9] shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-[#81a3d1] text-white font-medium group">
            <div className="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
              <ImTree className="text-[#3c70b9] text-2xl" />
            </div>
            <div className="text-right">
              <p className="text-2xl">{pertenenceEstructura}</p>
              <p>Pertence a Estructura</p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 p-4 gap-4">

          <div className="bg-[#3c70b9] shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-[#81a3d1] text-white font-medium group">
            <div className="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
              <FaSortAmountUpAlt className="text-[#3c70b9] text-3xl" />
            </div>
            <div className="text-right">
              <p className="text-2xl">
                {movilizadorMasVotos} - {movilizadorMasVotosNumero}
              </p>
              <p>Movilizador con más Votos</p>
            </div>
          </div>

          <div className="bg-[#3c70b9] shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-[#81a3d1] text-white font-medium group">
            <div className="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
              <FaSortAmountDown className="text-[#3c70b9] text-3xl" />
            </div>
            <div className="text-right">
              <p className="text-2xl">
                {movilizadorMenosVotos} - {movilizadorMenosVotosNumero}
              </p>
              <p>Movilizador con menos Votos</p>
            </div>
          </div>
          <div className="bg-[#3c70b9] shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-[#81a3d1] text-white font-medium group">
            <div className="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
              <FaSortAmountUpAlt className="text-[#3c70b9] text-3xl" />
            </div>
            <div className="text-right">
              <p className="text-2xl">
                {seccionMasVotos} - {seccionMasVotosNumero}
              </p>
              <p>Sección con más Votos</p>
            </div>
          </div>
          <div className="bg-[#3c70b9] shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-[#81a3d1] text-white font-medium group">
            <div className="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
              <FaSortAmountDown className="text-[#3c70b9] text-3xl" />
            </div>
            <div className="text-right">
              <p className="text-2xl">
                {seccionMenosVotos} - {seccionMenosVotosNumero}
              </p>
              <p>Sección con Menos Votos</p>
            </div>
          </div>
        </div>

        {/*}
        <div className="grid grid-cols-1 md:grid-cols-2 p-12 gap-12 justify-items-center">
          <div className="md:col-span-2 text-center ml-24 mr-24">
            {padronOficial !== 0 &&
            padronIntencion !== 0 &&
            pertenenceEstructura !== 0 ? (
              <Bar
                options={optionsBarras}
                data={dataBarras}
                className="h-[350px]"
              />
            ) : (
              <label>Cargando...</label>
            )}
          </div>

          <div className="h-80 mt-20">
            {padronOficial !== 0 && padronIntencion !== 0 ? (
              <Doughnut data={dataDona} options={optionsDona} />
            ) : (
              <label>Cargando...</label>
            )}
          </div>

          <div className="h-80 mt-20">
            {padronOficial !== 0 &&
            padronIntencion !== 0 &&
            pertenenceEstructura !== 0 ? (
              <Pie data={dataPie} />
            ) : (
              <label>Cargando...</label>
            )}
          </div>
          */}

          {/*
          <div className="h-80 mt-20 w-full">
            <Line options={options} data={data2} />
          </div>
          */}

          {/*}
          <div className="md:col-span-2 text-center ml-24 mr-24">
           
            <Line options={options} data={data2} className="h-[350px]" />
          </div>
        </div>
        */}

        {isLoading && <div className='ml-12 mt-2 p-1'><Loader /></div>}

        { movilizadores &&
          <>
            <div className='w-full'>
              <div className='mt-8 ml-4 text-[#3c70b9] font-bold'>Selecciona un Movilizador para ver su Avance de Votación</div>
              <div className='' id="select-movilizadores">
                <select id="movilizadores" name="movilizadores" className="ml-4 mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" onChange={hanldeChangeMovilizador}>
                </select>
              </div>

              <div className='mt-6 ml-4 gap-6 flex flex-row'>
                <div className='hidden' id="grafica-avance-movilizadores">
                  <MovilizadorVotos votos={votosGraficaMovilizadores} total={totalGraficaMovilizadores} />
                </div>
                <div id="tabla-avance-movilizadores">
                </div>
              </div>
            </div>
          </>
        }

          <div classList="w-3/5">
            <TablaMovilizadores />
          </div>
        
        {/*}
        <div className='hidden' id="grafica-movilizador">
          <MovilizadorMasVotos movilizadorMasVotosNumero={movilizadorMasVotosNumero} totalVotosComprometidosMovilizador={totalVotosComprometidosMovilizador} />
        </div>

        <div className='hidden' id="grafica-movilizador-menos">
          <MovilizadorMenosVotos movilizadorMenosVotosNumero={movilizadorMenosVotosNumero} totalVotosComprometidosMovilizadorMenos={totalVotosComprometidosMovilizadorMenos} />
        </div>

        <div className='hidden' id="grafica-seccion">
          <MovilizadorMenosVotos movilizadorMenosVotosNumero={movilizadorMenosVotosNumero} totalVotosComprometidosMovilizadorMenos={totalVotosComprometidosMovilizadorMenos} />
        </div>
        */}

      </div>


      <div id='pestana-3' className='hidden'>

        <BarrasDVTR />

        <Secciones />
        

        {/*
        <div className='flex md:flex-row flex-col'>
          <ChartG
            chartType="Gauge"
            width="100%"
            height="400px"
            data={dataGauge1}
            options={{
              width: 400,
              height: 400,
              max: padronIntencion,
              redFrom: 0,
              redTo: padronIntencion - (padronIntencion * 0.3),
              greenFrom: padronIntencion - (padronIntencion * 0.15),
              greenTo: padronIntencion,
              yellowFrom: padronIntencion - (padronIntencion * 0.3),
              yellowTo: padronIntencion - (padronIntencion * 0.15),
              minorTicks: 5,
            }}
          />

          <ChartG
            chartType="Gauge"
            width="100%"
            height="400px"
            data={dataGauge2}
            options={{
              width: 400,
              height: 400,
              max: padronOficial,
              redFrom: 0,
              redTo: padronOficial - (padronOficial * 0.3),
              greenFrom: padronOficial - (padronOficial * 0.15),
              greenTo: padronOficial,
              yellowFrom: padronOficial - (padronOficial * 0.3),
              yellowTo: padronOficial - (padronOficial * 0.15),
              minorTicks: 5,
            }}
          />

        </div>
        */}

        {/*
        <div className='flex md:flex-row flex-col'>
            <GPIV />
            <GPV />
        </div>
      */}

      </div>

      <div id='pestana-4' className='hidden'>
        
        {/*
        <DVTR />
        */}

        <div className='w-full mt-8 flex justify-center items-center flex-col'>
          <div className='flex md:flex-row flex-col'>

            <ChartG
              chartType="Gauge"
              width="100%"
              height="400px"
              data={dataGauge2}
              options={{
                width: 400,
                height: 400,
                max: padronOficial,
                redFrom: 0,
                redTo: padronOficial - (padronOficial * 0.3),
                greenFrom: padronOficial - (padronOficial * 0.15),
                greenTo: padronOficial,
                yellowFrom: padronOficial - (padronOficial * 0.3),
                yellowTo: padronOficial - (padronOficial * 0.15),
                minorTicks: 5,
              }}
            />

            <ChartG
              chartType="Gauge"
              width="100%"
              height="400px"
              data={dataGauge1}
              options={{
                width: 400,
                height: 400,
                max: padronIntencion,
                redFrom: 0,
                redTo: padronIntencion - (padronIntencion * 0.3),
                greenFrom: padronIntencion - (padronIntencion * 0.15),
                greenTo: padronIntencion,
                yellowFrom: padronIntencion - (padronIntencion * 0.3),
                yellowTo: padronIntencion - (padronIntencion * 0.15),
                minorTicks: 5,
              }}
            />

          </div>

            <IPTD />

          {/*
          <DVTR />
          */}

          </div>

      </div>
    </>
  );
}

export default Home;
