import React, { useEffect } from 'react';
import { ApiUrl, settingsWithoutBody } from '../../helpers/ApiUrl';

const TablaMovilizadores = () => {


  useEffect(() => {

    const getData = async() => {
     
      let escenario = sessionStorage.getItem("escenario");
      let token = sessionStorage.getItem("token");
      const response = await fetch(ApiUrl().url + "padron/votosComprometidosMovilizador/" + escenario, settingsWithoutBody(token, 'GET'));
      const respmovilizadores = await response.json();

      if (respmovilizadores) {
        //console.log(respmovilizadores.padron);

        const responseTotal = await fetch(ApiUrl().url + "padron/votosMovilizador/" + escenario, settingsWithoutBody(token, 'GET'));
        const respmovilizadoresVotos = await responseTotal.json();

        if (respmovilizadoresVotos) {
          //console.log(respmovilizadoresVotos.padron);

          let $datosTabla = document.getElementById("datos-tabla");
          $datosTabla.innerHTML = "";

          /* Cero votos  */
          respmovilizadores.padron.forEach(el => {
            
            let encontrado = false;
            respmovilizadoresVotos.padron.forEach(el2 => {

              if (el.id == el2.id)
                encontrado = true;

            });

            if (!encontrado) {
              //console.log(el.id);
              
              let $tr = document.createElement("tr");
              let $td1  = document.createElement("td");
              let $td2  = document.createElement("td");
              let $td3  = document.createElement("td");

              $td1.classList.add("px-6", "py-3", "text-left", "text-xs", "font-medium", "text-gray-500", "uppercase", "tracking-wider");
              $td2.classList.add("px-6", "py-3", "text-right", "text-xs", "font-medium", "text-gray-500", "uppercase", "tracking-wider");
              $td3.classList.add("px-6", "py-3", "text-right", "text-xs", "font-medium", "text-gray-500", "uppercase", "tracking-wider");

              $td1.innerHTML = el.nombre + " " + el.paterno + " " + el.materno;
              $td2.innerHTML = el.total;
              $td3.innerHTML = "0";

              $tr.appendChild($td1);
              $tr.appendChild($td2);
              $tr.appendChild($td3);
              $datosTabla.appendChild($tr);

            }
          });

          /* Movilizadores con votos  */

          respmovilizadoresVotos.padron.forEach(el2 => {

            respmovilizadores.padron.forEach(el => {

              if (el.id == el2.id) {

                let $tr = document.createElement("tr");
                let $td1  = document.createElement("td");
                let $td2  = document.createElement("td");
                let $td3  = document.createElement("td");

                $td1.classList.add("px-6", "py-3", "text-left", "text-xs", "font-medium", "text-gray-500", "uppercase", "tracking-wider");
                $td2.classList.add("px-6", "py-3", "text-right", "text-xs", "font-medium", "text-gray-500", "uppercase", "tracking-wider");
                $td3.classList.add("px-6", "py-3", "text-right", "text-xs", "font-medium", "text-gray-500", "uppercase", "tracking-wider");

                $td1.innerHTML = el.nombre + " " + el.paterno + " " + el.materno;
                $td2.innerHTML = el.total;
                $td3.innerHTML = el2.total;

                $tr.appendChild($td1);
                $tr.appendChild($td2);
                $tr.appendChild($td3);
                $datosTabla.appendChild($tr);
              }


            });

          });

        }

      }
    };

    getData();
    
  }, []);
  


  return (
    <>
      <div className="flex flex-col mt-4">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 select-none">
                <thead className="bg-blue-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Nombre
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                      Votos Comprometidos
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                      Total Votos
                    </th>
                  </tr>
                </thead>
                <tbody id="datos-tabla" className="bg-white divide-y divide-gray-200">
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TablaMovilizadores;