/**
 * @file Estructura.js
 * @brief Administración de la Estructuras.
 * @author Efraín Gourcy
 * @modified Feb 13, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { contextUserPermissions } from '../../App';
import BtnAceptar from '../../components/BtnAceptar';
import { ApiUrl, settingsWithoutBody } from '../../helpers/ApiUrl';
import Grid from './Grid';

const Estructura = () => {

  /** Variables */
  const { userPermissions } = useContext(contextUserPermissions);
  const [estructura, setEstructura] = useState(null);
  const [gridEstructura, setGridEstructura] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [permissionEdit, setPermissionEdit] = useState(false);
  const [permissionDelete, setPermissionDelete] = useState(false);
  let url = ApiUrl().url + "estructura/get";
  const navigate = useNavigate();

  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Verifica los permisos del usuario */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "estructura") {

          if (el.permiso === "ver")
            permission = true;

          if (el.permiso === "nuevo") {
            let $btnNuevoEstructura = document.getElementById("btn-nuevo-estructura");
            $btnNuevoEstructura.classList.remove("invisible");
          }

          if (el.permiso === "editar")
            setPermissionEdit(true);

          if (el.permiso === "eliminar")
            setPermissionDelete(true);

        }
      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);

  /**************** useEffect (url) ******************************************************************************* */

  useEffect(() => {
    /** Carga el grid de estructura */
    setLoading(true);

    let valueToken = sessionStorage.getItem("token");

    let url2 = url;
      if (sessionStorage.getItem("grupo") !== '3881')
        url2 = ApiUrl().url + "estructura/get/"+sessionStorage.getItem("escenario");

    fetch(url2, settingsWithoutBody(valueToken, 'GET'))
      .then(res => res.json())
      .then(data => {
        setEstructura(data.estructura);
        setGridEstructura(data.estructura.slice(0, 5));
        setLoading(false);
      });

  }, [url]);


  /**************** handleSearch ******************************************************************************* */
  const handleSearch = (e) => {
    /** Buscar un elemento dentro del grid de estructura */
    let search = e.target.value;
    let usersArray = [];

    if (search.length > 0) {

      let serachByEstructura = false;
      if (isNaN(search))
        serachByEstructura = true;

      usersArray = estructura.filter(el => {

        if (serachByEstructura) {
          let estructura = el.estructura.toLowerCase();
          return estructura.includes(search.toLowerCase());
        }
      });

      setGridEstructura(usersArray);
      setIsSearch(true);

    } else {
      setGridEstructura(estructura.slice(0, 5));
      setIsSearch(false);
    }
  };


  /**************** cargaGrid ******************************************************************************* */
  const cargaGrid = () => {
    /** Carga el grid de estructura */
    setEstructura(null);
    setGridEstructura(null);
    setLoading(true);

    let inputSearch = document.getElementById("search");
    inputSearch.value = "";
    setIsSearch(false);

    let valueToken = sessionStorage.getItem("token");

    fetch(url, settingsWithoutBody(valueToken, 'GET'))
      .then(res => res.json())
      .then(data => {
        setEstructura(data.estructura);
        setGridEstructura(data.estructura.slice(0, 5));
        setLoading(false);
      });
  };


  /**************** return ******************************************************************************* */
  return (
    <>

      <div className="md:py-12 md:px-12 sm:py-3 sm:px-3 py-3 px-3">

        <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-left border-b-2 border-[#3c70b9]">Administrar Estructuras</h1>

        <div className="p-4 flex bg-gray-50 rounded-lg shadow-xl">
          <div className="flex-none md:flex-grow-0">

            <NavLink id="btn-nuevo-estructura" to="/estructura/form/0" className="invisible">
              <BtnAceptar text={"Agregar Elemento"} />
            </NavLink>

          </div>

          <div className="pl-3 md:pl-96 flex-grow">
            <input id="search" type="text" onChange={handleSearch} className="p-2 w-full rounded border-[#3c70b9] shadow-lg focus:ring-blue-500 focus:outline-none focus:ring" placeholder="Buscar..." />
          </div>
        </div>

        <Grid estructura={estructura} loading={loading} gridEstructura={gridEstructura} setGridEstructura={setGridEstructura} isSearch={isSearch} permissionEdit={permissionEdit} permissionDelete={permissionDelete} cargaGrid={cargaGrid} />

      </div>
    </>
  );
}

export default Estructura;