/**
 * @file Login.js
 * @brief Login de la aplicación.
 * @author Efraín Gourcy
 * @modified Feb 18, 2023
 */

import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import BtnAceptar from '../components/BtnAceptar';
import Loader from '../components/Loader';
import { ApiUrl, settingsWithBody } from "../helpers/ApiUrl";
import logo from "../images/logo.png";

/** Valores iniciales del formulario */
const initialValues = {
  username: "",
  password: ""
};

const Login = ({ isLogged, setIsLogged }) => {

  /** Variables */
  const [form, setForm] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  /******************************************************** useEffect [isLogged] ********************************************************/
  useEffect(() => {
    /** Si esta logeado lo redirecciona al dashboard */
    if (isLogged)
      return navigate("/");

  }, [isLogged]);


  /**************** hanldeChange ******************************************************************************* */
  const hanldeChange = (e) => {
    /** ACtualización de los valores del formulario */
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };


  /**************** attemptLogin ******************************************************************************* */
  const attemptLogin = async () => {
    /** Logeo del usuario */
    try {
      const response = await fetch(ApiUrl().url + "login", settingsWithBody("", form, 'POST'));
      const respLogin = await response.json();

      if (respLogin.status === "success") {
        sessionStorage.setItem("token", respLogin.authorisation.token);
        sessionStorage.setItem("token_time", new Date());
        sessionStorage.setItem("name", respLogin.user.name);
        sessionStorage.setItem("escenario", respLogin.user.escenario);
        sessionStorage.setItem("username", respLogin.user.username);
        sessionStorage.setItem("profile", respLogin.user.perfil);
        sessionStorage.setItem("grupo", respLogin.user.grupo);

        setIsLogged(true);
      } else {
        let $errorUserNotValid = document.getElementById("error-user-not-valid");
        $errorUserNotValid.classList.remove("invisible");
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }

  };


  /**************** hanldeSubmit ******************************************************************************* */
  const hanldeSubmit = (e) => {
    /** Clic al botón de ingresar */
    e.preventDefault();
    setIsLoading(true);

    let $username = document.getElementById("username");
    let $password = document.getElementById("password");

    let $errorusername = document.getElementById("error-username");
    let $errorPassword = document.getElementById("error-password");
    let $errorUserNotValid = document.getElementById("error-user-not-valid");

    $errorusername.classList.add("invisible");
    $errorPassword.classList.add("invisible");
    $errorUserNotValid.classList.add("invisible");

    $username.classList.remove("ring-1");
    $username.classList.remove("ring-red-500");
    $password.classList.remove("ring-1");
    $password.classList.remove("ring-red-500");

    let error = false;

    if ($username.value === '') {
      $errorusername.classList.remove("invisible");
      $username.classList.add("ring-1");
      $username.classList.add("ring-red-500");
      error = true;
    }

    if ($password.value === '') {
      $errorPassword.classList.remove("invisible");
      $password.classList.add("ring-1");
      $password.classList.add("ring-red-500");
      error = true;
    }


    if (!error)
      attemptLogin();
    else
      setIsLoading(false);

  };


  /**************** return ******************************************************************************* */
  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-gray-100 bg-fondoLogin">
        <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg rounded-xl">

          <div className="flex justify-center">
            <img src={logo} alt="logo" className="w-44 ml-10 mb-4" />
          </div>

          <h3 className="text-2xl font-bold text-center text-blue-900">Ingresa con tu cuenta</h3>

          <form onSubmit={hanldeSubmit}>

            <div className="mt-4">

              <div>
                <label className="block text-blue-900" htmlFor="username">Usuario</label>
                <input id="username" name="username" type="text" placeholder="usuario" onChange={hanldeChange} className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" autoComplete="on" autoFocus />
                <span id="error-username" className="invisible font-bold text-xs tracking-wide text-red-500">* Campo obligatorio</span>
              </div>

              <div className="mt-4">
                <label className="block text-blue-900">Contraseña</label>
                <input id="password" name="password" type="password" placeholder="contraseña" onChange={hanldeChange} className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" autoComplete="on" />
                <span id="error-password" className="invisible font-bold text-xs tracking-wide text-red-500">* Campo obligatorio</span>
              </div>

              <div className="-mt-6">
                <span id="error-user-not-valid" className="invisible font-bold text-xs tracking-wide text-red-500">* Usuario no válido</span>
              </div>

              {isLoading
                ? <>
                  <div className="h-16 flex items-baseline justify-center">
                    <Loader />
                  </div>
                </>
                : <>
                  <div className="mt-4 h-16 flex items-baseline justify-end">
                    <BtnAceptar text={"Ingresar"} />
                    {/*
                    <NavLink to="/">
                    <span className="text-sm text-gray-700 hover:underline">¿Olvidaste tu contraseña?</span>
                    </NavLink>
                    */}
                  </div>
                </>
              }

            </div>

          </form>

        </div>
      </div>

    </>
  );
}

export default Login;