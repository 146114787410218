/**
 * @file Usuarios.js
 * @brief Administración de Usuarios.
 * @author Efraín Gourcy
 * @modified Feb 13, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { NavLink,useNavigate } from 'react-router-dom';
import BtnAceptar from '../../components/BtnAceptar';
import { ApiUrl,settingsWithoutBody } from '../../helpers/ApiUrl';
import Grid from './Grid';
import { contextUserPermissions } from "../../App";

const Usuarios = () => {

  /** Variables */
  const {userPermissions} = useContext(contextUserPermissions);
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gridUsers, setGridUsers] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [permissionDelete, setPermissionDelete] = useState(false);
  const [permissionEdit, setPermissionEdit] = useState(false);
  let url = ApiUrl().url+"usuarios/get";

  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Valida los permisos para el módulo de usuarios */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "usuarios") {

          if (el.permiso === "ver")
            permission = true;

          if (el.permiso === "nuevo") {
            let $btnNuevoUsuario = document.getElementById("btn-nuevo-usuario");
            $btnNuevoUsuario.classList.remove("invisible");
          }

          if (el.permiso === "eliminar")
            setPermissionDelete(true);

          if (el.permiso === "editar")
            setPermissionEdit(true);

        }
      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);


  /**************** useEffect (url) ******************************************************************************* */
  useEffect(() => {
    /** Carga el grid de usuarios */
    setLoading(true);
      
      let valueToken = sessionStorage.getItem("token");

      let url2 = url;
      if (sessionStorage.getItem("grupo") !== '3881')
        url2 = ApiUrl().url+"usuarios/get/"+sessionStorage.getItem("escenario");

      fetch(url2,settingsWithoutBody(valueToken,'GET'))
      .then(res => res.json())
      .then(data => {
        setUsers(data.users);
        setGridUsers(data.users.slice(0,5));
        setLoading(false);
      });
      

  }, [url]);


  /**************** updateGrid ******************************************************************************* */
  const updateGrid = () => {
    /** Actualiza el grid */
    setUsers(null);
    setGridUsers(null);
    setLoading(true);

    let inputSearch = document.getElementById("search");
    inputSearch.value= "";
    setIsSearch(false);

    let valueToken = sessionStorage.getItem("token");

    fetch(url,settingsWithoutBody(valueToken,'GET'))
      .then(res => res.json())
      .then(data => {
        setUsers(data.users);
        setGridUsers(data.users.slice(0,5));
        setLoading(false);
      });
  };


  /**************** handleActive ******************************************************************************* */
  const handleActive = (e) => {
    /** Activa / Inactiva los usuarios */
    if (permissionDelete) {
      setUsers(null);
      setGridUsers(null);
      setLoading(true);

      let valueToken = sessionStorage.getItem("token");

      url = `${ApiUrl().url}usuarios/active/${e.currentTarget.dataset.id}`;
      fetch(url,settingsWithoutBody(valueToken),'GET')
        .then(res => res.json())
        .then(data => {
          setLoading(false);
          url = ApiUrl().url+"usuarios/get";
          updateGrid();
        });
    }
  };

  /**************** handleSearch ******************************************************************************* */
  const handleSearch = (e) => {
    /** Buscar un elemento dentro del grid de usuarios */
    let search = e.target.value;
    let usersArray = [];

    if (search.length > 0) {

      let serachByName = false;
      if (isNaN(search))
        serachByName = true;

      usersArray = users.filter(el => {
        
        if (serachByName){
          let name = el.name.toLowerCase();
          return name.includes(search.toLowerCase());
        }
      });

      setGridUsers(usersArray);
      setIsSearch(true);

    } else {
      setGridUsers(users.slice(0,5));
      setIsSearch(false);
    }

  };

  /**************** return ******************************************************************************* */
  return (
    <>
    
      <div className="md:py-12 md:px-12 sm:py-3 sm:px-3 py-3 px-3">

        <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-left border-b-2 border-[#3c70b9]">Usuarios del Sistema</h1>

        <div className="p-4 flex bg-gray-50 rounded-lg shadow-xl">
          <div className="flex-none md:flex-grow-0">
            
            <NavLink id="btn-nuevo-usuario" to="/usuarios/form/0" className="invisible">
              <BtnAceptar text={ "Nuevo Usuario" } />
            </NavLink>
            
          </div>

          <div className="pl-3 md:pl-96 flex-grow">
            <input id="search" type="text" onChange={ handleSearch } className="p-2 w-full rounded border-[#3c70b9] shadow-lg focus:ring-blue-500 focus:outline-none focus:ring" placeholder="Buscar..." />
          </div>
        </div>

        <Grid users={ users } loading={ loading } handleActive={ handleActive } gridUsers={ gridUsers } setGridUsers={ setGridUsers } isSearch={ isSearch} permissionEdit={ permissionEdit } />
          
      </div>
    </>
  );
}

export default Usuarios;