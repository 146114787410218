/**
 * @file Form.js
 * @brief Formulario es usuarios (Nuevo/Editar).
 * @author Efraín Gourcy
 * @modified Mar 24, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import BtnAceptar from '../../components/BtnAceptar';
import BtnCancelar from '../../components/BtnCancelar';
import Loader from '../../components/Loader';
import { ApiUrl, settingsWithBody, settingsWithoutBody } from '../../helpers/ApiUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contextUserPermissions } from "../../App";

/** Valores iniciales form */
const initalValues = {
  name: "",
  username: "",
  email: "",
  password: "",
  confirmPassword: "",
  perfil: "",
  escenario: ""
}

const UserForm = () => {

  /** Variables */
  let { id } = useParams();
  let navigate = useNavigate();
  const [form, setForm] = useState(initalValues);
  const [loading, setLoading] = useState(false);
  const {userPermissions, escenario} = useContext(contextUserPermissions);
  const [userDf, setUserDf] = useState(0);
  const [userDl, setUserDl] = useState(0);
  const [userSeccion, setUserSeccion] = useState(0);
  const [userCasilla, setUserCasilla] = useState(0);

  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Valida los permisos para el módulo de usuarios */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "usuarios") {
          if (el.permiso === "nuevo" || el.permiso == "editar")
            permission = true;
        }
      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);

  /**************** useEffect [] ******************************************************************************* */
  useEffect(() => {
    if (id == 0)
      loadDistritosFederales();
  }, []);


  /**************** useEffect [userDf] ******************************************************************************* */
  useEffect(() => {
    if (userDf != 0)
      loadDistritosFederales2(userDf);
  }, [userDf]);


  /**************** useEffect [id] ******************************************************************************* */
  useEffect(() => {
    /** Carga los escenarios */
    const cargaEscenarios = async (escenario) => {

      let token = sessionStorage.getItem("token");
      const response = await fetch(ApiUrl().url + "escenarios/getActives", settingsWithoutBody(token, 'GET'));
      const respEscenarios = await response.json();

      let $option;
      let $select = document.getElementById("escenario");
      $select.innerHTML = "";

      $option = document.createElement("option");
      $option.innerText = "Selecciona un opción";
      $option.value = "-1";
      $select.appendChild($option);

      let existe = false;
      if (respEscenarios.status === "success") {
        respEscenarios.escenarios.forEach(el => {
          $option = document.createElement("option");
          $option.innerText = el.nombre;
          $option.value = el.id;
          if (el.id === escenario)
            existe = true;
          $select.appendChild($option);
        });

        if (escenario !== "" && existe)
          $select.value = escenario;

      }
    };

    /** Carga los perfiles */
    const cargaPerfiles = async (perfiles) => {
      let valueToken = sessionStorage.getItem("token");
      let respPerfiles;

      let response = "";
      if (sessionStorage.getItem("grupo") === "3881")
        response = await fetch(ApiUrl().url + "perfiles/get", settingsWithoutBody(valueToken, 'GET'));
      else
        response = await fetch(ApiUrl().url + "perfiles/get/"+sessionStorage.getItem("escenario"), settingsWithoutBody(valueToken, 'GET'));

      respPerfiles = await response.json();

      if (respPerfiles.perfiles) {

        let $divEl;
        let $element;
        let $label;
        let $permisos = document.getElementById("perfiles");
        $permisos.innerHTML = "";

        let $div = document.createElement("div");
        $div.classList.add("perfiles", "grid", "grid-cols-2", "md:grid-cols-3", "gap-4", "items-center", "mb-2");

        let idPerfil = 0;

        respPerfiles.perfiles.forEach(el => {
          idPerfil++;

          $divEl = document.createElement("div");

          $element = document.createElement("input");
          $element.type = "checkbox";
          $element.name = idPerfil;
          $element.value = el.perfil;
          $element.id = idPerfil;

          if (perfiles !== "") {
            let result = perfiles.includes(el.perfil);
            if (result)
              $element.checked = true;
          }

          $element.classList.add("cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

          $label = document.createElement("label");
          $label.htmlFor = idPerfil;
          $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
          $label.appendChild(document.createTextNode(el.perfil));

          $divEl.appendChild($element);
          $divEl.appendChild($label);
          $div.appendChild($divEl);

          $permisos.appendChild($div);

        });

      }
    };

    /* Carga perfiles usuarios APP */
    const cargaPerfilesUsuariosApp = async() => {

      let $idUsuarioApp = 0;
      if (id)
        $idUsuarioApp = id

      let valueToken = sessionStorage.getItem("token");
      let response = await fetch(ApiUrl().url + "usuariosApp/get/" + $idUsuarioApp, settingsWithoutBody(valueToken, 'GET'));
      let respUsuarios = await response.json();

      let bing = "0";
      let movi = "0";
      let graf = "0";
      let prep = "0";
      let lide = "0";
      let busq = "0";
      let esta = "0";
      let herr = "0";
      let estr = "0";
      let df = "";
      let dl = "";
      let seccion = "";
      let casilla = "";

      //console.log(respUsuarios.usuarios);

      if (respUsuarios.usuarios) {
        respUsuarios.usuarios.forEach(el => {
          if (el.bing == "1")
            bing = "1";
          if (el.movi == "1")
            movi = "1";
          if (el.graf == "1")
            graf = "1";
          if (el.prep == "1")
            prep = "1";
          if (el.lide == "1")
            lide = "1";
          if (el.busq == "1")
            busq = "1";
          if (el.esta == "1")
            esta = "1";
          if (el.herr == "1")
            herr = "1";
          if (el.estr == "1")
            estr = "1";
        });

        if (respUsuarios.usuarios.length > 0) {
          df = respUsuarios.usuarios[0].distrito_federal;
          dl = respUsuarios.usuarios[0].distrito_local;
          seccion = respUsuarios.usuarios[0].seccion;
          casilla = respUsuarios.usuarios[0].casilla;
        }

      }

      if (df != "" && dl != "" && seccion != "" && casilla != "") {
        setUserDf(df);
        setUserDl(dl);
        setUserSeccion(seccion);
        setUserCasilla(casilla);

        document.getElementById('div-dl').classList.remove('hidden');
        document.getElementById('div-seccion').classList.remove('hidden');
        document.getElementById('div-casilla').classList.remove('hidden');

      } else {
        loadDistritosFederales();
      }


      let $usuariosApp = document.getElementById("usuarios-app");
      $usuariosApp.innerHTML = "";

      let $div = document.createElement("div");
      $div.classList.add("usuarios-app", "grid", "grid-cols-2", "md:grid-cols-3", "gap-4", "items-center", "mb-2");

      let $element;
      let $divEl;
      let $label;

      /* Bingo */
      $divEl = document.createElement("div");

      $element = document.createElement("input");
      $element.type = "checkbox";
      $element.name = "app-bingo";
      $element.value = "app-bingo";
      $element.id = "app-bingo";

      if (bing == "1")
        $element.checked = true;

      $element.classList.add("cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

      $label = document.createElement("label");
      $label.htmlFor = "app-bingo";
      $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
      $label.appendChild(document.createTextNode("Bingo"));

      $divEl.appendChild($element);
      $divEl.appendChild($label);
      $div.appendChild($divEl);

      $usuariosApp.appendChild($div);

      /* Movilizador */
      $divEl = document.createElement("div");

      $element = document.createElement("input");
      $element.type = "checkbox";
      $element.name = "app-movilizador";
      $element.value = "app-movilizador";
      $element.id = "app-movilizador";

      if (movi == "1")
        $element.checked = true;

      $element.classList.add("cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

      $label = document.createElement("label");
      $label.htmlFor = "app-movilizador";
      $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
      $label.appendChild(document.createTextNode("Movilizador"));

      $divEl.appendChild($element);
      $divEl.appendChild($label);
      $div.appendChild($divEl);

      $usuariosApp.appendChild($div);


      /* Graficas */
      $divEl = document.createElement("div");

      $element = document.createElement("input");
      $element.type = "checkbox";
      $element.name = "app-graficas";
      $element.value = "app-graficas";
      $element.id = "app-graficas";

      if (graf == "1")
        $element.checked = true;

      $element.classList.add("cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

      $label = document.createElement("label");
      $label.htmlFor = "app-graficas";
      $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
      $label.appendChild(document.createTextNode("Gráficas"));

      $divEl.appendChild($element);
      $divEl.appendChild($label);
      $div.appendChild($divEl);

      $usuariosApp.appendChild($div);


      /* Prep */
      $divEl = document.createElement("div");

      $element = document.createElement("input");
      $element.type = "checkbox";
      $element.name = "app-prep";
      $element.value = "app-prep";
      $element.id = "app-prep";

      if (prep == "1")
        $element.checked = true;

      $element.classList.add("cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

      $label = document.createElement("label");
      $label.htmlFor = "app-prep";
      $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
      $label.appendChild(document.createTextNode("PREP"));

      $divEl.appendChild($element);
      $divEl.appendChild($label);
      $div.appendChild($divEl);

      $usuariosApp.appendChild($div);

      /* Liderazgo */
      $divEl = document.createElement("div");

      $element = document.createElement("input");
      $element.type = "checkbox";
      $element.name = "app-liderazgo";
      $element.value = "app-liderazgo";
      $element.id = "app-liderazgo";

      if (lide == "1")
        $element.checked = true;

      $element.classList.add("cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

      $label = document.createElement("label");
      $label.htmlFor = "app-liderazgo";
      $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
      $label.appendChild(document.createTextNode("Liderazgo"));

      $divEl.appendChild($element);
      $divEl.appendChild($label);
      $div.appendChild($divEl);

      $usuariosApp.appendChild($div);


      /* Busqueda */
      $divEl = document.createElement("div");

      $element = document.createElement("input");
      $element.type = "checkbox";
      $element.name = "app-busqueda";
      $element.value = "app-busqueda";
      $element.id = "app-busqueda";

      if (busq == "1")
        $element.checked = true;

      $element.classList.add("cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

      $label = document.createElement("label");
      $label.htmlFor = "app-busqueda";
      $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
      $label.appendChild(document.createTextNode("Búsqueda"));

      $divEl.appendChild($element);
      $divEl.appendChild($label);
      $div.appendChild($divEl);

      $usuariosApp.appendChild($div);

      /* Estadisticas */
      $divEl = document.createElement("div");

      $element = document.createElement("input");
      $element.type = "checkbox";
      $element.name = "app-estadisticas";
      $element.value = "app-estadisticas";
      $element.id = "app-estadisticas";

      if (esta == "1")
        $element.checked = true;

      $element.classList.add("cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

      $label = document.createElement("label");
      $label.htmlFor = "app-estadisticas";
      $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
      $label.appendChild(document.createTextNode("Estadísticas"));

      $divEl.appendChild($element);
      $divEl.appendChild($label);
      $div.appendChild($divEl);

      $usuariosApp.appendChild($div);

      /* Herramientas */
      $divEl = document.createElement("div");

      $element = document.createElement("input");
      $element.type = "checkbox";
      $element.name = "app-herramientas";
      $element.value = "app-herramientas";
      $element.id = "app-herramientas";

      if (herr == "1")
        $element.checked = true;

      $element.classList.add("cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

      $label = document.createElement("label");
      $label.htmlFor = "app-herramientas";
      $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
      $label.appendChild(document.createTextNode("Herramientas"));

      $divEl.appendChild($element);
      $divEl.appendChild($label);
      $div.appendChild($divEl);

      $usuariosApp.appendChild($div);

      /* Estrcutura */
      $divEl = document.createElement("div");

      $element = document.createElement("input");
      $element.type = "checkbox";
      $element.name = "app-estructura";
      $element.value = "app-estructura";
      $element.id = "app-estructura";

      if (estr == "1")
        $element.checked = true;

      $element.classList.add("cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

      $label = document.createElement("label");
      $label.htmlFor = "app-estructura";
      $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
      $label.appendChild(document.createTextNode("Estructura"));

      $divEl.appendChild($element);
      $divEl.appendChild($label);
      $div.appendChild($divEl);

      $usuariosApp.appendChild($div);



    };

    /** Carga los datos del usuario (Modo Edición) */
    if (parseInt(id) !== 0) {

      const searchUser = async () => {

        let valueToken = sessionStorage.getItem("token");

        try {

          let url = ApiUrl().url + "usuarios/getUser/" + id;
          const response = await fetch(url, settingsWithoutBody(valueToken, 'GET'));
          const respUser = await response.json();

          if (respUser.user[0]) {

            setForm({
              ...form,
              username: respUser.user[0].username,
              name: respUser.user[0].name,
              email: respUser.user[0].email,
              password: "aabbcc58",
              confirmPassword: "aabbcc58",
              perfil: respUser.user[0].perfil,
              escenario: respUser.user[0].escenario,
            });
          } else {
            navigate('/usuarios');
          }

          cargaPerfiles(respUser.user[0].perfil);
          cargaPerfilesUsuariosApp();
          if (sessionStorage.getItem("grupo") === "3881")
            cargaEscenarios(respUser.user[0].escenario);

        } catch (err) {
          console.log(err);
        }
      };

      searchUser();

    } else {
      cargaPerfiles("");
      cargaPerfilesUsuariosApp();
      setForm({
        ...form,
        escenario: sessionStorage.getItem("escenario")
      });
      if (sessionStorage.getItem("grupo") === "3881")
        cargaEscenarios("");
    }
  }, [id]);


  /**************** handleChange ******************************************************************************* */
  const handleChange = (e) => {
    /** Actualización de los campos del formulario */
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };


  /**************** store ******************************************************************************* */
  const store = async () => {
    /** Guarda / Actualiza el usuario */
    setLoading(true);

    let valueToken = sessionStorage.getItem("token");
    let url = ApiUrl().url;

    try {

      let errorUsuariosApp = false;

      if (parseInt(id) === 0)
        url = url + "usuarios/store";
      else
        url = url + "usuarios/edit/" + id;

      /**  Perfil del usuario */
      let perfil = "";
      let $elements = document.getElementById('perfiles').getElementsByTagName('input');
      for (var i = 0; i < $elements.length; i++) {
        if ($elements[i].checked === true)
          perfil = perfil + $elements[i].value + ",";
      }

      if (perfil !== "")
        perfil = perfil.substring(0, perfil.length - 1);

      let data;

      /**  Usuarios APP Móvil */
      let usuariosApp = "";
      let $elementsApp = document.getElementById('usuarios-app').getElementsByTagName('input');
      for (var i = 0; i < $elementsApp.length; i++) {
        if ($elementsApp[i].checked === true)
          usuariosApp = usuariosApp + $elementsApp[i].value + ",";
      }


      let $valueDf = 0;
      let $valueDl = 0;
      let $valueSeccion = 0;
      let $valueCasilla = 0;

      if (usuariosApp !== "") {

        try {

          let $df = document.getElementById('distritoFederal');
          $valueDf = $df.options[$df.selectedIndex].text;
          //console.log($valueDf);
    
          let $dl = document.getElementById('distritoLocal');
          $valueDl = $dl.options[$dl.selectedIndex].text;
          //console.log($valueDl);
    
          let $seccion = document.getElementById('seccion');
          $valueSeccion = $seccion.options[$seccion.selectedIndex].text;
          //console.log($valueSeccion);
    
          let $casilla = document.getElementById('casilla');
          $valueCasilla = $casilla.options[$casilla.selectedIndex].text;
          //console.log($valueCasilla);

        } catch (err) {
          //errorUsuariosApp = true;
        }

        usuariosApp = usuariosApp.substring(0, usuariosApp.length - 1);

      }
        

      //console.log(usuariosApp);

      if (parseInt(id) !== 0 && form.password === "aabbcc58") {
        data = {
          ...form,
          "password": "",
          "confirmPassword": "",
          "perfil": perfil,
          "app": usuariosApp,
          "df": $valueDf,
          "dl": $valueDl,
          "seccion": $valueSeccion,
          "casilla": $valueCasilla
        }
      } else {
        data = {
          ...form,
          "perfil": perfil,
          "app": usuariosApp,
          "df": $valueDf,
          "dl": $valueDl,
          "seccion": $valueSeccion,
          "casilla": $valueCasilla
        }
      }

      if (!errorUsuariosApp) {

        const response = await fetch(url, settingsWithBody(valueToken, data, 'POST'));
        const respUser = await response.json();

        if (respUser.status === "success")
          return navigate("/usuarios");
          

        if (respUser.message) {

          if (respUser.errors.username) {
            toast.error('El nombre de usaurio ya existe', {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored"
            });
          } else {
            if (respUser.errors.email) {
              toast.error('Correo electrónico incorrecto', {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
              });
            } else {
              toast.error('Campos incorrectos', {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
              });
            }
          }
        }

      } else {
        toast.error('No ha seleccionado Distrito Federal, Distrito Local, Seccion o Casiila para el usuario de la APP', {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored"
        });
      }

    } catch (err) {
      toast.error('Error de comunicación con el servidor', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
    }

    setLoading(false);
  };


  /**************** handleAccept ******************************************************************************* */
  const handleAccept = (e) => {
    /** Clic al botón de aceptar */
    e.preventDefault();

    /** Reseta los errores */
    let error = false;

    let inputName = document.getElementById("name");
    let inputUsername = document.getElementById("username");
    let inputEmail = document.getElementById("email");
    let inputPassword = document.getElementById("password");
    let inputConfirmPassword = document.getElementById("confirmPassword");
    let selectEscenario = document.getElementById("escenario");

    /** Valida los datos */
    if (form.name === "") {
      inputName.classList.add("ring-2");
      inputName.classList.add("ring-red-400");
      toast.error('No ha introducido un nombre', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
      error = true;
    } else {
      inputName.classList.remove("ring-2");
      inputName.classList.remove("ring-red-400");
    }

    if (form.username === "") {
      inputUsername.classList.add("ring-2");
      inputUsername.classList.add("ring-red-400");
      toast.error('No ha introducido un nombre de usuario', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
      error = true;
    } else {
      inputUsername.classList.remove("ring-2");
      inputUsername.classList.remove("ring-red-400");
    }

    if (form.email === "") {
      inputEmail.classList.add("ring-2");
      inputEmail.classList.add("ring-red-400");
      toast.error('No ha introducido un correo electrónico', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
      error = true;
    } else {
      inputEmail.classList.remove("ring-2");
      inputEmail.classList.remove("ring-red-400");
    }

    if (form.password === "") {
      inputPassword.classList.add("ring-2");
      inputPassword.classList.add("ring-red-400");
      toast.error('No ha introducido una contraseña', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
      error = true;
    } else {
      inputPassword.classList.remove("ring-2");
      inputPassword.classList.remove("ring-red-400");
    }

    if (form.confirmPassword === "") {
      inputConfirmPassword.classList.add("ring-2");
      inputConfirmPassword.classList.add("ring-red-400");
      toast.error('No ha introducido la conformación de contraseña', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
      error = true;
    } else {
      inputConfirmPassword.classList.remove("ring-2");
      inputConfirmPassword.classList.remove("ring-red-400");
    }

    if (sessionStorage.getItem("grupo") === "3881") {
      if (form.escenario === "" || form.escenario === "-1") {
        selectEscenario.classList.add("ring-2");
        selectEscenario.classList.add("ring-red-400");
        toast.error('No ha seleccionado un escenario', {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored"
        });
        error = true;
      } else {
        selectEscenario.classList.remove("ring-2");
        selectEscenario.classList.remove("ring-red-400");
      }
    }
    

    if (form.password !== form.confirmPassword) {
      inputPassword.classList.add("ring-2");
      inputPassword.classList.add("ring-red-400");
      inputConfirmPassword.classList.add("ring-2");
      inputConfirmPassword.classList.add("ring-red-400");
      toast.error('Las contraseñas no son iguales', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
      error = true;
    }

    if (!error)
      store();

  };

  /**************** handleChangeSelect ******************************************************************************* */
  const handleChangeSelect = (e) => {

    if (e.target.id === 'distritoFederal') {
      if (e.target.value !== '-1') {
        loadDistritosLocales(e.target.value);
        document.getElementById('div-dl').classList.remove('hidden');
        document.getElementById('div-seccion').classList.add('hidden');
        document.getElementById('div-casilla').classList.add('hidden');
      } else {
        document.getElementById('div-dl').classList.add('hidden');
        document.getElementById('div-seccion').classList.add('hidden');
        document.getElementById('div-casilla').classList.add('hidden');
      }
    }

    if (e.target.id === 'distritoLocal') {
      if (e.target.value !== '-1') {
        loadSecciones(e.target.value);
        document.getElementById('div-seccion').classList.remove('hidden');
        document.getElementById('div-casilla').classList.add('hidden');
      } else {
        document.getElementById('div-seccion').classList.add('hidden');
        document.getElementById('div-casilla').classList.add('hidden');
      }
    }

    if (e.target.id === 'seccion') {
      if (e.target.value !== '-1') {
        loadCasillas(e.target.value);
        document.getElementById('div-casilla').classList.remove('hidden');
      } else {
        document.getElementById('div-casilla').classList.add('hidden');
      }
    }

    if (e.target.id === 'casilla') {
      if (e.target.value !== '-1') {
        //loadCheckBoxes();
      }
    }

  };

  /**************** loadDistritosFederales ******************************************************************************* */
  const loadDistritosFederales = async () => {

    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getDistritosFederales/" + valorEscenario, settingsWithoutBody(token, 'GET'));
    const respDistritosFederales = await resp.json();

    let $selectDistritoFederal = document.getElementById('distritoFederal');
    $selectDistritoFederal.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectDistritoFederal.appendChild($option);

    if (respDistritosFederales.status === "success") {
      respDistritosFederales.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.distrito_federal;
        $option.innerText = el.distrito_federal;
        $selectDistritoFederal.appendChild($option);
      });
    }
  };

  /**************** loadDistritosLocales ******************************************************************************* */
  const loadDistritosLocales = async (df) => {

    /** Carga el select de distrito local */
    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getDistritosLocalesFiltrado/" + valorEscenario + "/" + df, settingsWithoutBody(token, 'GET'));
    const respDistritosLocales = await resp.json();

    let $selectDistritoFederal = document.getElementById('distritoLocal');
    $selectDistritoFederal.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectDistritoFederal.appendChild($option);

    if (respDistritosLocales.padron) {
      respDistritosLocales.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.distrito_local;
        $option.innerText = el.distrito_local;
        $selectDistritoFederal.appendChild($option);
      });
    }

  };


  /**************** loadSecciones ******************************************************************************* */
  const loadSecciones = async (dl) => {
    /** Carga el select de seccion */

    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getSeccionesFiltrado/" + valorEscenario + "/" + dl, settingsWithoutBody(token, 'GET'));
    const respSecciones = await resp.json();

    let $selectSeccion = document.getElementById('seccion');
    $selectSeccion.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectSeccion.appendChild($option);

    if (respSecciones.padron) {
      respSecciones.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.seccion;
        $option.innerText = el.seccion;
        $selectSeccion.appendChild($option);
      });
    }

  };


  /**************** loadCasillas ******************************************************************************* */
  const loadCasillas = async (seccion) => {
    /** Carga el select de casillas */

    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getCasillasFiltrado/" + valorEscenario + "/" + seccion, settingsWithoutBody(token, 'GET'));
    const respCasillas = await resp.json();

    let $selectCasillas = document.getElementById('casilla');
    $selectCasillas.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectCasillas.appendChild($option);

    if (respCasillas.padron) {
      respCasillas.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.casilla;
        $option.innerText = el.casilla;
        $selectCasillas.appendChild($option);
      });
    }

  };


  /**************** loadDistritosFederales2 ******************************************************************************* */
  const loadDistritosFederales2 = async (df) => {

    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getDistritosFederales/" + valorEscenario, settingsWithoutBody(token, 'GET'));
    const respDistritosFederales = await resp.json();

    let $selectDistritoFederal = document.getElementById('distritoFederal');
    $selectDistritoFederal.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectDistritoFederal.appendChild($option);

    if (respDistritosFederales.status === "success") {
      respDistritosFederales.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.distrito_federal;
        $option.innerText = el.distrito_federal;

        if (el.distrito_federal == df) {
          $option.selected = true;
        }

        $selectDistritoFederal.appendChild($option);
      });
    }

    if (userDl != 0)
      loadDistritosLocales2(df, userDl);

  };


  /**************** loadDistritosLocales2 ******************************************************************************* */
  const loadDistritosLocales2 = async (df,dl) => {

    /** Carga el select de distrito local */
    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getDistritosLocalesFiltrado/" + valorEscenario + "/" + df, settingsWithoutBody(token, 'GET'));
    const respDistritosLocales = await resp.json();

    let $selectDistritoFederal = document.getElementById('distritoLocal');
    $selectDistritoFederal.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectDistritoFederal.appendChild($option);

    if (respDistritosLocales.padron) {
      respDistritosLocales.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.distrito_local;
        $option.innerText = el.distrito_local;

        if (el.distrito_local == dl) {
          $option.selected = true;
        }

        $selectDistritoFederal.appendChild($option);
      });
    }

    if (userSeccion != 0)
      loadSecciones2(dl, userSeccion);

  };

  /**************** loadSecciones2 ******************************************************************************* */
  const loadSecciones2 = async (dl,seccion) => {
    /** Carga el select de seccion */

    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getSeccionesFiltrado/" + valorEscenario + "/" + dl, settingsWithoutBody(token, 'GET'));
    const respSecciones = await resp.json();

    let $selectSeccion = document.getElementById('seccion');
    $selectSeccion.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectSeccion.appendChild($option);

    if (respSecciones.padron) {
      respSecciones.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.seccion;
        $option.innerText = el.seccion;

        if (el.seccion == seccion) {
          $option.selected = true;
        }

        $selectSeccion.appendChild($option);
      });
    }

    if (userCasilla != 0)
      loadCasillas2(seccion, userCasilla);

  };


  /**************** loadCasillas2 ******************************************************************************* */
  const loadCasillas2 = async (seccion,casilla) => {
    /** Carga el select de casillas */

    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getCasillasFiltrado/" + valorEscenario + "/" + seccion, settingsWithoutBody(token, 'GET'));
    const respCasillas = await resp.json();

    let $selectCasillas = document.getElementById('casilla');
    $selectCasillas.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectCasillas.appendChild($option);

    if (respCasillas.padron) {
      respCasillas.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.casilla;
        $option.innerText = el.casilla;

        if (el.casilla == casilla) {
          $option.selected = true;
        }

        $selectCasillas.appendChild($option);
      });
    }

  };

  /**************** return ******************************************************************************* */
  return (
    <>
      <ToastContainer autoClose={2000} />
      <div className="py-6 px-2">

        <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-center">Crea un Nuevo Usuario</h1>

        <div className="grid md:grid-cols-6 gap-3">

          <div className="md:col-start-2 md:col-span-4 bg-gray-100 rounded-lg p-2">

            <form>
              <div className="px-2 py-2 grid md:grid-cols-4 gap-2">

                <div className="col-span-4 p-1">
                  <label htmlFor="name" className="text-gray-500">Nombre</label>
                  <input type="text" id="name" name="name" autoComplete="true" value={form.name} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>

                <div className="p-1 md:col-span-2 col-span-4">
                  <label htmlFor="username" className="text-gray-500">Usuario</label>
                  <input type="text" id="username" name="username" autoComplete="true" value={form.username} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>

                <div className="p-1 md:col-span-2 col-span-4">
                  <label htmlFor="email" className="text-gray-500">Correo Electrónico</label>
                  <input type="text" id="email" name="email" autoComplete="true" value={form.email} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>

                <div className="p-1 md:col-span-2 col-span-4 -mt-4">
                  <label htmlFor="password" className="text-gray-500">Contraseña</label>
                  <input type="password" id="password" name="password" autoComplete="true" value={form.password} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>

                <div className="p-1 md:col-span-2 col-span-4 -mt-4">
                  <label htmlFor="confirmPassword" className="text-gray-500">Confirma Contraseña</label>
                  <input type="password" id="confirmPassword" name="confirmPassword" autoComplete="true" value={form.confirmPassword} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>

                {sessionStorage.getItem("grupo") === "3881"
                  ? <>
                      <div className="col-span-4 p-1 -mt-4">
                        <label htmlFor="escenario" className="text-gray-500">Escenario</label>
                        <select id="escenario" name="escenario" value={form.escenario} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
                        </select>
                      </div>
                    </>
                  : <></>
                }

                <div className="col-span-4 p-1 border-b-2 border-gray-400 mt-4">
                  <div className="text-sm tracking-wide text-gray-500 uppercase">perfil</div>
                </div>

                <div id="perfiles" className="col-span-4 p-1">
                </div>

                <div className="col-span-4 p-1 border-b-2 border-gray-400 mt-4">
                  <div className="text-sm tracking-wide text-gray-500 uppercase">Es usuario de la app</div>
                </div>

                <div id="usuarios-app" className="col-span-4 p-1">
                </div>

                <div className="mt-10">
                  <label htmlFor="distritoFederal" className="text-gray-600">Distrito Federal</label>
                  <select onChange={handleChangeSelect} id="distritoFederal" name="distritoFederal" className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
                  </select>
                </div>

                <div className="mt-10 hidden" id="div-dl">
                  <label htmlFor="distritoLocal" className="text-gray-600">Distrito Local</label>
                  <select onChange={handleChangeSelect} id="distritoLocal" name="distritoLocal" className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
                  </select>
                </div>

                <div className="mt-10 hidden" id="div-seccion">
                  <label htmlFor="seccion" className="text-gray-600">Sección</label>
                  <select onChange={handleChangeSelect} id="seccion" name="seccion" className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
                  </select>
                </div>

                <div className="mt-10 hidden" id="div-casilla">
                  <label htmlFor="casilla" className="text-gray-600">Casilla</label>
                  <select onChange={handleChangeSelect} id="casilla" name="casilla" className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
                  </select>
                </div>

                

                <div className="p-1 col-span-4 md:justify-self-end mt-12">
                  {loading
                    ? <Loader />
                    : <>
                      <span onClick={handleAccept} className="mr-4"><BtnAceptar text={"Guardar"} /></span>

                      <NavLink to="/usuarios">
                        <BtnCancelar text={"Cancelar "} />
                      </NavLink>
                    </>
                  }

                </div>

              </div>
            </form>

          </div>

        </div>

      </div>
    </>
  );
}

export default UserForm;