/**
 * @file Session.js
 * @brief Helper para el manejo de la sesión.
 * @author Efraín Gourcy
 * @modified Mar 3, 2023
 */

 import { ApiUrl,settingsWithBody } from "./ApiUrl";

/**************** validateToken ******************************************************************************* */
const validateToken = async (tokenSessionStorage) => {
  /** Valida que el token sea válido en la API */
  try {
    const response = await fetch(ApiUrl().url+"validToken", settingsWithBody(tokenSessionStorage,{token:tokenSessionStorage},'POST'));
    const respToken = await response.json();

    if (respToken.message === "Unauthenticated." || respToken.message === 'Unauthorized') {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("token_time");
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("escenario");
      sessionStorage.removeItem("usernmae");
      sessionStorage.removeItem("profile");
      sessionStorage.removeItem("grupo");
      return false;
      
    } else
      return true;

  } catch (err) {
    console.log(err);
    return false;
  }
};

/**************** validToken ******************************************************************************* */
export const validToken = () => {
  /** Obtiene el token del sessionStorage y lo válida */
  let tokenSessionStorage = sessionStorage.getItem("token");

  if (tokenSessionStorage === null)
    return false;
  else
    return validateToken(tokenSessionStorage);
  
};