/**
 * @file BingoPrepCaptura.js
 * @brief Captura del Bingo Prep (Programa de resultados preliminares).
 * @author Efraín Gourcy
 * @modified Jul 26, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { contextUserPermissions } from '../../App';
import { ApiUrl, settingsWithBody, settingsWithoutBody } from '../../helpers/ApiUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import BtnAceptarLargo from '../../components/BtnAceptarLargo';

const initialValues = {
  distritoFederal: "",
  distritoLocal: "",
  seccion: "",
  casilla: "",
  pan: "0",
  pri: "0",
  prd: "0",
  pt: "0",
  verde: "0",
  mc: "0",
  morena: "0",
  pes: "0",
  fsm: "0",
  rsp: "0",
  pla: "0",
  nulos: "0",
  escenario: "0"
}

const BingoPrepCaptura = () => {
  /** Variables */
  const { escenario } = useContext(contextUserPermissions);
  const navigate = useNavigate();
  const [form, setForm] = useState(initialValues);
  const {userPermissions} = useContext(contextUserPermissions);


  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Valida los permisos para el módulo de bingo prep */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "bingoPrep") {
          if (el.permiso === "capturar")
            permission = true;
        }
      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);


  /**************** useEffect [] ******************************************************************************* */
  useEffect(() => {
    /** Carga el select de distrito federal */
    const loadDistritosFederales = async () => {

      let valorEscenario = sessionStorage.getItem('escenario');
      if (escenario !== null)
        valorEscenario = escenario;

      let token = sessionStorage.getItem("token");
      const resp = await fetch(ApiUrl().url + "padron/getDistritosFederales/" + valorEscenario, settingsWithoutBody(token, 'GET'));
      const respDistritosFederales = await resp.json();

      let $selectDistritoFederal = document.getElementById('distritoFederal');
      $selectDistritoFederal.innerHTML = '';

      let $option = document.createElement('option');
      $option.value = '-1';
      $option.innerText = 'Selecciona una opción';
      $selectDistritoFederal.appendChild($option);

      if (respDistritosFederales.status === "success") {
        respDistritosFederales.padron.forEach(el => {
          let $option = document.createElement('option');
          $option.value = el.distrito_federal;
          $option.innerText = el.distrito_federal;
          $selectDistritoFederal.appendChild($option);
        });
      }

      setForm({
        ...form,
        escenario: valorEscenario
      });

    };


    /** Carga partidos visibles */
    const loadPartidosVisibles = async () => {
      let token = sessionStorage.getItem("token");
      //const resp = await fetch(ApiUrl().url + "partidos/getVisibles", settingsWithoutBody(token, 'GET'));
      //const respPartidos = await resp.json();

    };


    loadDistritosFederales();
    loadPartidosVisibles();
    
  }, []);


  /**************** store ******************************************************************************* */
  const store = async () => {
    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "bingoPrep/store", settingsWithBody(token, form, 'POST'));
    const respPrep = await resp.json();
    if (respPrep.status === "success") {
      navigate("/bingoPrep");
    } else {
      toast.error('Ocurrio un error al guardar el registro', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
    }
  };


  /**************** hanldeAccept ******************************************************************************* */
  const hanldeAccept = (e) => {
    e.preventDefault();

    let error = false;
    let $selectDistritoFederal = document.getElementById("distritoFederal");
    $selectDistritoFederal.classList.remove("ring-2");
    $selectDistritoFederal.classList.remove("ring-red-400");
    let $selectDistritoLocal = document.getElementById("distritoLocal");
    $selectDistritoLocal.classList.remove("ring-2");
    $selectDistritoLocal.classList.remove("ring-red-400");
    let $selectSeccion = document.getElementById("seccion");
    $selectSeccion.classList.remove("ring-2");
    $selectSeccion.classList.remove("ring-red-400");
    let $selectCasilla = document.getElementById("casilla");
    $selectCasilla.classList.remove("ring-2");
    $selectCasilla.classList.remove("ring-red-400");

    let $inputPan = document.getElementById("pan");
    $inputPan.classList.remove("ring-2");
    $inputPan.classList.remove("ring-red-400");
    let $inputPri = document.getElementById("pri");
    $inputPri.classList.remove("ring-2");
    $inputPri.classList.remove("ring-red-400");
    let $inputPrd = document.getElementById("prd");
    $inputPrd.classList.remove("ring-2");
    $inputPrd.classList.remove("ring-red-400");
    let $inputPt = document.getElementById("pt");
    $inputPt.classList.remove("ring-2");
    $inputPt.classList.remove("ring-red-400");
    let $inputVerde = document.getElementById("verde");
    $inputVerde.classList.remove("ring-2");
    $inputVerde.classList.remove("ring-red-400");
    let $inputMc = document.getElementById("mc");
    $inputMc.classList.remove("ring-2");
    $inputMc.classList.remove("ring-red-400");
    let $inputMorena = document.getElementById("morena");
    $inputMorena.classList.remove("ring-2");
    $inputMorena.classList.remove("ring-red-400");
    let $inputPes = document.getElementById("pes");
    $inputPes.classList.remove("ring-2");
    $inputPes.classList.remove("ring-red-400");
    let $inputFsm = document.getElementById("fsm");
    $inputFsm.classList.remove("ring-2");
    $inputFsm.classList.remove("ring-red-400");
    let $inputRsp = document.getElementById("rsp");
    $inputRsp.classList.remove("ring-2");
    $inputRsp.classList.remove("ring-red-400");
    let $inputPla = document.getElementById("pla");
    $inputPla.classList.remove("ring-2");
    $inputPla.classList.remove("ring-red-400");
    let $inputNulos = document.getElementById("nulos");
    $inputNulos.classList.remove("ring-2");
    $inputNulos.classList.remove("ring-red-400");

    if (form.distritoFederal === '' || form.distritoFederal === '-1') {
      $selectDistritoFederal.classList.add("ring-2");
      $selectDistritoFederal.classList.add("ring-red-400");
      error = true;
    }

    if (form.distritoLocal === '' || form.distritoLocal === '-1') {
      $selectDistritoLocal.classList.add("ring-2");
      $selectDistritoLocal.classList.add("ring-red-400");
      error = true;
    }

    if (form.seccion === '' || form.seccion === '-1') {
      $selectSeccion.classList.add("ring-2");
      $selectSeccion.classList.add("ring-red-400");
      error = true;
    }

    if (form.casilla === '' || form.casilla === '-1') {
      $selectCasilla.classList.add("ring-2");
      $selectCasilla.classList.add("ring-red-400");
      error = true;
    }

    if (form.pan === '') {
      $inputPan.classList.add("ring-2");
      $inputPan.classList.add("ring-red-400");
      error = true;
    }

    if (form.pri === '') {
      $inputPri.classList.add("ring-2");
      $inputPri.classList.add("ring-red-400");
      error = true;
    }

    if (form.prd === '') {
      $inputPrd.classList.add("ring-2");
      $inputPrd.classList.add("ring-red-400");
      error = true;
    }

    if (form.pt === '') {
      $inputPt.classList.add("ring-2");
      $inputPt.classList.add("ring-red-400");
      error = true;
    }

    if (form.verde === '') {
      $inputVerde.classList.add("ring-2");
      $inputVerde.classList.add("ring-red-400");
      error = true;
    }

    if (form.mc === '') {
      $inputMc.classList.add("ring-2");
      $inputMc.classList.add("ring-red-400");
      error = true;
    }

    if (form.morena === '') {
      $inputMorena.classList.add("ring-2");
      $inputMorena.classList.add("ring-red-400");
      error = true;
    }

    if (form.pes === '') {
      $inputPes.classList.add("ring-2");
      $inputPes.classList.add("ring-red-400");
      error = true;
    }

    if (form.fsm === '') {
      $inputFsm.classList.add("ring-2");
      $inputFsm.classList.add("ring-red-400");
      error = true;
    }

    if (form.rsp === '') {
      $inputRsp.classList.add("ring-2");
      $inputRsp.classList.add("ring-red-400");
      error = true;
    }

    if (form.pla === '') {
      $inputPla.classList.add("ring-2");
      $inputPla.classList.add("ring-red-400");
      error = true;
    }

    if (form.nulos === '') {
      $inputNulos.classList.add("ring-2");
      $inputNulos.classList.add("ring-red-400");
      error = true;
    }

    if (error) {
      toast.error('Faltan campos por capturar', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
    } else {
      store();
    }

  };


  /**************** loadDistritosLocales ******************************************************************************* */
  const loadDistritosLocales = async (df) => {

    /** Carga el select de distrito local */
    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getDistritosLocalesFiltrado/" + valorEscenario + "/" + df, settingsWithoutBody(token, 'GET'));
    const respDistritosLocales = await resp.json();

    let $selectDistritoFederal = document.getElementById('distritoLocal');
    $selectDistritoFederal.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectDistritoFederal.appendChild($option);

    if (respDistritosLocales.padron) {
      respDistritosLocales.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.distrito_local;
        $option.innerText = el.distrito_local;
        $selectDistritoFederal.appendChild($option);
      });
    }

  };


  /**************** loadSecciones ******************************************************************************* */
  const loadSecciones = async (dl) => {
    /** Carga el select de seccion */

    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getSeccionesFiltrado/" + valorEscenario + "/" + dl, settingsWithoutBody(token, 'GET'));
    const respSecciones = await resp.json();

    let $selectSeccion = document.getElementById('seccion');
    $selectSeccion.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectSeccion.appendChild($option);

    if (respSecciones.padron) {
      respSecciones.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.seccion;
        $option.innerText = el.seccion;
        $selectSeccion.appendChild($option);
      });
    }

  };


  /**************** loadCasillas ******************************************************************************* */
  const loadCasillas = async (seccion) => {
    /** Carga el select de casillas */

    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getCasillasFiltrado/" + valorEscenario + "/" + seccion, settingsWithoutBody(token, 'GET'));
    const respCasillas = await resp.json();

    let $selectCasillas = document.getElementById('casilla');
    $selectCasillas.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectCasillas.appendChild($option);

    if (respCasillas.padron) {
      respCasillas.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.casilla;
        $option.innerText = el.casilla;
        $selectCasillas.appendChild($option);
      });
    }

  };


  /**************** handleChange ******************************************************************************* */
  const handleChange = (e) => {
    /** Actualización de los campos (lo va guardando en la variable form) */
    
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });

    let $divTotales = document.getElementById('div-totales');

    if (e.target.id === 'distritoFederal') {
      if (e.target.value !== '-1') {
        loadDistritosLocales(e.target.value);
        document.getElementById('div-dl').classList.remove('hidden');
        document.getElementById('div-seccion').classList.add('hidden');
        document.getElementById('div-casilla').classList.add('hidden');
        $divTotales.classList.add('hidden');
      } else {
        document.getElementById('div-dl').classList.add('hidden');
        document.getElementById('div-seccion').classList.add('hidden');
        document.getElementById('div-casilla').classList.add('hidden');
        $divTotales.classList.add('hidden');
      }
    }

    if (e.target.id === 'distritoLocal') {
      if (e.target.value !== '-1') {
        loadSecciones(e.target.value);
        document.getElementById('div-seccion').classList.remove('hidden');
        document.getElementById('div-casilla').classList.add('hidden');
        $divTotales.classList.add('hidden');
      } else {
        document.getElementById('div-seccion').classList.add('hidden');
        document.getElementById('div-casilla').classList.add('hidden');
        $divTotales.classList.add('hidden');
      }
    }

    if (e.target.id === 'seccion') {
      if (e.target.value !== '-1') {
        loadCasillas(e.target.value);
        document.getElementById('div-casilla').classList.remove('hidden');
        $divTotales.classList.add('hidden');
      } else {
        document.getElementById('div-casilla').classList.add('hidden');
        $divTotales.classList.add('hidden');
      }
    }

    if (e.target.id === 'casilla') {
      if (e.target.value !== '-1') {
        getPartidosVisibles();
        $divTotales.classList.remove('hidden');
        //setMuestraBotonGuardar(true);
      } else {
        $divTotales.classList.add('hidden');
      }
    }
  };


  /**************** getPartidosVisibles ******************************************************************************* */
  const getPartidosVisibles = async() => {

    /* Obtiene la lista de partidos visibles */
    const token = sessionStorage.getItem("token");
    const response = await fetch(ApiUrl().url + "partidos/get/" + escenario, settingsWithoutBody(token, 'GET'));
    const data = await response.json();

    data.partidos.forEach(el => {

      if (el.partido === "pan" && (el.visible === "1" || el.visible === 1))
        document.getElementById("div-pan").classList.remove("hidden");
      
      if (el.partido === "pri" && (el.visible === "1" || el.visible === 1))
        document.getElementById("div-pri").classList.remove("hidden");
      
      if (el.partido === "prd" && (el.visible === "1" || el.visible === 1))
        document.getElementById("div-prd").classList.remove("hidden");
    
      if (el.partido === "pt" && (el.visible === "1" || el.visible === 1))
        document.getElementById("div-pt").classList.remove("hidden");

      if (el.partido === "verde" && (el.visible === "1" || el.visible === 1))
        document.getElementById("div-verde").classList.remove("hidden");
  
      if (el.partido === "mc" && (el.visible === "1" || el.visible === 1))
        document.getElementById("div-mc").classList.remove("hidden");

      if (el.partido === "morena" && (el.visible === "1" || el.visible === 1))
        document.getElementById("div-morena").classList.remove("hidden");

      if (el.partido === "pes" && (el.visible === "1" || el.visible === 1))
        document.getElementById("div-pes").classList.remove("hidden");

      if (el.partido === "fsm" && (el.visible === "1" || el.visible === 1))
        document.getElementById("div-fsm").classList.remove("hidden");

      if (el.partido === "rsp" && (el.visible === "1" || el.visible === 1))
        document.getElementById("div-rsp").classList.remove("hidden");
       
      if (el.partido === "pla" && (el.visible === "1" || el.visible === 1))
        document.getElementById("div-pla").classList.remove("hidden");

    });
  };


  /**************** return ******************************************************************************* */
  return (
    <>
      <div className="py-6 px-2 bg-gray-100">
        <ToastContainer />

        <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-center">Captura el Número de Votos</h1>

        <ToastContainer />

        <form>
          <div className="md:px-24 px-2 md:py-6 py-2 grid md:grid-cols-12 gap-3">

            <div className="md:col-span-3">
              <label htmlFor="distritoFederal" className="text-gray-600">Distrito Federal</label>
              <select onChange={handleChange} id="distritoFederal" name="distritoFederal" className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
              </select>
            </div>

            <div id="div-dl" className="hidden md:col-span-3">
              <label htmlFor="distritoLocal" className="text-gray-600">Distrito Local</label>
              <select onChange={handleChange} id="distritoLocal" name="distritoLocal" className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
              </select>
            </div>

            <div id="div-seccion" className="hidden md:col-span-3">
              <label htmlFor="seccion" className="text-gray-600">Sección</label>
              <select onChange={handleChange} id="seccion" name="seccion" className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
              </select>
            </div>

            <div id="div-casilla" className="hidden md:col-span-3">
              <label htmlFor="casilla" className="text-gray-600">Casilla</label>
              <select onChange={handleChange} id="casilla" name="casilla" className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
              </select>
            </div>

            <div id="div-totales" className="hidden md:col-span-12">

              <div className="md:col-span-12 md:mt-20 mt-6 hidden" id="div-pan">
                <div className='flex place-items-center'>
                  <label htmlFor="casilla" className="text-gray-600 mr-2 w-16">PAN</label>
                  <input onChange={handleChange} value={form.pan} type="text" id="pan" name="pan" autoComplete="true" className="-mt-1 w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>
              </div>

              <div className="md:col-span-12 mt-6 hidden" id="div-pri">
                <div className='flex place-items-center'>
                  <label htmlFor="casilla" className="text-gray-600 mr-2 w-16">PRI</label>
                  <input onChange={handleChange} value={form.pri} type="text" id="pri" name="pri" autoComplete="true" className="-mt-1 w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>
              </div>

              <div className="md:col-span-12 mt-6 hidden" id="div-prd">
                <div className='flex place-items-center'>
                  <label htmlFor="casilla" className="text-gray-600 mr-2 w-16">PRD</label>
                  <input onChange={handleChange} value={form.prd} type="text" id="prd" name="prd" autoComplete="true" className="-mt-1 w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>
              </div>

              <div className="md:col-span-12 mt-6 hidden" id="div-pt">
                <div className='flex place-items-center'>
                  <label htmlFor="casilla" className="text-gray-600 mr-2 w-16">PT</label>
                  <input onChange={handleChange} value={form.pt} type="text" id="pt" name="pt" autoComplete="true" className="-mt-1 w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>
              </div>

              <div className="md:col-span-12 mt-6 hidden" id="div-verde">
                <div className='flex place-items-center'>
                  <label htmlFor="casilla" className="text-gray-600 mr-2 w-16">Verde</label>
                  <input onChange={handleChange} value={form.verde} type="text" id="verde" name="verde" autoComplete="true" className="-mt-1 w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>
              </div>

              <div className="md:col-span-12 mt-6 hidden" id="div-mc">
                <div className='flex place-items-center'>
                  <label htmlFor="casilla" className="text-gray-600 mr-2 w-16">MC</label>
                  <input onChange={handleChange} value={form.mc} type="text" id="mc" name="mc" autoComplete="true" className="-mt-1 w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>
              </div>

              <div className="md:col-span-12 mt-6 hidden" id="div-morena">
                <div className='flex place-items-center'>
                  <label htmlFor="casilla" className="text-gray-600 mr-2 w-16">Morena</label>
                  <input onChange={handleChange} value={form.morena} type="text" id="morena" name="morena" autoComplete="true" className="-mt-1 w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>
              </div>

              <div className="md:col-span-12 mt-6 hidden" id="div-pes">
                <div className='flex place-items-center'>
                  <label htmlFor="casilla" className="text-gray-600 mr-2 w-16">PES</label>
                  <input onChange={handleChange} value={form.pes} type="text" id="pes" name="pes" autoComplete="true" className="-mt-1 w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>
              </div>

              <div className="md:col-span-12 mt-6 hidden" id="div-fsm">
                <div className='flex place-items-center'>
                  <label htmlFor="casilla" className="text-gray-600 mr-2 w-16">FSM</label>
                  <input onChange={handleChange} value={form.fsm} type="text" id="fsm" name="fsm" autoComplete="true" className="-mt-1 w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>
              </div>

              <div className="md:col-span-12 mt-6 hidden" id="div-rsp">
                <div className='flex place-items-center'>
                  <label htmlFor="casilla" className="text-gray-600 mr-2 w-16">RSP</label>
                  <input onChange={handleChange} value={form.rsp} type="text" id="rsp" name="rsp" autoComplete="true" className="-mt-1 w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>
              </div>

              <div className="md:col-span-12 mt-6 hidden" id="div-pla">
                <div className='flex place-items-center'>
                  <label htmlFor="casilla" className="text-gray-600 mr-2 w-16">PLA</label>
                  <input onChange={handleChange} value={form.pla} type="text" id="pla" name="pla" autoComplete="true" className="-mt-1 w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>
              </div>

              <div className="md:col-span-12 mt-6" id="div-nulos">
                <div className='flex place-items-center'>
                  <label htmlFor="casilla" className="text-gray-600 mr-2 w-16">NULOS</label>
                  <input onChange={handleChange} value={form.nulos} type="text" id="nulos" name="nulos" autoComplete="true" className="-mt-1 w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>
              </div>

              <div className="md:col-span-12 mt-6" id="div-btn-guardar">
                <span onClick={hanldeAccept}>
                  <BtnAceptarLargo text={"Guardar"} />
                </span>
              </div>

            </div>


          </div>
        </form>
      </div>
    </>

  );
}

export default BingoPrepCaptura;