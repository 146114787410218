/**
 * @file FormProgramasBeneficiarios.js
 * @brief Formulario para asignar programas de apoyo al padron de intención del voto.
 * @author Efraín Gourcy
 * @modified May 23, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { contextUserPermissions } from '../../App';
import BtnAceptar from '../../components/BtnAceptar';
import Loader from '../../components/Loader';
import { ApiUrl, settingsWithoutBody, settingsWithBody } from '../../helpers/ApiUrl';

const FormProgramasBeneficarios = () => {

  /** Variables */
  let { id } = useParams();
  const [datosPadron, setDatosPadron] = useState({});
  const { escenario } = useContext(contextUserPermissions);
  const [programas, setProgramas] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {userPermissions} = useContext(contextUserPermissions);


  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Valida los permisos para el módulo de permisos */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "asignar/programas") {
          if (el.permiso == "editar")
            permission = true;
        }
      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);


  /**************** useEffect [programas] ******************************************************************************* */
  useEffect(() => {
    /** Cargar la lista de programas y busca los programas que tiene el usuario del padron */
    if (Object.keys(programas).length > 0) {
      
      setLoading(true);

      let token = sessionStorage.getItem("token");
      fetch(ApiUrl().url + "programas/padron/get/" + id, settingsWithoutBody(token, 'GET'))
        .then(data => data.json())
        .then(data => {

          let $divEl;
          let $element;
          let $label;
          let $permisos = document.getElementById("div-programas");
          $permisos.innerHTML = "";

          let $div = document.createElement("div");
          $div.classList.add("grid", "grid-cols-2", "md:grid-cols-3", "gap-4", "items-center", "mb-2");

          programas.forEach(el => {

            $divEl = document.createElement("div");
            $element = document.createElement("input");
            $element.type = "checkbox";
            $element.name = el.id;
            $element.value = el.id;
            $element.id = el.id;
            $element.classList.add("cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

            if (data.programas) {
              data.programas.forEach(elProgramas => {

                if (parseInt(elProgramas.programa) === parseInt(el.id))
                  $element.checked = true;

              });
            }

            $label = document.createElement("label");
            $label.htmlFor = el.id;
            $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
            $label.appendChild(document.createTextNode(el.programa));

            $divEl.appendChild($element);
            $divEl.appendChild($label);
            $div.appendChild($divEl);

            $permisos.appendChild($div);

          });

        });

      setLoading(false);
    }

  }, [programas]);


  /**************** useEffect [escenario] ******************************************************************************* */
  useEffect(() => {
    /** Obtienen los programas de acuerdo al escenario */
    if (escenario !== null) {
      let token = sessionStorage.getItem("token");
      fetch(ApiUrl().url + "programas/getConEscenario/" + escenario, settingsWithoutBody(token, 'GET'))
        .then(data => data.json())
        .then(data => {
          setProgramas(data.programas);
        });
    }

  }, [escenario]);


  /**************** useEffect [id] ******************************************************************************* */
  useEffect(() => {
    /** Obtiene los datos del usuario del padron */
    const getData = async () => {

      let token = sessionStorage.getItem("token");
      let response = await fetch(ApiUrl().url + "padron/getPadron/" + id, settingsWithoutBody(token, 'GET'));
      const respPadron = await response.json();

      if (respPadron.status === "success" && respPadron.padron.length > 0)
        setDatosPadron(respPadron.padron[0]);
      else
        navigate('/programasBeneficiarios');

      setLoading(false);
    };

    if (parseInt(id) !== 0) {
      setLoading(true);
      getData();
    }

  }, [id]);


  /**************** store ******************************************************************************* */
  const store = async (programa) => {
    /** Guarda el programa */
    let token = sessionStorage.getItem("token");
    let data = {
      padron: id,
      programa: programa
    }

    await fetch(ApiUrl().url + "programas/padron/agrega", settingsWithBody(token, data, 'POST'));

  };


  /**************** handleAccept ******************************************************************************* */
  const handleAccept = async (e) => {
    /** Boton aceptar */
    setLoading(true);
    let token = sessionStorage.getItem("token");
    fetch(ApiUrl().url + "programas/padron/eliminar", settingsWithBody(token, { padron: id }, 'POST'))
      .then(data => data.json())
      .then(data => {
        if (data.status === "success") {

          let $elements = document.getElementById('div-programas').getElementsByTagName('input');

          for (var i = 0; i < $elements.length; i++) {
            if ($elements[i].checked === true)
              store($elements[i].id);
          }

          navigate("/programasBeneficiarios");

        } else
          setLoading(false);
        
      });
    
  };


  /**************** return ******************************************************************************* */
  return (
    <>
      <div className="py-12 h-screen bg-gray-300">
        <div className="max-w-12xl mx-12 bg-white rounded-xl shadow-md overflow-hidden md:max-w-12xl">
          <div className="md:flex">
            <div className="w-full p-2 py-10">

              <div className="flex justify-center">
                <div className="relative">

                  <img src={`https://ui-avatars.com/api/?name=${datosPadron.nombre}+${datosPadron.paterno}=&background=cbd5e1&color=ffffff`} className="rounded-full" width="80" />
                  <span className="absolute border-white border-4 h-5 w-5 top-12 left-16 bg-green-700 rounded-full"></span>

                </div>

              </div>

              <div className="flex flex-col text-center mt-3 mb-4">

                <span className="text-2xl font-medium">{datosPadron.clave_electoral}</span>
                <span className="text-md text-gray-400">{datosPadron.nombre} {datosPadron.paterno} {datosPadron.materno}</span>

              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 px-20">
                <div className="text-left">
                  <span>Calle: </span>{datosPadron.calle}
                </div>
                <div className="text-left">
                  <span>Interior: </span>{datosPadron.interior}
                </div>
                <div className="text-left">
                  <span>Exterior: </span>{datosPadron.exterior}
                </div>
                <div className="text-left">
                  <span>Colonia: </span>{datosPadron.colonia}
                </div>
                <div className="text-left">
                  <span>Municipio: </span>{datosPadron.municipio}
                </div>
                <div className="text-left">
                  <span>Distrito Local: </span>{datosPadron.distrito_local}
                </div>
                <div className="text-left">
                  <span>Distrito Federal: </span>{datosPadron.distrito_federal}
                </div>
                <div className="text-left">
                  <span>Teléfono Móvil: </span>{datosPadron.telefono_movil}
                </div>
              </div>

              <div className="mt-16 px-16 mt-3 text-center">
                <span className="bg-gray-100 h-5 p-1 px-3 rounded cursor-pointer hover:shadow">{datosPadron.puesto}</span>
              </div>

              <div id="div-programas" className="mt-16 px-16 mt-3 text-letf">
              </div>

              <div className="px-14 mt-16 text-right">

                {!loading
                  ? <>
                    <span onClick={handleAccept}>
                      <BtnAceptar text={"Guardar"} />
                    </span>
                  </>
                  : <>
                    <Loader />
                  </>
                }

              </div>

            </div>

          </div>
        </div>

      </div>
    </>
  );
}

export default FormProgramasBeneficarios;