/**
 * @file Ver.js
 * @brief Ver un registro del padron.
 * @author Efraín Gourcy
 * @modified Ene 27, 2023
 */

import React, { useContext, useEffect, useState, useRef } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { ApiUrl, settingsWithoutBody } from '../../helpers/ApiUrl';
import BtnAceptar from '../../components/BtnAceptar';
//import ControlledExpandedNode from '../../components/Arbol';
import { contextUserPermissions } from '../../App';
//import { element } from 'prop-types';
import Arbol2 from '../../components/Arbol2';
import { useReactToPrint } from 'react-to-print';

const Ver = () => {

  /** Variables */
  const { userPermissions, escenario } = useContext(contextUserPermissions);
  let { id } = useParams();
  const [datosPadron, setDatosPadron] = useState({});
  let url = ApiUrl().url + "padron/get/" + escenario;

  const [padron, setPadron] = useState({});
  const [dataArbol, setDataArbol] = useState({});
  let datos = {};
  let cadenaDatos = "";

  /**************** Imprimir Arbol ******************************************************************************* */
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  /**************** useEffect [id] ******************************************************************************* */
  useEffect(() => {

    const getData = async () => {

      let token = sessionStorage.getItem("token");
      const response = await fetch(ApiUrl().url + "padron/getPadron/" + id, settingsWithoutBody(token, 'GET'));
      const respPadron = await response.json();

      if (respPadron.status === "success")
        setDatosPadron(respPadron.padron[0]);
    
      
    };

    if (parseInt(id) !== 0)
      getData();

  }, [id])

  const getNodesUp = (data,id,puesto,usuarioDependeEstructura) => {

    let nombre = "";
    let paterno = "";
    let materno = "";
    let childrenName = "";
    let idBuscar = id;
    let data2 = data;
    let nombreActual = "";
    let paternoActual = "";
    let maternoActual = "";
    let puestoEstructura = "";

    data.forEach(element => {
      if (element.id == usuarioDependeEstructura) {

        if (element.usuario_depende_estructura != 0) {
          
          // Agregar nodo padre (Lider)
          data2.forEach(el => {
            if (el.id == element.usuario_depende_estructura) {
              cadenaDatos = '{"name":"", "children":[ {"name":"' + el.puesto + ' - ' + el.nombre + ' ' + el.paterno + ' ' + el.materno + '"}';
            }
          });
          
          idBuscar = element.id;
          
          //cadenaDatos = '{"name":"", "children":[ {"name":"' + element.puesto + ' - ' + element.nombre + ' ' + element.paterno + ' ' + element.materno + '"}';


        } else {
          
          cadenaDatos = '{"name":"", "children":[ {"name":"' + element.puesto + ' - ' + element.nombre + ' ' + element.paterno + ' ' + element.materno + '"}';
          
        }
        

        //cadenaDatos = '{"name":"", "children":[ {"name":"' + element.puesto + ' - ' + element.nombre + ' ' + element.paterno + ' ' + element.materno + '"}';



      }

      if (element.id == idBuscar) {
        nombre = element.nombre;
        paterno = element.paterno;
        materno = element.materno;
        puestoEstructura = element.puesto;
      }

      if (element.id == id) {
        nombreActual = element.nombre;
        paternoActual = element.paterno;
        maternoActual = element.materno;
      }

    });

    // Aqui van los hijos de este puesto
    let entra = 0;
    data2.forEach(el => {
      if (el.usuario_depende_estructura == idBuscar) {
        if (idBuscar == id) {
          childrenName = childrenName + '{"name":"' + el.puesto + ' - ' + el.nombre + ' ' + el.paterno + ' ' + el.materno + '"},';
          entra = 1;
        }
      }
    });

    if (entra == 0) {
      childrenName = childrenName + '{"name":"' + puesto + ' - ' + nombreActual + ' ' + paternoActual + ' ' + maternoActual + '"},';
    }

    childrenName = childrenName.substring(0, childrenName.length - 1);
    
    // Este es el puesto del usuario actual
    cadenaDatos = cadenaDatos + ',{ "name": "' + puestoEstructura + '' + ' - ' + nombre + ' ' + paterno + ' ' + materno + '" , "children":[ '+ childrenName +' ]}';
    
    //cadenaDatos ='{"name":"", "children":[ {"name":"'+puesto+'"}';

    //console.log(data,id,puesto,usuarioDependeEstructura);
    //getNodesDown(data,id);
  }

  const getNodesDown = async(data,id) => {
    // Aqui voy seleccionar los dependientes en la estructura; asi como los dependeintes de cada uno de estos (parea que se genere el arbol)

    //console.log(data);

    let data2 = data;

    data.forEach(element => {
      //console.log(element);
      if (element.usuario_depende_estructura == id) {
        //console.log(element);

        let childrenName = "";

        data2.forEach(el => {
          
          if (el.usuario_depende_estructura == element.id) {

            childrenName = childrenName + '{"name":"' + el.puesto + ' - ' + el.nombre + ' ' + el.paterno + ' ' + el.materno + '"},';

          }

        });

        childrenName = childrenName.substring(0, childrenName.length - 1);
        //console.log("hijos: ",childrenName);

        cadenaDatos = cadenaDatos + ',{ "name": "'+ element.puesto + ' - ' + element.nombre + ' ' + element.paterno + ' ' + element.materno + '" , "children":[ '+ childrenName +' ]}';
      }
    });


    /*
    cadenaDatos = cadenaDatos + ',{ "name": "'+ element.puesto + ' ' + element.nombre + ' ' + element.paterno + ' ' + element.materno + '" , "children":[ {"name":"negros1"} ]}';
    cadenaDatos = cadenaDatos + ',{ "name": "Movilizado2" , "children":[ {"name":"negros2"} ]}'
    cadenaDatos = cadenaDatos + ',{ "name": "Movilizado3" , "children":[ {"name":"negros3"} ]}'
    */
  }

  /**************** useEffect (url) ******************************************************************************* */
  useEffect(() => {
    /** Carga el grid de padron */
    //setLoading(true);

    let valueToken = sessionStorage.getItem("token");
    

    fetch(url, settingsWithoutBody(valueToken, 'GET'))
      .then(res => res.json())
      .then(data => {
        if (data.padron) {

          setPadron(data.padron);

          //console.log(data.padron);
          //console.log(id);

          data.padron.forEach(element => {

            if (element.id == id) {
              //console.log(element);
              //console.log("Puesto: ", element.puesto);
              //console.log("Depende: ", element.usuario_depende_estructura);

              if (element.usuario_depende_estructura != 0) {
                getNodesUp(data.padron,id,element.puesto,element.usuario_depende_estructura);
              } else {
                cadenaDatos = '{"name":"", "children":[ {"name":"'+ element.puesto + ' - ' + element.nombre + ' ' + element.paterno + ' ' + element.materno + '"}';
                getNodesDown(data.padron,id);
              }

              


              cadenaDatos = cadenaDatos + ']}';
              //console.log(cadenaDatos);
              datos = JSON.parse(cadenaDatos);
              //console.log(datos);
            }
          });

          /*datos = {
            name: "",
            children: [
              {
                name: "Movilizador Pedro",
                children: [
                  { name: "Movilizado Pablo" },
                  { name: "Esther" },
                  { name: "Juana" },
                  {
                    name: "Movilizado",
                    children: [
                      { name: "Saul" },
                      { name: "Guillermo" },
                      { name: "Olga" },
                      { name: "Lorena" },
                      {
                        name: "Movilizado",
                        children: [
                          { name: "Saul" },
                          { name: "Guillermo" },
                          { name: "Olga" },
                          { name: "Lorena" },
                        ],
                      },
                    ],
                  },
                ],
              }
            ],
          }
          */

          //setPadron(datos);
          setDataArbol(datos);


        }
      });


  }, [url]);


  /**************** return ******************************************************************************* */
  return (
    <>
      <div className="py-12 h-screen bg-gray-300">
        <div className="max-w-12xl mx-12 bg-white rounded-xl shadow-md overflow-hidden md:max-w-12xl">
          <div className="md:flex">
            <div className="w-full p-2 py-10">

              <div className="flex justify-center">
                <div className="relative">

                  <img src={`https://ui-avatars.com/api/?name=${datosPadron.nombre}+${datosPadron.paterno}=&background=cbd5e1&color=ffffff`} className="rounded-full" width="80" />
                  <span className="absolute border-white border-4 h-5 w-5 top-12 left-16 bg-green-700 rounded-full"></span>

                </div>

              </div>

              <div className="flex flex-col text-center mt-3 mb-4">

                <span className="text-2xl font-medium">{datosPadron.clave_electoral}</span>
                <span className="text-md text-gray-400">{datosPadron.nombre} {datosPadron.paterno} {datosPadron.materno}</span>

              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 px-20">
                <div className="text-left">
                  <span>Calle: </span>{datosPadron.calle}
                </div>
                <div className="text-left">
                  <span>Interior: </span>{datosPadron.interior}
                </div>
                <div className="text-left">
                  <span>Exterior: </span>{datosPadron.exterior}
                </div>
                <div className="text-left">
                  <span>Colonia: </span>{datosPadron.colonia}
                </div>
                <div className="text-left">
                  <span>Municipio: </span>{datosPadron.municipio}
                </div>
                <div className="text-left">
                  <span>Distrito Local: </span>{datosPadron.distrito_local}
                </div>
                <div className="text-left">
                  <span>Distrito Federal: </span>{datosPadron.distrito_federal}
                </div>
                <div className="text-left">
                  <span>Teléfono Móvil: </span>{datosPadron.telefono_movil}
                </div>
              </div>

              <div className="mt-16 px-16 mt-3 text-center">
                <span className="bg-gray-100 h-5 p-1 px-3 rounded cursor-pointer hover:shadow">{datosPadron.puesto}</span>
              </div>

              {/*
              <div className="mt-16 text-center">
                <ControlledExpandedNode padron={dataArbol} />
              </div>
              */}

              <div className="mt-16 text-center" ref={contentToPrint}>
                <Arbol2 idPadron={id} />
              </div>
              
              <div className="px-14 mt-16 text-right">

                <NavLink to="/intencion/consultar">
                  <BtnAceptar text={"Regresar"} />
                </NavLink>

                <button className="ml-6 px-6 py-2 text-white font-bold bg-[#3c70b9] rounded-lg hover:bg-[#81a3d1] ring-2 ring-blue-400 focus:outline-none" onClick={() => {
                  handlePrint(null, () => contentToPrint.current);
                  }}>
                  Imprimir
                </button>

              </div>

            </div>

          </div>
        </div>

      </div>
    </>
  );
}

export default Ver;