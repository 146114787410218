/**
 * @file Form.js
 * @brief Formulario de permisos (Nuevo/Editar).
 * @author Efraín Gourcy
 * @modified Mar 24, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import BtnAceptar from '../../components/BtnAceptar';
import BtnCancelar from '../../components/BtnCancelar';
import Loader from '../../components/Loader';
import { ApiUrl, settingsWithBody, settingsWithoutBody } from '../../helpers/ApiUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contextUserPermissions } from "../../App";

/** Valores iniciales form */
const initalValues = {
  modulo: "",
  permiso: ""
}

const PermisosForm = () => {

  /** Variables */
  let { id } = useParams();
  let navigate = useNavigate();
  const [form, setForm] = useState(initalValues);
  const [loading, setLoading] = useState(false);
  const {userPermissions} = useContext(contextUserPermissions);


  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Valida los permisos para el módulo de permisos */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "permisos") {
          if (el.permiso === "nuevo" || el.permiso == "editar")
            permission = true;
        }
      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);


  /**************** useEffect[id] ******************************************************************************* */
  useEffect(() => {
    /** Carga la información del permiso (Modo Edicion) */
    if (parseInt(id) !== 0) {

      const searchUser = async () => {

        let valueToken = sessionStorage.getItem("token");

        try {

          let url = ApiUrl().url + "permisos/getPermiso/" + id;
          const response = await fetch(url, settingsWithoutBody(valueToken, 'GET'));
          const respPermiso = await response.json();

          if (respPermiso.permiso[0]) {

            setForm({
              ...form,
              modulo: respPermiso.permiso[0].modulo,
              permiso: respPermiso.permiso[0].permiso
            });
          } else {
            navigate('/permisos');
          }

        } catch (err) {
          console.log(err);
        }
      };

      searchUser();

    }
  }, [id]);


  /**************** store ******************************************************************************* */
  const store = async() => {
    /** Guarda / Actualiza el permiso */
    setLoading(true);

    let valueToken = sessionStorage.getItem("token");
    let url = ApiUrl().url;

    let generalError = document.getElementById("generalError");

    try {

      if (parseInt(id) === 0)
        url = url + "permisos/store";
      else
        url = url + "permisos/edit/" + id;

      const response = await fetch(url, settingsWithBody(valueToken, form, 'POST'));
      const respPermisos = await response.json();

      if (respPermisos.status === "success")
        return navigate("/permisos");

      if (respPermisos.message) {
        generalError.innerText = "Campos incorrectos";
        generalError.classList.remove("invisible");
      }

    } catch (err) {
      generalError.innerText = "Error de comunicación con el servidor";
      generalError.classList.remove("invisible");
      console.log(err);
    }

    setLoading(false);

  };


  /**************** handleAccept ******************************************************************************* */
  const handleAccept = (e) => {
    /** Clic al botón de aceptar */
    e.preventDefault();
    let error = false;

    let inputModulo = document.getElementById("modulo");
    let inputPermiso = document.getElementById("permiso");

    if (form.modulo === "") {
      inputModulo.classList.add("ring-2");
      inputModulo.classList.add("ring-red-400");
      toast.error('No ha introducido un modulo', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
      error = true;
    } else {
      inputModulo.classList.remove("ring-2");
      inputModulo.classList.remove("ring-red-400");
    }

    if (form.permiso === "") {
      inputPermiso.classList.add("ring-2");
      inputPermiso.classList.add("ring-red-400");
      toast.error('No ha introducido un permiso', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
      error = true;
    } else {
      inputPermiso.classList.remove("ring-2");
      inputPermiso.classList.remove("ring-red-400");
    }

    if (!error)
      store();
  };


  /**************** handleChange ******************************************************************************* */
  const handleChange = (e) => {
    /** Actualización de los campos del formulario */
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };


  /**************** return ******************************************************************************* */
  return (
    <>
      <ToastContainer autoClose={2000} />
      <div className="py-6 px-2">

      <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-center">Crea un Nuevo Permiso</h1>

        <div className="grid md:grid-cols-4 gap-3">

          <div className="md:col-start-2 md:col-span-2 bg-gray-100 rounded-lg p-2">

            <form>
              <div className="px-2 py-2 grid md:grid-cols-4 gap-2">

                <div className="col-span-4 p-1">
                  <label htmlFor="modulo" className="text-gray-600">Módulo</label>
                  <input type="text" id="modulo" name="modulo" autoComplete="true" value={form.modulo} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>

                <div className="col-span-4 p-1">
                  <label htmlFor="permiso" className="text-gray-600">Permiso</label>
                  <input type="text" id="permiso" name="permiso" autoComplete="true" value={form.permiso} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border border-gray-300 text-gray-600" />
                </div>

                <div className="p-1 col-span-4 md:justify-self-end">
                  {loading
                    ? <Loader />
                    : <>
                      <span onClick={handleAccept} className="mr-4"><BtnAceptar text={"Guardar"} /></span>

                      <NavLink to="/permisos">
                        <BtnCancelar text={"Cancelar "} />
                      </NavLink>
                    </>
                  }

                </div>

              </div>
            </form>

          </div>

        </div>

      </div>
    </>
  );
}

export default PermisosForm;