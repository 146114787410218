import React, { useContext, useEffect, useState } from 'react';
import { ApiUrl, settingsWithoutBody } from '../../helpers/ApiUrl';
import { contextUserPermissions } from '../../App';
import ReactECharts from 'echarts-for-react';

const IPTD = () => {

  const { userPermissions, escenario } = useContext(contextUserPermissions);
  //const [totalPadron, setTotalPadron] = useState(0);

  const [labelDf, setLabelDf] = useState(0);
  const [labelSeccion, setLabelSeccion] = useState(0);
  const [labelCasilla, setLabelCasilla] = useState(0);
  const [porcentajePadronGraficaDF1, setPorcentajePadronGraficaDF1] = useState(0);
  const [porcentajeAvanceVotosGraficaDF1, setPorcentajeAvanceVotosGraficaDF1] = useState(0);
  const [porcentajePadronGraficaSeccion, setPorcentajePadronGraficaSeccion] = useState(0);
  const [porcentajeAvanceVotosGraficaSeccion, setPorcentajeAvanceVotosGraficaSeccion] = useState(0);
  const [porcentajePadronGraficaCasilla, setPorcentajePadronGraficaCasilla] = useState(0);
  const [porcentajeAvanceVotosGraficaCasilla, setPorcentajeAvanceVotosGraficaCasilla] = useState(0);
  const [totalRegistrosIntencionGraficaDF, setTotalRegistrosIntencionGraficaDF] = useState(0);
  const [totalRegistrosPadronGraficaDF, setTotalRegistrosPadronGraficaDF] = useState(0);
  const [totalRegistrosIntencionGraficaSeccion, setTotalRegistrosIntencionGraficaSeccion] = useState(0);
  const [totalRegistrosPadronGraficaSeccion, setTotalRegistrosPadronGraficaSeccion] = useState(0);
  const [totalRegistrosIntencionGraficaCasilla, setTotalRegistrosIntencionGraficaCasilla] = useState(0);
  const [totalRegistrosPadronGraficaCasilla, setTotalRegistrosPadronGraficaCasilla] = useState(0);

  const optionDF1 = {
    title: {
      text: 'Padron',
      subtext: 'Avance Votos Distrito Federal ' + labelDf,
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: '50%',
        data: [
          { value: porcentajePadronGraficaDF1, name: 'Faltante' },
          { value: porcentajeAvanceVotosGraficaDF1, name: 'Avance de Votos' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  const optionDF2 = {
    title: {
      text: 'Intención del Voto',
      subtext: 'Total de registros Distrito Federal ' + labelDf,
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: '50%',
        data: [
          { value: totalRegistrosIntencionGraficaDF, name: 'Avance de Votos' },
          { value: totalRegistrosPadronGraficaDF, name: 'Faltante' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };


  const optionSeccion1 = {
    title: {
      text: 'Padron',
      subtext: 'Avance Votos Sección ' + labelSeccion,
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: '50%',
        data: [
          { value: porcentajePadronGraficaSeccion, name: 'Faltante' },
          { value: porcentajeAvanceVotosGraficaSeccion, name: 'Avance de Votos' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  const optionSeccion2 = {
    title: {
      text: 'Intención del Voto',
      subtext: 'Total de registros Sección ' + labelSeccion,
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: '50%',
        data: [
          { value: totalRegistrosIntencionGraficaSeccion, name: 'Avance de votos' },
          { value: totalRegistrosPadronGraficaSeccion, name: 'Faltante' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  const optionCasilla1 = {
    title: {
      text: 'Padron',
      subtext: 'Avance Votos Sección ' + labelCasilla,
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        name: 'Avance de Votos %',
        type: 'pie',
        radius: '50%',
        data: [
          { value: porcentajePadronGraficaCasilla, name: 'Padron' },
          { value: porcentajeAvanceVotosGraficaCasilla, name: 'Avance de Votos' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  const optionCasilla2 = {
    title: {
      text: 'Intención del Voto',
      subtext: 'Total de registros Sección ' + labelCasilla,
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        name: 'Total de registros',
        type: 'pie',
        radius: '50%',
        data: [
          { value: totalRegistrosIntencionGraficaCasilla, name: 'Intención del voto' },
          { value: totalRegistrosPadronGraficaCasilla, name: 'Padron' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  /**************** useEffect [] ******************************************************************************* */
  useEffect(() => {
    /** Carga el select de distrito federal */
    const loadDistritosFederales = async () => {

      let valorEscenario = sessionStorage.getItem('escenario');
      if (escenario !== null)
        valorEscenario = escenario;

      let token = sessionStorage.getItem("token");
      const resp = await fetch(ApiUrl().url + "padron/getDistritosFederales/" + valorEscenario, settingsWithoutBody(token, 'GET'));
      const respDistritosFederales = await resp.json();

      let $selectDistritoFederal = document.getElementById('distritoFederal');
      $selectDistritoFederal.innerHTML = '';

      let $option = document.createElement('option');
      $option.value = '-1';
      $option.innerText = 'Selecciona una opción';
      $selectDistritoFederal.appendChild($option);

      if (respDistritosFederales.status === "success") {
        respDistritosFederales.padron.forEach(el => {
          let $option = document.createElement('option');
          $option.value = el.distrito_federal;
          $option.innerText = el.distrito_federal;
          $selectDistritoFederal.appendChild($option);
        });
      }
    };

    /*
    const getTotalPadron = async() => {
      let valorEscenario = sessionStorage.getItem('escenario');
      if (escenario !== null)
        valorEscenario = escenario;

      let totalVotos = 0;
      let padron = 0;

      let token = sessionStorage.getItem("token");
      const resp = await fetch(ApiUrl().url + "padronOficial/total/" + valorEscenario, settingsWithoutBody(token, 'GET'));
      const respPadron = await resp.json();

      if (respPadron) {
        padron = respPadron.total;
        setTotalPadron(respPadron.total);
      }

      resp = await fetch(ApiUrl().url + "padron/getTotalVotos/" + valorEscenario, settingsWithoutBody(token, 'GET'));
      const respPadronTotalVotos = await resp.json();

      if (respPadronTotalVotos) {
        totalVotos = respPadronTotalVotos.total;
      }

    };
    */

    loadDistritosFederales();
    //getTotalPadron();
  }, []);

  /**************** loadSecciones ******************************************************************************* */
  const loadSecciones = async (dl) => {
    /** Carga el select de seccion */

    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getSeccionesFiltrado/" + valorEscenario + "/" + dl, settingsWithoutBody(token, 'GET'));
    const respSecciones = await resp.json();

    let $selectSeccion = document.getElementById('seccion');
    $selectSeccion.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectSeccion.appendChild($option);

    if (respSecciones.padron) {
      respSecciones.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.seccion;
        $option.innerText = el.seccion;
        $selectSeccion.appendChild($option);
      });
    }

  };


  /**************** loadCasillas ******************************************************************************* */
  const loadCasillas = async (seccion) => {
    /** Carga el select de casillas */

    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getCasillasFiltrado/" + valorEscenario + "/" + seccion, settingsWithoutBody(token, 'GET'));
    const respCasillas = await resp.json();

    let $selectCasillas = document.getElementById('casilla');
    $selectCasillas.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectCasillas.appendChild($option);

    if (respCasillas.padron) {
      respCasillas.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.casilla;
        $option.innerText = el.casilla;
        $selectCasillas.appendChild($option);
      });
    }

  };


  /**************** loadGraficasDF ******************************************************************************* */
  const loadGraficasDF = async(df) => {

    setLabelDf(df);

    let totalPadron = 0;
    let totalVotos = 0;
    let votos = 0;
    
    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padronOficial/totalRegistrosDf/" + valorEscenario + "/" + df, settingsWithoutBody(token, 'GET'));
    const respTotal = await resp.json();
    
    if (respTotal)
      totalPadron = respTotal.total;
    
    const respVotos = await fetch(ApiUrl().url + "padron/getTotalVotosDf/" + valorEscenario + "/" + df, settingsWithoutBody(token, 'GET'));
    const respTotalVotos = await respVotos.json();

    if (respTotalVotos)
      totalVotos = respTotalVotos.total;

    if (totalPadron != 0)
      votos = (totalVotos * 100) / totalPadron;

    
    setPorcentajeAvanceVotosGraficaDF1(Number.parseFloat(votos).toFixed(2));
    setPorcentajePadronGraficaDF1(100 - Number.parseFloat(votos).toFixed(2));

    
    const respTotalPorDfIntencion = await fetch(ApiUrl().url + "padron/getTotalPorDistritoFederal/" + valorEscenario + "/" + df, settingsWithoutBody(token, 'GET'));
    const respTotalRegPorDfIntencion = await respTotalPorDfIntencion.json();

    if (respTotalRegPorDfIntencion) {

      let porcentajePadron = (totalVotos * 100)/respTotalRegPorDfIntencion.total;

      setTotalRegistrosIntencionGraficaDF(Number.parseFloat(porcentajePadron).toFixed(2));
      setTotalRegistrosPadronGraficaDF(100 - Number.parseFloat(porcentajePadron).toFixed(2));
    }

  };

  /**************** loadGraficasSeccion ******************************************************************************* */
  const loadGraficasSeccion = async(seccion) => {

    setLabelSeccion(seccion);

    let totalPadron = 0;
    let totalVotos = 0;
    let votos = 0;
    let df = document.getElementById('distritoFederal').value;

    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padronOficial/totalRegistrosDfSeccion/" + valorEscenario + "/" + df + "/" + seccion, settingsWithoutBody(token, 'GET'));
    const respTotal = await resp.json();

    if (respTotal) {
      totalPadron = respTotal.total;
    }

    const respVotos = await fetch(ApiUrl().url + "padron/getTotalVotosDfSeccion/" + valorEscenario + "/" + df + "/" + seccion, settingsWithoutBody(token, 'GET'));
    const respTotalVotos = await respVotos.json();

    if (respTotalVotos) {
      totalVotos = respTotalVotos.total;
    }

    if (totalPadron != 0) {
      votos = (totalVotos * 100) / totalPadron;
    }

    setPorcentajeAvanceVotosGraficaSeccion(Number.parseFloat(votos).toFixed(2));
    setPorcentajePadronGraficaSeccion(100 - Number.parseFloat(votos).toFixed(2));

    const respTotalPorDfSeccionIntencion = await fetch(ApiUrl().url + "padron/getTotalPorDistritoFederalSeccion/" + valorEscenario + "/" + df + "/" + seccion, settingsWithoutBody(token, 'GET'));
    const respTotalRegPorDfSeccionIntencion = await respTotalPorDfSeccionIntencion.json();

    if (respTotalRegPorDfSeccionIntencion.total) {

      let porcentajePadron = (totalVotos * 100)/respTotalRegPorDfSeccionIntencion.total;

      setTotalRegistrosIntencionGraficaSeccion(Number.parseFloat(porcentajePadron).toFixed(2));
      setTotalRegistrosPadronGraficaSeccion(100 - Number.parseFloat(porcentajePadron).toFixed(2));
    }

  };


  /**************** loadGraficasCasilla ******************************************************************************* */
  const loadGraficasCasilla = async(casilla) => {

    setLabelCasilla(casilla);
    let totalPadron = 0;
    let totalVotos = 0;
    let votos = 0;
    let df = document.getElementById('distritoFederal').value;
    let seccion = document.getElementById('seccion').value;

    
    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padronOficial/totalRegistrosDfSeccionCasilla/" + valorEscenario + "/" + df + "/" + seccion + "/" + casilla, settingsWithoutBody(token, 'GET'));
    const respTotal = await resp.json();
  
    if (respTotal) {
      totalPadron = respTotal.total;
    }

    const respVotos = await fetch(ApiUrl().url + "padron/getTotalVotosDfSeccionCasilla/" + valorEscenario + "/" + df + "/" + seccion + "/" + casilla, settingsWithoutBody(token, 'GET'));
    const respTotalVotos = await respVotos.json();

    if (respTotalVotos) {
      totalVotos = respTotalVotos.total;
    }

    if (totalPadron != 0) {
      votos = (totalVotos * 100) / totalPadron;
    }

    setPorcentajeAvanceVotosGraficaCasilla(Number.parseFloat(votos).toFixed(2));
    setPorcentajePadronGraficaCasilla(100 - Number.parseFloat(votos).toFixed(2));

    const respTotalPorDfSeccionCasillaIntencion = await fetch(ApiUrl().url + "padron/getTotalPorDistritoFederalSeccionCasilla/" + valorEscenario + "/" + df + "/" + seccion + "/" + casilla, settingsWithoutBody(token, 'GET'));
    const respTotalRegPorDfSeccionCasillaIntencion = await respTotalPorDfSeccionCasillaIntencion.json();

    if (respTotalRegPorDfSeccionCasillaIntencion) {
      let porcentajePadron = (totalVotos * 100)/respTotalRegPorDfSeccionCasillaIntencion.total;

      setTotalRegistrosIntencionGraficaCasilla(Number.parseFloat(porcentajePadron).toFixed(2));
      setTotalRegistrosPadronGraficaCasilla(100 - Number.parseFloat(porcentajePadron).toFixed(2));
    }

  };


  /**************** handleChange ******************************************************************************* */
  const handleChange = (e) => {

    if (e.target.id === 'distritoFederal') {
        if (e.target.value !== '-1') {
          loadSecciones(e.target.value);
          loadGraficasDF(e.target.value);
          document.getElementById('div-seccion').classList.remove('hidden');
          document.getElementById('div-grafica-df').classList.remove('hidden');
          document.getElementById('div-casilla').classList.add('hidden');
          document.getElementById('div-grafica-seccion').classList.add('hidden');
          document.getElementById('div-grafica-casilla').classList.add('hidden');
        } else {
          document.getElementById('div-grafica-df').classList.add('hidden');
          document.getElementById('div-seccion').classList.add('hidden');
          document.getElementById('div-casilla').classList.add('hidden');
          document.getElementById('div-grafica-seccion').classList.add('hidden');
          document.getElementById('div-grafica-casilla').classList.add('hidden');
        }
    }

    if (e.target.id === 'seccion') {
      if (e.target.value !== '-1') {
        loadCasillas(e.target.value);
        loadGraficasSeccion(e.target.value);
        document.getElementById('div-casilla').classList.remove('hidden');
        document.getElementById('div-grafica-seccion').classList.remove('hidden');
        document.getElementById('div-grafica-casilla').classList.add('hidden');
      } else {
        document.getElementById('div-casilla').classList.add('hidden');
        document.getElementById('div-grafica-seccion').classList.add('hidden');
        document.getElementById('div-grafica-casilla').classList.add('hidden');
        
      }
    }

    if (e.target.id === 'casilla') {
      if (e.target.value !== '-1') {
        loadGraficasCasilla(e.target.value);
        document.getElementById('div-grafica-casilla').classList.remove('hidden');
      } else {
        document.getElementById('div-grafica-casilla').classList.add('hidden');
      }
    }

  };


  /**************** return ******************************************************************************* */
  return (
    <>
      <div className="md:py-12 md:px-12 sm:py-3 sm:px-3 py-3 px-3">
        <div className="md:px-24 px-2 md:py-6 py-2 grid md:grid-cols-1 gap-3">

          <div className="md:col-span-12">
            <label htmlFor="distritoFederal" className="text-gray-600">Distrito Federal</label>
            <select onChange={handleChange} id="distritoFederal" name="distritoFederal" className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
            </select>

            <div className="hidden w-full mt-12 flex justify-center items-center" id="div-grafica-df">
              <ReactECharts 
                option={optionDF1}
                style={{ width: '100%', height: '400px', minHeight: '300px', minWidth: '300px'}}
              />

              <ReactECharts 
                option={optionDF2}
                style={{ width: '100%', height: '400px', minHeight: '300px', minWidth: '300px'}}
              />
            </div>
          </div>

          <div className="md:col-span-12 hidden" id="div-seccion">
            <label htmlFor="seccion" className="text-gray-600">Sección</label>
            <select onChange={handleChange} id="seccion" name="seccion" className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
            </select>
            <div className="hidden w-full mt-12 flex justify-center items-center" id="div-grafica-seccion">
              <ReactECharts 
                option={optionSeccion1}
                style={{ width: '100%', height: '400px', minHeight: '300px', minWidth: '300px'}}
              />

              <ReactECharts 
                option={optionSeccion2}
                style={{ width: '100%', height: '400px', minHeight: '300px', minWidth: '300px'}}
              />
            </div>
          </div>

          <div className="md:col-span-12 hidden" id="div-casilla">
            <label htmlFor="casilla" className="text-gray-600">Casilla</label>
            <select onChange={handleChange} id="casilla" name="casilla" className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
            </select>
            <div className="hidden w-full mt-12 flex justify-center items-center" id="div-grafica-casilla">
            <ReactECharts 
                option={optionCasilla1}
                style={{ width: '100%', height: '400px', minHeight: '300px', minWidth: '300px'}}
              />

              <ReactECharts 
                option={optionCasilla2}
                style={{ width: '100%', height: '400px', minHeight: '300px', minWidth: '300px'}}
              />
            </div>
          </div>



        </div>
      </div>

    </>
  );
}

export default IPTD;