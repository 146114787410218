/**
 * @file Bingo.js
 * @brief Administración del Bingo.
 * @author Efraín Gourcy
 * @modified Mar 27, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { contextUserPermissions } from '../../App';
import BtnAceptarLargo from '../../components/BtnAceptarLargo';
import Loader from '../../components/Loader';
import { ApiUrl, settingsWithBody, settingsWithoutBody } from '../../helpers/ApiUrl';
import { Bingo as BingoTot } from "../../helpers/Config";

const Bingo = () => {

  /** Variables */
  const { userPermissions, escenario } = useContext(contextUserPermissions);
  const [permissionVote, setPermissionVote] = useState(false);
  const [muestraBotonGuardar, setMuestraBotonGuardar] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Verifica los permisos del usuario */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "bingo") {

          if (el.permiso === "ver")
            permission = true;

          if (el.permiso === "marcar-voto")
            setPermissionVote(true);

        }
      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);



  /**************** useEffect [] ******************************************************************************* */
  useEffect(() => {
    /** Carga el select de distrito federal */
    const loadDistritosFederales = async () => {

      let valorEscenario = sessionStorage.getItem('escenario');
      if (escenario !== null)
        valorEscenario = escenario;

      let token = sessionStorage.getItem("token");
      const resp = await fetch(ApiUrl().url + "padron/getDistritosFederales/" + valorEscenario, settingsWithoutBody(token, 'GET'));
      const respDistritosFederales = await resp.json();

      let $selectDistritoFederal = document.getElementById('distritoFederal');
      $selectDistritoFederal.innerHTML = '';

      let $option = document.createElement('option');
      $option.value = '-1';
      $option.innerText = 'Selecciona una opción';
      $selectDistritoFederal.appendChild($option);

      if (respDistritosFederales.status === "success") {
        respDistritosFederales.padron.forEach(el => {
          let $option = document.createElement('option');
          $option.value = el.distrito_federal;
          $option.innerText = el.distrito_federal;
          $selectDistritoFederal.appendChild($option);
        });
      }
    };

    loadDistritosFederales();
  }, []);


  /**************** loadDistritosLocales ******************************************************************************* */
  const loadDistritosLocales = async (df) => {

    /** Carga el select de distrito local */
    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getDistritosLocalesFiltrado/" + valorEscenario + "/" + df, settingsWithoutBody(token, 'GET'));
    const respDistritosLocales = await resp.json();

    let $selectDistritoFederal = document.getElementById('distritoLocal');
    $selectDistritoFederal.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectDistritoFederal.appendChild($option);

    if (respDistritosLocales.padron) {
      respDistritosLocales.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.distrito_local;
        $option.innerText = el.distrito_local;
        $selectDistritoFederal.appendChild($option);
      });
    }

  };


  /**************** loadSecciones ******************************************************************************* */
  const loadSecciones = async (dl) => {
    /** Carga el select de seccion */

    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getSeccionesFiltrado/" + valorEscenario + "/" + dl, settingsWithoutBody(token, 'GET'));
    const respSecciones = await resp.json();

    let $selectSeccion = document.getElementById('seccion');
    $selectSeccion.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectSeccion.appendChild($option);

    if (respSecciones.padron) {
      respSecciones.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.seccion;
        $option.innerText = el.seccion;
        $selectSeccion.appendChild($option);
      });
    }

  };


  /**************** loadCasillas ******************************************************************************* */
  const loadCasillas = async (seccion) => {
    /** Carga el select de casillas */

    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let token = sessionStorage.getItem("token");
    const resp = await fetch(ApiUrl().url + "padron/getCasillasFiltrado/" + valorEscenario + "/" + seccion, settingsWithoutBody(token, 'GET'));
    const respCasillas = await resp.json();

    let $selectCasillas = document.getElementById('casilla');
    $selectCasillas.innerHTML = '';

    let $option = document.createElement('option');
    $option.value = '-1';
    $option.innerText = 'Selecciona una opción';
    $selectCasillas.appendChild($option);

    if (respCasillas.padron) {
      respCasillas.padron.forEach(el => {
        let $option = document.createElement('option');
        $option.value = el.casilla;
        $option.innerText = el.casilla;
        $selectCasillas.appendChild($option);
      });
    }

  };


  /**************** handleChange ******************************************************************************* */
  const handleChange = (e) => {

    setMuestraBotonGuardar(false);
    let $registros = document.getElementById("registros-bingo");
    $registros.innerHTML = "";

    if (e.target.id === 'distritoFederal') {
      if (e.target.value !== '-1') {
        loadDistritosLocales(e.target.value);
        document.getElementById('div-dl').classList.remove('hidden');
        document.getElementById('div-seccion').classList.add('hidden');
        document.getElementById('div-casilla').classList.add('hidden');
      } else {
        document.getElementById('div-dl').classList.add('hidden');
        document.getElementById('div-seccion').classList.add('hidden');
        document.getElementById('div-casilla').classList.add('hidden');
      }
    }

    if (e.target.id === 'distritoLocal') {
      if (e.target.value !== '-1') {
        loadSecciones(e.target.value);
        document.getElementById('div-seccion').classList.remove('hidden');
        document.getElementById('div-casilla').classList.add('hidden');
      } else {
        document.getElementById('div-seccion').classList.add('hidden');
        document.getElementById('div-casilla').classList.add('hidden');
      }
    }

    if (e.target.id === 'seccion') {
      if (e.target.value !== '-1') {
        loadCasillas(e.target.value);
        document.getElementById('div-casilla').classList.remove('hidden');
      } else {
        document.getElementById('div-casilla').classList.add('hidden');
      }
    }

    if (e.target.id === 'casilla') {
      if (e.target.value !== '-1') {
        loadCheckBoxes();
        setMuestraBotonGuardar(true);
      }
    }
  };


  /**************** loadCheckBoxes ******************************************************************************* */
  const loadCheckBoxes = () => {
    /** Carga los checkboxes */

    setLoading(true);

    let $divEl;
    let $element;
    let $label;
    let $registros = document.getElementById("registros-bingo");
    let $div = document.createElement("div");
    $div.classList.add("grid", "grid-cols-2", "md:grid-cols-10", "gap-4", "items-center", "mb-2");
    $registros.innerHTML = "";

    for (let i = 1; i <= BingoTot().totalPorSeccion ; i++) {
      $divEl = document.createElement("div");

      $element = document.createElement("input");
      $element.type = "checkbox";
      $element.name = i;
      $element.value = i;
      $element.id = i;
      /* Se quita el tooltip con el nombre porque ya no se liga al padron de intención del voto */
      //$divEl.setAttribute("data-te-toggle", "tooltip");
      //$divEl.setAttribute("title", el.nombre + ' ' + el.paterno + ' ' + el.materno);
      $element.classList.add("cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

      $label = document.createElement("label");
      $label.htmlFor = i;
      $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
      $label.appendChild(document.createTextNode(i));

      $divEl.appendChild($element);
      $divEl.appendChild($label);
      $div.appendChild($divEl);

      $registros.appendChild($div);
    }

    /* Se quita esta parte porque ya son fijos y no de acuerdo al padron de intención del voto
    let valorEscenario = sessionStorage.getItem('escenario');
    if (escenario !== null)
      valorEscenario = escenario;

    let valueToken = sessionStorage.getItem("token");
    let url = ApiUrl().url + "padron/bingo/" + valorEscenario + "/" + document.getElementById('distritoFederal').value + "/" + document.getElementById('distritoLocal').value + "/" + document.getElementById('seccion').value + "/" + document.getElementById('casilla').value;

    fetch(url, settingsWithoutBody(valueToken, 'GET'))
      .then(res => res.json())
      .then(data => {

        let $divEl;
        let $element;
        let $label;
        let $registros = document.getElementById("registros-bingo");
        $registros.innerHTML = "";

        let $div = document.createElement("div");
        $div.classList.add("grid", "grid-cols-2", "md:grid-cols-10", "gap-4", "items-center", "mb-2");

        for (let i = 1; i <= 600; i++) {
          $divEl = document.createElement("div");

          $element = document.createElement("input");
          $element.type = "checkbox";
          $element.name = i;
          $element.value = i;
          $element.id = i;
          //$divEl.setAttribute("data-te-toggle", "tooltip");
          //$divEl.setAttribute("title", el.nombre + ' ' + el.paterno + ' ' + el.materno);
          $element.classList.add("cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

          $label = document.createElement("label");
          $label.htmlFor = i;
          $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
          $label.appendChild(document.createTextNode(i));

          $divEl.appendChild($element);
          $divEl.appendChild($label);
          $div.appendChild($divEl);

          $registros.appendChild($div);
        }

        data.padron.forEach(el => {

          $divEl = document.createElement("div");

          $element = document.createElement("input");
          $element.type = "checkbox";
          $element.name = el.id;
          $element.value = el.id;
          $element.id = el.id;
          $divEl.setAttribute("data-te-toggle", "tooltip");
          $divEl.setAttribute("title", el.nombre + ' ' + el.paterno + ' ' + el.materno);
          $element.classList.add("cursor-pointer", "w-4", "h-4", "text-blue-600", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

          /** Check true si ya tiene los permisos */
          /* Esto ya estaba comentado
          if (perfil !== "0") {

            if (respPerfiles.perfiles) {
              respPerfiles.perfiles.forEach(elPerfiles => {

                if (parseInt(elPerfiles.permiso) === parseInt(el.id))
                  $element.checked = true;

              });
            }
            

          }*/
          /*
          $label = document.createElement("label");
          $label.htmlFor = el.id;
          $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
          $label.appendChild(document.createTextNode(el.numero_electoral));

          $divEl.appendChild($element);
          $divEl.appendChild($label);
          $div.appendChild($divEl);

          $registros.appendChild($div);


        });
      });
      */
    setLoading(false);

  };


  /**************** hanldeAccept ******************************************************************************* */
  const hanldeAccept = () => {
    let $elements = document.getElementById('registros-bingo').getElementsByTagName('input');

    let seleccionados = [];
    for (var i = 0; i < $elements.length; i++) {
      if ($elements[i].checked === true)
        seleccionados.push($elements[i].id);
    }

    let $distritoFederal = document.getElementById("distritoFederal").value;
    let $distritoLocal = document.getElementById("distritoLocal").value;
    let $seccion = document.getElementById("seccion").value;
    let $casilla = document.getElementById("casilla").value;

    let votos = {
      votos: seleccionados,
      distritoFederal: $distritoFederal,
      distritoLocal: $distritoLocal,
      seccion: $seccion,
      casilla: $casilla,
    };

    
    let token = sessionStorage.getItem("token");
    fetch(ApiUrl().url + "padron/guardaVotos", settingsWithBody(token, votos, 'POST'))
      .then(data => data.json())
      .then(data => {
        if (data.status === 'success') {
          document.getElementById("distritoFederal").value = '-1';
          setMuestraBotonGuardar(false);
          let $permisos = document.getElementById("registros-bingo");
          $permisos.innerHTML = "";
          document.getElementById('div-dl').classList.add('hidden');
          document.getElementById('div-seccion').classList.add('hidden');
          document.getElementById('div-casilla').classList.add('hidden');
        }
      });
    
  };


  /**************** return ******************************************************************************* */
  return (
    <>
      <div className="md:py-12 md:px-12 sm:py-3 sm:px-3 py-3 px-3">

        <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-center pb-10">Selecciona los Elementos</h1>


        <div className="md:px-24 px-2 md:py-6 py-2 grid md:grid-cols-12 gap-3">

          <div className="md:col-span-3">
            <label htmlFor="distritoFederal" className="text-gray-600">Distrito Federal</label>
            <select onChange={handleChange} id="distritoFederal" name="distritoFederal" className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
            </select>
          </div>

          <div className="md:col-span-3 hidden" id="div-dl">
            <label htmlFor="distritoLocal" className="text-gray-600">Distrito Local</label>
            <select onChange={handleChange} id="distritoLocal" name="distritoLocal" className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
            </select>
          </div>

          <div className="md:col-span-3 hidden" id="div-seccion">
            <label htmlFor="seccion" className="text-gray-600">Sección</label>
            <select onChange={handleChange} id="seccion" name="seccion" className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
            </select>
          </div>

          <div className="md:col-span-3 hidden" id="div-casilla">
            <label htmlFor="casilla" className="text-gray-600">Casilla</label>
            <select onChange={handleChange} id="casilla" name="casilla" className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
            </select>
          </div>
        </div>

        {loading && <div className='mt-28 text-center'><Loader /></div>}

        <div id="registros-bingo" className='mt-28'>
        </div>

        {muestraBotonGuardar && permissionVote
          ? <>
            <div className="md:col-span-12 mt-10 md:mt-28">
              <span onClick={hanldeAccept}>
                <BtnAceptarLargo text={"Guardar"} />
              </span>
            </div>
          </>
          : <></>
        }

      </div>
    </>
  );
}

export default Bingo;