/**
 * @file GridProgramas.js
 * @brief Grid que muestra la información de los programas/apoyos.
 * @author Efraín Gourcy
 * @modified Ene 22, 2023
 */

import React, { useEffect, useState } from 'react';
import Loader from '../../components/Loader';
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApiUrl, settingsWithoutBody } from '../../helpers/ApiUrl';

const GridProgramas = ({ programas, loading, gridProgramas, setGridProgramas, isSearch, permissionEdit, handleActive }) => {

  /** Variables */
  const [currentPage, setCurrentPage] = useState(0);
  const [pagination, setPagination] = useState([]);


  /**************** useEffect (currentPage)******************************************************************************* */
  useEffect(() => {
    /** Paginación del Grid */
    let pagPrev = document.getElementById("paginationPrev");
    let pagNext = document.getElementById("paginationNext");

    if (currentPage <= 1)
      pagPrev.classList.add("invisible");
    else
      pagPrev.classList.remove("invisible");

    setPagination([]);

    let total = 0;

    if (programas !== null) {
      total = Math.ceil(programas.length / 5);

      if (currentPage >= total)
        pagNext.classList.add("invisible");
      else
        pagNext.classList.remove("invisible");

      let page = currentPage;
      let end = page * 5;
      let start = end - 5;

      if (total > 10 && currentPage > 5) {

        let pagBegin = parseInt(currentPage) - 5;
        let pagEnd = parseInt(currentPage) + 5;

        if (pagEnd > total)
          pagEnd = total;

        for (let i = pagBegin; i <= pagEnd; i++)
          setPagination(arr => [...arr, i]);

      } else {
        if (total >= 10) {
          for (let i = 1; i <= 10; i++)
            setPagination(arr => [...arr, i]);
        } else {
          for (let i = 1; i <= total; i++)
            setPagination(arr => [...arr, i]);
        }
      }

      setGridProgramas(programas.slice(start, end));

    }

  }, [currentPage]);


  /**************** useEffect (perfiles) ******************************************************************************* */
  useEffect(() => {
    /** Crea la paginación y establece el grid en la primera página */
    if (programas !== null)
      setCurrentPage(1);

  }, [programas]);


  /**************** handlePagination ******************************************************************************* */
  const handlePagination = (e) => {
    /** Clic en los numeros de pagina del grid */
    setCurrentPage(parseInt(e.target.dataset.page));
  };


  /**************** paginationPrevious ******************************************************************************* */
  const paginationPrevious = (e) => {
    /** Clic paginación regresar */
    if (currentPage > 1)
      setCurrentPage(parseInt(currentPage) - 1);
  };


  /**************** paginationNext ******************************************************************************* */
  const paginationNext = (e) => {
    /** Clic paginación avanzar */
    if (currentPage >= 1)
      setCurrentPage(parseInt(currentPage) + 1);

  };


  /**************** return ******************************************************************************* */
  return (
    <>
      <ToastContainer />
      <div className="flex flex-col mt-4">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 select-none">
                <thead className="bg-blue-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Escenario
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Programa
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Descripcion
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Activo
                    </th>
                    <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Editar
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">

                  {loading && <tr><td colSpan="6" className="text-center p-10"><Loader /></td></tr>}

                  {gridProgramas && gridProgramas.map(el => (

                    <tr key={el.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {el.nombre}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        {el.programa}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        {el.descripcion}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        {el.activo === "1" || el.activo === 1
                          ? <span onClick={handleActive} data-id={el.id} className="inline-flex text-xs leading-5 font-semibold rounded-full bg-green-200 text-green-800 hover:bg-green-300 cursor-pointer">
                            <span className="w-20 text-center">Activo</span>
                          </span>
                          : <span onClick={handleActive} data-id={el.id} className="inline-flex text-xs leading-5 font-semibold rounded-full bg-red-200 text-red-800 hover:bg-red-300 cursor-pointer">
                            <span className="w-20 text-center">Inactivo</span>
                          </span>
                        }
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap font-medium text-center">
                        {permissionEdit
                          ? <>
                            <NavLink to={`/programas/form/${el.id}`}>
                              <span data-id={el.id} className="text-2xl text-blue-400 hover:text-blue-500 cursor-pointer"><FiEdit3 className="w-full" /></span>
                            </NavLink>
                          </>
                          : <>
                            <span data-id={el.id} className="text-2xl text-gray-400"><FiEdit3 className="w-full" /></span>
                          </>
                        }
                      </td>
                    </tr>
                  ))}


                </tbody>

              </table>

              {!isSearch
                ? <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
                  <div className="flex w-1/2 items-center justify-between">
                    <span id="paginationPrev" onClick={paginationPrevious} className="cursor-pointer text-sm"><GrFormPrevious /></span>
                    {
                      (pagination.map((el) => (
                        currentPage === el
                          ? <span key={el} onClick={handlePagination} data-page={el} className="select-none bg-blue-200 rounded ml-1 px-1 cursor-pointer text-sm font-bold hover:text-sky-700">{el}</span>
                          : <span key={el} onClick={handlePagination} data-page={el} className="select-none bg-blue-200 rounded ml-1 px-1 cursor-pointer text-sm font-bold hover:text-sky-700">{el}</span>
                      )))
                    }
                    <span id="paginationNext" onClick={paginationNext} className="cursor-pointer ml-1"><GrFormNext /></span>
                  </div>
                </div>
                : <></>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GridProgramas