/**
 * @file Perfiles.js
 * @brief Administración de los Perfiles.
 * @author Efraín Gourcy
 * @modified Feb 13, 2022
 */

import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import BtnAceptar from '../../components/BtnAceptar';
import { ApiUrl, settingsWithoutBody } from '../../helpers/ApiUrl';
import Grid from './Grid';
import { contextUserPermissions } from "../../App";

const Perfiles = () => {

  /** Variables */
  const {userPermissions} = useContext(contextUserPermissions);
  const [perfiles, setPerfiles] = useState(null);
  const [gridPerfiles, setGridPerfiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [permissionEdit, setPermissionEdit] = useState(false);
  let url = ApiUrl().url+"perfiles/get";
  const navigate = useNavigate();


  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Verifica los permisos del usuario */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        //console.log(el.modulo,el.permiso);

        if (el.modulo === "perfiles") {

          if (el.permiso === "ver")
            permission = true;

          if (el.permiso === "nuevo") {
            let $btnNuevoPerfil = document.getElementById("btn-nuevo-perfil");
            $btnNuevoPerfil.classList.remove("invisible");
          }

          if (el.permiso === "editar")
            setPermissionEdit(true);

        }
      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);

  /**************** useEffect (url) ******************************************************************************* */
  
  useEffect(() => {
    /** Carga el grid de perfiles */
    setLoading(true);
      
      let valueToken = sessionStorage.getItem("token");

      let url2 = url;
      if (sessionStorage.getItem("grupo") !== '3881')
        url2 = ApiUrl().url+"perfiles/get/"+sessionStorage.getItem("escenario");

      fetch(url2,settingsWithoutBody(valueToken,'GET'))
      .then(res => res.json())
      .then(data => {
        setPerfiles(data.perfiles);
        setGridPerfiles(data.perfiles.slice(0,5));
        setLoading(false);
      });

  }, [url]);


  /**************** handleSearch ******************************************************************************* */
  const handleSearch = (e) => {
    /** Buscar un elemento dentro del grid de estructura */
    let search = e.target.value;
    let usersArray = [];

    if (search.length > 0) {

      let serachByPerfil = false;
      if (isNaN(search))
        serachByPerfil = true;

      usersArray = perfiles.filter(el => {
        
        if (serachByPerfil){
          let perfil = el.perfil.toLowerCase();
          return perfil.includes(search.toLowerCase());
        }
      });

      setGridPerfiles(usersArray);
      setIsSearch(true);

    } else {
      setGridPerfiles(perfiles.slice(0,5));
      setIsSearch(false);
    }

  };

  /**************** return ******************************************************************************* */
  return (
    <>
    
      <div className="md:py-12 md:px-12 sm:py-3 sm:px-3 py-3 px-3">

        <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-left border-b-2 border-[#3c70b9]">Administrar Perfiles</h1>
    
        <div className="p-4 flex bg-gray-50 rounded-lg shadow-xl">
          <div className="flex-none md:flex-grow-0">
            
            <NavLink id="btn-nuevo-perfil" to="/perfiles/form/0" className="invisible">
              <BtnAceptar text={ "Nuevo Perfil" } />
            </NavLink>
            
          </div>

          <div className="pl-3 md:pl-96 flex-grow">
            <input id="search" type="text" onChange={ handleSearch } className="p-2 w-full rounded border-[#3c70b9] shadow-lg focus:ring-blue-500 focus:outline-none focus:ring" placeholder="Buscar..." />
          </div>
        </div>

        <Grid perfiles={ perfiles } loading={ loading } gridPerfiles={ gridPerfiles } setGridPerfiles={ setGridPerfiles } isSearch={ isSearch} permissionEdit={ permissionEdit } />
          
      </div>
    </>
  );
}

export default Perfiles;