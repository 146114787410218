/**
 * @file EditarEstructura.js
 * @brief Edita la posición en la estructura de un registro del padrón.
 * @author Efraín Gourcy
 * @modified Mar 24, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiUrl, settingsWithBody, settingsWithoutBody } from '../../helpers/ApiUrl';
import BtnAceptar from '../../components/BtnAceptar';
import { contextUserPermissions } from "../../App";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialValues = {
  "estructura": "",
  "puesto": "",
  "depende": ""
}

const EditarEstructura = () => {

  /** Variables */
  let { id } = useParams();
  const [datosPadron, setDatosPadron] = useState({});
  const [form, setForm] = useState(initialValues);
  const { escenario } = useContext(contextUserPermissions);
  //const [valorDepende, setValorDepende] = useState(false);
  const navigate = useNavigate();


  /**************** useEffect [id] ******************************************************************************* */
  useEffect(() => {

    /** Obtiene los datos del registro */
    const getData = () => {

      let token = sessionStorage.getItem("token");
      fetch(ApiUrl().url + "padron/getPadron/" + id, settingsWithoutBody(token, 'GET'))
        .then(data => data.json())
        .then(data => {
          setDatosPadron(data.padron[0]);

          let estructura = data.padron[0].estructura;
          let idEstructura = 0;
          let puestoEstructura = data.padron[0].puesto_estructura;
          let dependeDeUsuario = data.padron[0].usr_dep;
          let puestoPersona = 0;

          /** Obtiene las estructuras y llena el select */
          fetch(ApiUrl().url + "estructura/getEstructurasConEscenario/" + escenario, settingsWithoutBody(token, 'GET'))
            .then(data => data.json())
            .then(data => {

              let $option;
              let $select = document.getElementById("estructura");
              $select.innerHTML = "";

              $option = document.createElement("option");
              $option.innerText = "Selecciona una opción";
              $option.value = "-1";
              $select.appendChild($option);

              if (data.estructura) {
                data.estructura.forEach(el => {
                  $option = document.createElement("option");
                  $option.innerText = el.estructura;
                  $option.value = el.id;
                  $select.appendChild($option);

                  if (estructura === el.estructura)
                    idEstructura = el.id;

                });

                /** Selecciona la estructura a la que pertence el usuario */
                $select.value = idEstructura;
              }

              fetch(ApiUrl().url + "estructura/getPuestos", settingsWithBody(token, { id_estructura: idEstructura }, 'POST'))
                .then((data) => data.json())
                .then((data) => {

                  let $optionPuesto;
                  let $selectPuesto = document.getElementById("puesto");
                  $selectPuesto.innerHTML = "";
                  let depende = 0;

                  $optionPuesto = document.createElement("option");
                  $optionPuesto.innerText = "Selecciona una opción";
                  $optionPuesto.value = "-1";
                  $selectPuesto.appendChild($optionPuesto);

                  if (data.estructura) {
                    data.estructura.forEach(el => {
                      $optionPuesto = document.createElement("option");
                      $optionPuesto.innerText = el.puesto;
                      $optionPuesto.value = el.id;
                      $selectPuesto.appendChild($optionPuesto);

                      if (parseInt(puestoEstructura) === parseInt(el.id)) {
                        puestoPersona = el.id;
                        depende = el.depende;
                      }

                    });

                    $selectPuesto.value = puestoPersona;

                    /** Si depende !== 0 no es liderazgo (busca la persona de la que depende en la estructura) */
                    if (depende !== "0" && depende !== 0) {

                      fetch(ApiUrl().url + "padron/getPuesto", settingsWithBody(token, { puesto_estructura: depende }, 'POST'))
                        .then((data) => data.json())
                        .then((data) => {

                          let $optionDepende;
                          let $selectDepende = document.getElementById("depende");
                          $selectDepende.innerHTML = "";

                          $optionDepende = document.createElement("option");
                          $optionDepende.innerText = "Selecciona una opción";
                          $optionDepende.value = "-1";
                          $selectDepende.appendChild($optionDepende);

                          if (data.padron) {
                            data.padron.forEach(el => {
                              $optionDepende = document.createElement("option");
                              $optionDepende.innerText = el.paterno + " " + el.materno + " " + el.nombre;
                              $optionDepende.value = el.id;
                              $selectDepende.appendChild($optionDepende);

                            });

                            /** Selecciona el usuario del que depende en la estructura */
                            $selectDepende.value = dependeDeUsuario;
                          }

                        });
                    } else {
                      let $divDepende = document.getElementById("div-depende");
                      $divDepende.classList.add("hidden");
                    }

                  };

                  /** Actualiza los valores del formulario */
                  setForm({
                    ...form,
                    "estructura": idEstructura,
                    "puesto": puestoPersona,
                    "depende": dependeDeUsuario
                  });

                });

            });
        });
    };

    if (parseInt(id) !== 0 && escenario !== null)
      getData();
    else
      navigate("/intencion/asignar/estructura");

  }, [id]);


  /**************** loadPuestos ******************************************************************************* */
  /** Carga los puestos de acuerdo a la estructura seleccionada */
  const loadPuestos = async (id_estructura) => {

    let token = sessionStorage.getItem("token");
    const response = await fetch(ApiUrl().url + "estructura/getPuestos", settingsWithBody(token, { id_estructura }, 'POST'));
    const respEstructura = await response.json();

    if (respEstructura.status === "success") {

      if (respEstructura.estructura.length > 0) {
        let $option;
        let $select = document.getElementById("puesto");
        $select.innerHTML = "";

        $option = document.createElement("option");
        $option.innerText = "Selecciona un opción";
        $option.value = "-1";
        $select.appendChild($option);

        respEstructura.estructura.forEach(el => {
          $option = document.createElement("option");
          $option.innerText = el.puesto;
          $option.value = el.id;
          $select.appendChild($option);
        });
      }

    }
  };


  /**************** getDependientesPuesto ******************************************************************************* */
  const getDependientesPuesto = async (id_puesto) => {
    let token = sessionStorage.getItem("token");
    let response = await fetch(ApiUrl().url + "estructura/getDependientesPuesto", settingsWithBody(token, { depende: id_puesto }, 'POST'));
    const respEstructura = await response.json();

    if (respEstructura.status === "success") {
      let $puesto = document.getElementById("puesto");
      /** Si el puesto es un liderazo (nodo raiz de la estructura) */
      if (respEstructura.estructura[0].depende === "0" || respEstructura.estructura[0].depende === 0) {
        setForm({
          ...form,
          puesto: $puesto.value,
          depende: "0"
        });

        let $divDepende = document.getElementById("div-depende");
        $divDepende.classList.add("hidden");

        /** Si el puesto necesita depender de alguien */
      } else {
        setForm({
          ...form,
          puesto: $puesto.value,
          depende: ""
        });

        response = await fetch(ApiUrl().url + "padron/getPuesto", settingsWithBody(token, { puesto_estructura: respEstructura.estructura[0].depende }, 'POST'));
        const respPadron = await response.json();

        let $option;
        let $select = document.getElementById("depende");
        $select.innerHTML = "";

        $option = document.createElement("option");
        $option.innerText = "Selecciona un opción";
        $option.value = "-1";
        $select.appendChild($option);

        respPadron.padron.forEach(el => {
          $option = document.createElement("option");
          $option.innerText = el.nombre + " " + el.paterno + " " + el.materno;
          $option.value = el.id;
          $select.appendChild($option);
        });

      }

    }
  };


  /**************** handleChange ******************************************************************************* */
  const handleChange = (e) => {
    /** Cambia los valores de los selects */

    let $divDepende = document.getElementById("div-depende");

    /**  Si es el select de Estructura */
    if (e.target.id === "estructura") {

      $divDepende.classList.add("hidden");

      /** Válida que haya seleccionado una opción */
      let $divPuesto = document.getElementById("div-puesto");
      if (e.target.value !== "-1") {
        $divPuesto.classList.remove("hidden");
        loadPuestos(e.target.value);


        /** Oculta el div de puestos */
      } else
        $divPuesto.classList.add("hidden");

      setForm({
        ...form,
        estructura: e.target.value,
        puesto: "-1",
        depende: "-1"
      });

      /** Si es el select de puesto */
    } else if (e.target.id === "puesto") {

      /** Si selecciona una opción */
      if (e.target.value !== "-1") {
        getDependientesPuesto(e.target.value);
        $divDepende.classList.remove("hidden");
      } else {
        $divDepende.classList.add("hidden");
      }

      setForm({
        ...form,
        puesto: e.target.value,
        depende: "-1"
      });

      /** Si es el select de depende */
    } else if (e.target.id === "depende") {
      /** Si selecciona una opción */
      if (e.target.value !== "-1") {
        setForm({
          ...form,
          depende: e.target.value
        });
        
      } else {
        setForm({
          ...form,
          depende: "-1"
        });
      }
    }
  };


  /**************** updateEstructura ******************************************************************************* */
  const updateEstructura = async () => {
    let token = sessionStorage.getItem("token");

    const responseDependientes = await fetch(ApiUrl().url + "padron/tieneDependientes/" + id, settingsWithBody(token, form, 'POST'));
    const respDependientes = await responseDependientes.json();

    //console.log(respDependientes.total);

    if (respDependientes.total > 0) {
      toast.error('No se puede cambiar el puesto ya que tiene dependientes en la estructura', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
    } else {
      const response = await fetch(ApiUrl().url + "padron/editEstructura/" + id, settingsWithBody(token, form, 'POST'));
      const respPadron = await response.json();
      
      if (respPadron.status === "success")
        navigate("/intencion/asignar/estructura");
      else {
        toast.error('Error de comunicación con el servidor', {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored"
        });
      }
    }

  };


  /**************** handleAccept ******************************************************************************* */
  const handleAccept = () => {
    if (form.estructura === "-1") {
      toast.error('No ha seleccionado una estructura', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
    } else if (form.puesto === "-1") {
      toast.error('No ha seleccionado un puesto', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
    } else if (form.depende === "-1" || form.depende === "") {
      toast.error('No ha seleccionado un dependiente', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
    } else {
      updateEstructura();
    }
  };


  /**************** return ******************************************************************************* */
  return (
    <>
      <div className="py-12 h-screen bg-gray-300">

        <ToastContainer />

        <div className="max-w-12xl mx-12 bg-white rounded-xl shadow-md overflow-hidden md:max-w-12xl">
          <div className="md:flex">
            <div className="w-full p-2 py-10">

              <div className="flex justify-center">
                <div className="relative">

                  <img src={`https://ui-avatars.com/api/?name=${datosPadron.nombre}+${datosPadron.paterno}=&background=cbd5e1&color=ffffff`} className="rounded-full" width="80" />
                  <span className="absolute border-white border-4 h-5 w-5 top-12 left-16 bg-green-700 rounded-full"></span>

                </div>

              </div>

              <div className="flex flex-col text-center mt-3 mb-4">

                <span className="text-2xl font-medium">{datosPadron.clave_electoral}</span>
                <span className="text-md text-gray-400">{datosPadron.nombre} {datosPadron.paterno} {datosPadron.materno}</span>

              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 px-20">
                <div className="text-left">
                  <span>Calle: </span>{datosPadron.calle}
                </div>
                <div className="text-left">
                  <span>Interior: </span>{datosPadron.interior}
                </div>
                <div className="text-left">
                  <span>Exterior: </span>{datosPadron.exterior}
                </div>
                <div className="text-left">
                  <span>Colonia: </span>{datosPadron.colonia}
                </div>
                <div className="text-left">
                  <span>Municipio: </span>{datosPadron.municipio}
                </div>
                <div className="text-left">
                  <span>Distrito Local: </span>{datosPadron.distrito_local}
                </div>
                <div className="text-left">
                  <span>Distrito Federal: </span>{datosPadron.distrito_federal}
                </div>
                <div className="text-left">
                  <span>Teléfono Móvil: </span>{datosPadron.telefono_movil}
                </div>
              </div>

              <div className="mt-20 grid grid-cols-1 md:grid-cols-3 gap-4 px-20">

                <div>
                  <label htmlFor="estructura" className="text-gray-600">Estructura</label>
                  <select id="estructura" name="estructura" value={form.estructura} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-opacity-40 border border-gray-300 text-gray-600">
                  </select>
                  <span id="errorEstructura" className="hidden text-red-500 text-sm font-bold">* Campo estructura requerido</span>
                </div>

                <div id="div-puesto">
                  <label htmlFor="puesto" className="text-gray-600">Puesto</label>
                  <select id="puesto" name="puesto" value={form.puesto} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-opacity-40 border border-gray-300 text-gray-600">
                  </select>
                  <span id="errorPuesto" className="hidden text-red-500 text-sm font-bold">* Campo puesto requerido</span>
                </div>


                <div id="div-depende">
                  <label htmlFor="depende" className="text-gray-600">Depende</label>
                  <select id="depende" name="depende" value={form.depende} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-opacity-40 border border-gray-300 text-gray-600">
                  </select>
                  <span id="errorDepende" className="hidden text-red-500 text-sm font-bold">* Campo depende requerido</span>
                </div>

              </div>

              <div className="px-14 mt-16 text-right">

                <span onClick={handleAccept}>
                  <BtnAceptar text={"Guardar"} />
                </span>

              </div>

            </div>

          </div>
        </div>

      </div>
    </>
  );
}

export default EditarEstructura;