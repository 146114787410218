/**
 * @file Partidos.js
 * @brief Adminitración de partidos politicos.
 * @author Efraín Gourcy
 * @modified May 1, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contextUserPermissions } from '../../App';
import { useNavigate } from 'react-router-dom';
import { ApiUrl, settingsWithBody, settingsWithoutBody } from '../../helpers/ApiUrl';
import BtnAceptarLargo from '../../components/BtnAceptarLargo';

const initialValues = {
  pan: false,
  pri: false,
  prd: false,
  pt: false,
  verde: false,
  mc: false,
  morena: false,
  pes: false,
  fsm: false,
  rsp: false,
  pla: false,
};

const Partidos = () => {

  /** Variables */
  const { userPermissions } = useContext(contextUserPermissions);
  let navigate = useNavigate();
  const [checkboxes, setCheckboxes] = useState(initialValues);


  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Valida los permisos para el módulo de usuarios */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "partidos") {
          if (el.permiso === "editar")
            permission = true;
        }
      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);


  /**************** useEffect [] ******************************************************************************* */
  useEffect(() => {

    const getEscenarios = async () => {
      try {
        let token = sessionStorage.getItem("token");

        let response = "";
        if (sessionStorage.getItem("grupo") === "3881")
          response = await fetch(ApiUrl().url + "escenarios/getActives", settingsWithoutBody(token, 'GET'));
        else
          response = await fetch(ApiUrl().url + "escenarios/getActives/"+sessionStorage.getItem("escenario"), settingsWithoutBody(token, 'GET'));

        const data = await response.json();

        if (data.status === 'success') {

          let select = document.getElementById("escenario");
          select.innerHTML = "";

          let $option = document.createElement("option");
          $option.value = "-1";
          $option.text = "Seleccione un escenario";
          select.appendChild($option);

          data.escenarios.forEach(el => {
            let option = document.createElement("option");
            option.value = el.id;
            option.text = el.nombre;
            select.appendChild(option);
          });

        }
        else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored"
          });
        }
      } catch (error) {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored"
        });
      }
    };

    getEscenarios();

  }, []);


  /**************** handleChange ******************************************************************************* */
  const handleChange = async (e) => {

    let $divCheckboxes = document.getElementById("div-checkboxes");
    $divCheckboxes.classList.add("hidden");

    let partidosVisibles = initialValues;

    if (e.target.value !== "-1") {

      let token = sessionStorage.getItem("token");
      const response = await fetch(ApiUrl().url + "partidos/get/" + e.target.value, settingsWithoutBody(token, 'GET'));
      const data = await response.json();

      if (data.status === 'success') {

        $divCheckboxes.classList.remove("hidden");

        data.partidos.forEach(el => {
          if (el.visible === 1 || el.visible === "1") {
            partidosVisibles = { ...partidosVisibles, [el.partido]: true };
          }
        });

        Object.entries(partidosVisibles).forEach(([key, value]) => {
          document.getElementById("check-" + key).checked = value;
        });

        /** Agrega el escenario a la variable checkboxes */
        let escenario = document.getElementById("escenario").value;
        partidosVisibles = { ...partidosVisibles, "escenario": escenario };

        setCheckboxes(partidosVisibles);

      } else {
        toast.error('No se pudo cargar el catálogo de partidos', {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored"
        });
      }

    }
  };


  /**************** handleCheckBoxChange ******************************************************************************* */
  const handleCheckBoxChange = (e) => {
    setCheckboxes({
      ...checkboxes,
      [e.target.value]: e.target.checked
    });
  };


  /**************** handleAceptar ******************************************************************************* */
  const handleAceptar = async (e) => {
    e.preventDefault();

    let token = sessionStorage.getItem("token");
    const response = await fetch(ApiUrl().url + "partidos/actualiza", settingsWithBody(token, checkboxes, 'POST'));
    const data = await response.json();

    if (data.status === 'success') {
      toast.success('Datos Guardados', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
    }

  };


  /**************** return ******************************************************************************* */
  return (
    <>
      <ToastContainer autoClose={2000} />
      <div className="py-6 px-2">

        <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-center">Mostrar/Ocultar Partídos Políticos</h1>

        <div className="grid md:grid-cols-6 gap-3">
          <div className="md:col-start-2 md:col-span-4 bg-gray-100 rounded-lg p-2">

            <form>
              <div className="px-2 py-2 grid md:grid-cols-4 gap-2">

                <div className="col-span-4 p-1">
                  <label htmlFor="escenario" className="text-gray-500">Escenario</label>
                  <select id="escenario" name="escenario" onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600">
                  </select>
                </div>

              </div>
            </form>

          </div>
        </div>

        <div id="div-checkboxes" className="hidden grid md:grid-cols-6 mt-4 md:mt-16 gap-3 text-left md:pl-14">

          <div className="md:col-start-2 bg-gray-100 rounded-lg p-2">
            <input type="checkbox" id="check-pan" name="check-pan" value="pan" onChange={handleCheckBoxChange} className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="check-pan" className="text-gray-500 p-2 cursor-pointer">PAN</label>
          </div>

          <div className="bg-gray-100 rounded-lg p-2">
            <input type="checkbox" id="check-pri" name="check-pri" value="pri" onChange={handleCheckBoxChange} className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="check-pri" className="text-gray-500 p-2 cursor-pointer">PRI</label>
          </div>
          <div className="bg-gray-100 rounded-lg p-2">
            <input type="checkbox" id="check-prd" name="check-prd" value="prd" onChange={handleCheckBoxChange} className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="check-prd" className="text-gray-500 p-2 cursor-pointer">PRD</label>
          </div>
          <div className="bg-gray-100 rounded-lg p-2">
            <input type="checkbox" id="check-pt" name="check-pt" value="pt" onChange={handleCheckBoxChange} className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="check-pt" className="text-gray-500 p-2 cursor-pointer">PT</label>
          </div>
          <div className=" md:col-start-2 bg-gray-100 rounded-lg p-2">
            <input type="checkbox" id="check-verde" name="check-verde" value="verde" onChange={handleCheckBoxChange} className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="check-verde" className="text-gray-500 p-2 cursor-pointer">VERDE</label>
          </div>
          <div className="bg-gray-100 rounded-lg p-2">
            <input type="checkbox" id="check-mc" name="check-mc" value="mc" onChange={handleCheckBoxChange} className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="check-mc" className="text-gray-500 p-2 cursor-pointer">MC</label>
          </div>
          <div className="bg-gray-100 rounded-lg p-2">
            <input type="checkbox" id="check-morena" name="check-morena" value="morena" onChange={handleCheckBoxChange} className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="check-morena" className="text-gray-500 p-2 cursor-pointer">MORENA</label>
          </div>
          <div className="bg-gray-100 rounded-lg p-2">
            <input type="checkbox" id="check-pes" name="check-pes" value="pes" onChange={handleCheckBoxChange} className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="check-pes" className="text-gray-500 p-2 cursor-pointer">PES</label>
          </div>
          <div className=" md:col-start-2 bg-gray-100 rounded-lg p-2">
            <input type="checkbox" id="check-fsm" name="check-fsm" value="fsm" onChange={handleCheckBoxChange} className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="check-fsm" className="text-gray-500 p-2 cursor-pointer">FSM</label>
          </div>
          <div className="bg-gray-100 rounded-lg p-2">
            <input type="checkbox" id="check-rsp" name="check-rsp" value="rsp" onChange={handleCheckBoxChange} className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="check-rsp" className="text-gray-500 p-2 cursor-pointer">RSP</label>
          </div>
          <div className="bg-gray-100 rounded-lg p-2">
            <input type="checkbox" id="check-pla" name="check-pla" value="pla" onChange={handleCheckBoxChange} className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="check-pla" className="text-gray-500 p-2 cursor-pointer">PLA</label>
          </div>

          <div className="md:col-start-2 md:mt-10 md:col-span-4" onClick={handleAceptar}>
            <BtnAceptarLargo text={"Aceptar"} />
          </div>


        </div>

      </div>
    </>
  );

}

export default Partidos;