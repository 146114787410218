/**
 * @file Form.js
 * @brief Formulario de Perfiles (Nuevo/Editar).
 * @author Efraín Gourcy
 * @modified Mar 24, 2023
 */

import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import BtnAceptar from '../../components/BtnAceptar';
import BtnCancelar from '../../components/BtnCancelar';
import Loader from '../../components/Loader';
import { ApiUrl, settingsWithBody, settingsWithoutBody } from '../../helpers/ApiUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contextUserPermissions } from '../../App';

/** Valores iniciales form */
const initalValues = {
  perfil: ""
}

const PerfilesForm = () => {

  /** Variables */
  let { perfil } = useParams();
  let navigate = useNavigate();

  const [form, setForm] = useState(initalValues);
  const [loading, setLoading] = useState(false);
  const {userPermissions} = useContext(contextUserPermissions);
  const escenario = sessionStorage.getItem("escenario");


  /**************** useEffect [userPermissions] ******************************************************************************* */
  useEffect(() => {
    /** Valida los permisos para el módulo de permisos */
    if (userPermissions) {
      let permission = false;
      userPermissions.forEach(el => {

        if (el.modulo === "perfiles") {
          if (el.permiso === "nuevo" || el.permiso == "editar")
            permission = true;
        }
      });

      if (!permission)
        navigate("/");
    }

  }, [userPermissions]);


  /**************** useEffect[] ******************************************************************************* */
  useEffect(() => {

    const cargaPermisos = async () => {
      /** Carga los datos del perfil y sus permisos (modo edición) */
      let valueToken = sessionStorage.getItem("token");
      let respPerfiles;

      if (perfil !== "0") {
        setForm({
          ...form,
          "perfil": perfil
        });

        const response = await fetch(ApiUrl().url + "perfiles/get", settingsWithBody(valueToken, { perfil }, 'POST'));
        respPerfiles = await response.json();
      }


      const response = await fetch(ApiUrl().url + "permisos/get", settingsWithoutBody(valueToken, 'GET'));
      const respPermisos = await response.json();

      if (respPermisos.permisos) {
        /** Crea el div que muestra los permisos */
        let $divEl;
        let $element;
        let $label;
        let $permisos = document.getElementById("permisos");
        $permisos.innerHTML = "";

        let $div = document.createElement("div");
        $div.classList.add("grid", "grid-cols-2", "md:grid-cols-3", "gap-4", "items-center", "mb-2");


        let modulo = "";
        let moduloactual = "";
        respPermisos.permisos.forEach(el => {

          if (el.activo === "1" || el.activo === 1) {

            $divEl = document.createElement("div");

            moduloactual = el.modulo;
            if (moduloactual !== modulo) {
              modulo = el.modulo;
              $divEl.classList.add("col-start-1", "col-end-12");
              let $h1 = document.createElement("h1");
              $h1.classList.add("mt-8", "pb-4", "text-[#3c70b9]");
              $h1.innerText = "Modulo " + el.modulo;
              $divEl.appendChild($h1);
            } else {
              modulo = el.modulo;
            }

            $element = document.createElement("input");
            $element.type = "checkbox";
            $element.name = el.id;
            $element.value = el.id;
            $element.id = el.id;

            $divEl.setAttribute("data-te-toggle", "tooltip");
            $divEl.setAttribute("title", el.modulo + " / " + el.permiso);

            $element.classList.add("cursor-pointer", "w-4", "h-4", "text-[#3c70b9]", "bg-gray-100", "rounded", "border-gray-300", "focus:ring-blue-500", "focus:ring-2");

            /** Check true si ya tiene los permisos */
            if (perfil !== "0") {

              if (respPerfiles.perfiles) {
                respPerfiles.perfiles.forEach(elPerfiles => {

                  if (parseInt(elPerfiles.permiso) === parseInt(el.id))
                    $element.checked = true;

                });
              }

            }

            $label = document.createElement("label");
            $label.htmlFor = el.id;
            $label.classList.add("cursor-pointer", "ml-2", "text-sm", "font-medium", "text-gray-500");
            $label.appendChild(document.createTextNode(el.permiso));

            $divEl.appendChild($element);
            $divEl.appendChild($label);
            $div.appendChild($divEl);

            $permisos.appendChild($div);
          }

        });

      }

    };

    cargaPermisos();

  }, []);



  /**************** handleChange ******************************************************************************* */
  const handleChange = (e) => {
    /** Actualización de los datos del formulario */
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };


  /**************** existProfile ******************************************************************************* */
  const existProfile = async () => {
    /** Verifica si el perfil ya existe */
    let valueToken = sessionStorage.getItem("token");
    const response = await fetch(ApiUrl().url + "perfiles/exist", settingsWithBody(valueToken, form, 'POST'));
    const respPermisos = await response.json();

    if (respPermisos.status === "success") {
      if (respPermisos.exist === true)
        return true;
      else
        return false;

    } else
      return false;
  };


  /**************** store ******************************************************************************* */
  const store = async (idPermiso) => {
    /** Guarda el perfil */
    let valueToken = sessionStorage.getItem("token");
    let data = {
      perfil: form.perfil,
      permiso: idPermiso,
      escenario: escenario
    }

    await fetch(ApiUrl().url + "perfiles/store", settingsWithBody(valueToken, data, 'POST'));

  };


  /**************** deleteProfile ******************************************************************************* */
  const deleteProfile = async () => {
    /** Elimina el perfil */
    let valueToken = sessionStorage.getItem("token");
    let data = {
      perfil: form.perfil
    }

    await fetch(ApiUrl().url + "perfiles/delete", settingsWithBody(valueToken, data, 'POST'));
  
    return true;
  };


  /**************** handleAccept ******************************************************************************* */
  const handleAccept = (e) => {
    /** Clic al botón de Aceptar */
    e.preventDefault();

    setLoading(true);

    let inputPerfil = document.getElementById("perfil");
    inputPerfil.classList.remove("ring-2");
    inputPerfil.classList.remove("ring-red-400");

    if (form.perfil !== "") {

      /** Si es modo edición */
      if (perfil !== "0") {
        /** Elimina el perfil y lo crea de nuevo con los nuevos permisos */
        deleteProfile()
          .then(el => {
            let $elements = document.getElementById('permisos').getElementsByTagName('input');

            for (var i = 0; i < $elements.length; i++) {
              if ($elements[i].checked === true)
                store($elements[i].id);
  
            }
            return navigate("/perfiles");
          });

      /** Si es un perfil nuevo */
      } else {
        /** Verifica que no exista el perfil */
        existProfile()
          .then(existe => {
            if (existe) {
              toast.error('El nombre del perfil ya existe', {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
              });
              inputPerfil.classList.add("ring-2");
              inputPerfil.classList.add("ring-red-400");
            } else {
              let $elements = document.getElementById('permisos').getElementsByTagName('input');

              for (var i = 0; i < $elements.length; i++) {
                if ($elements[i].checked === true) {
                  store($elements[i].id);
                }
              }
              return navigate("/perfiles");
            }
          });

      }

    } else {
      toast.error('Campo perfil requerido', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
      inputPerfil.classList.add("ring-2");
      inputPerfil.classList.add("ring-red-400");
    }

    setLoading(false);


  };

  /**************** return ******************************************************************************* */
  return (
    <>
      <ToastContainer autoClose={2000} />
      <div className="py-6 px-2">

        <h1 className="md:my-3 md:text-3xl text-xl text-[#3c70b9] text-center">Crea un Nuevo Perfil</h1>

        <div className="grid md:grid-cols-6 gap-3">

          <div className="md:col-start-2 md:col-span-4 bg-gray-100 rounded-lg p-2">

            <form>
              <div className="px-2 py-2 grid md:grid-cols-4 gap-2">

                <div className="col-span-4 p-1">
                  <label htmlFor="perfil" className="text-gray-600">Perfil</label>
                  <input type="text" id="perfil" name="perfil" autoComplete="true" value={form.perfil} onChange={handleChange} className="w-full mt-2 p-1 rounded-lg shadow-lg focus:outline-none focus:ring focus:ring-blue-500 border border-gray-300 text-gray-600" />
                </div>

                <div id="permisos" className="col-span-4 p-1">

                </div>

                <div className="p-1 col-span-4 md:justify-self-end">
                  {loading
                    ? <Loader />
                    : <>
                      <span onClick={handleAccept} className="mr-4"><BtnAceptar text={"Guardar"} /></span>

                      <NavLink to="/perfiles">
                        <BtnCancelar text={"Cancelar"} />
                      </NavLink>
                    </>
                  }

                </div>

              </div>
            </form>

          </div>

        </div>

      </div>
    </>
  );
}

export default PerfilesForm;